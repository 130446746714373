import { SET_CARDS, SET_NOTIFICATIONS, DELETE_NOTIFICATION, UNREAD_NOTIFICATIONS, GRP_REQUEST, DELETE_AFF, DELETE_LEGAL, DELETE_USER, GROUP_ACTION, CHANGE_POST_STATUS, DELETE_EVENT, DELETE_MEDIA, DELETE_ARTICLE, SET_PENDING_ARTICLE, SET_PENDING_EVENT, SET_PENDING_MEDIA, SET_FLAGGED, SET_USER_DETAIL, SET_LEGAL, SET_STATES, MEDIA_DETAIL, SET_REQUESTED_LIST, SET_MY_GROUP, SET_PATRIOT_USER, SET_GROUP, SET_AFFILIATE, SET_UNAUTHENTICATED, SET_EVENT, SET_ARTICLE, SET_MEDIA, ARTICLE_DETAIL } from '../types'

const initialState = {
    loading: false,
    legals: [],
    legalHasMore: false,
    leCurrentPage: 0,
    groups: [],
    myGroups: [],
    affiliates: [],
    affiliatesHasMore: false,
    afCurrentPage: 0,
    events: [],
    eventHasMore: false,
    eCurrentPage: 0,
    pendingEvents: [],
    pendingEventHasMore: false,
    articles: [],
    articlesHasMore: false,
    aCurrentPage: 0,
    pendingArticles: [],
    pendingArticlesHasMore: false,
    peCurrentPage: 0,
    medias: [],
    mediaHasMore: false,
    mCurrentPage: 0,
    mpCurrentPage: 0,
    pendingMedias: [],
    pendingMediaHasMore: false,
    patriotUsers: [],
    patriotUsersHasMore: false,
    pgCurrentPage: 0,
    requesters: [],
    articleDetail: {},
    mediaDetail: {},
    userDetail: {},
    states: [],
    flaggedItems: [],
    unreadNotifications: 0,
    notifications: [],
    cards: [],
}

export default function home(state = initialState, action) {
    switch (action.type) {
        case SET_UNAUTHENTICATED:
            return initialState;
        case SET_LEGAL:
            let oldDataL = state.legals;
            let newDataL = action.payload;
            let leCurrentPage = action.currentPage
            oldDataL = (leCurrentPage === 1 ? newDataL : oldDataL.concat(newDataL))
            let updatedLegal = new Map();
            for (const tag of oldDataL) {
                updatedLegal.set(tag.legal_id, tag);
            }
            let lResult = [...updatedLegal.values()]
            return {
                ...state,
                legals: lResult,
                legalHasMore: action.hasmore,
                leCurrentPage: action.currentPage,
                loading: false
            }
        case SET_AFFILIATE:
            // let oldDataAf = state.affiliates;
            // let newDataAf = action.payload;
            // oldDataAf = oldDataAf.concat(newDataAf);
            // let updatedAff = new Map();
            // for (const tag of oldDataAf) {
            //     updatedAff.set(tag.aff_id, tag);
            // }
            // let afResult = [...updatedAff.values()]
            let oldDataAf = state.affiliates;
            let newDataAf = action.payload;
            let afCurrentPage = action.currentPage
            oldDataAf = (afCurrentPage === 1 ? newDataAf : oldDataAf.concat(newDataAf))
            let updatedAff = new Map();
            for (const tag of oldDataAf) {
                updatedAff.set(tag.aff_id, tag);
            }
            let afResult = [...updatedAff.values()]
            return {
                ...state,
                affiliates: afResult,
                affiliatesHasMore: action.hasmore,
                afCurrentPage: action.currentPage,
                loading: false
            }
        case SET_EVENT:
            let oldDataE = state.events;
            let newDataE = action.payload;
            oldDataE = oldDataE.concat(newDataE);
            let updatedEvent = new Map();
            for (const tag of oldDataE) {
                updatedEvent.set(tag.eventid, tag);
            }
            let eResult = [...updatedEvent.values()]
            return {
                ...state,
                events: eResult,
                eventHasMore: action.hasmore,
                eCurrentPage: action.currentPage,
                loading: false
            }
        case SET_PENDING_EVENT:
            let oldPenEvents = state.pendingEvents.filter(res => !action.payload.some(result => result.eventid === res.eventid));
            let updatedPenEvent = oldPenEvents.concat(action.payload);
            return {
                ...state,
                pendingEvents: updatedPenEvent,
                pendingEventHasMore: action.hasmore,
                loading: false
            }
        case DELETE_EVENT:
            return {
                ...state,
                events: state.events.filter(res => res.eventid !== action.payload),
                loading: false
            }
        case SET_ARTICLE:
            let oldDataA = state.articles;
            let newDataA = action.payload;
            let acurrentPage = action.currentPage
            oldDataA = (acurrentPage === 1 ? newDataA : oldDataA.concat(newDataA))
            let updatedArticle = new Map();
            for (const tag of oldDataA) {
                updatedArticle.set(tag.article_id, tag);
            }
            let aResult = [...updatedArticle.values()]
            return {
                ...state,
                articles: aResult,
                articlesHasMore: action.hasmore,
                aCurrentPage: action.currentPage,
                loading: false
            }
        case SET_PENDING_ARTICLE:
            let oldPenArt = state.pendingArticles;
            let newPenA = action.payload;
            let peCurrentPage = action.currentPage;
            oldPenArt = (peCurrentPage === 1 ? newPenA : oldPenArt.concat(newPenA))
            let updatedPenArt = new Map();
            for (const tag of oldPenArt) {
                updatedPenArt.set(tag.article_id, tag);
            }
            let penRes = [...updatedPenArt.values()]
            return {
                ...state,
                pendingArticles: penRes,
                pendingArticlesHasMore: action.hasmore,
                peCurrentPage: peCurrentPage,
                loading: false
            }
        case DELETE_ARTICLE:
            return {
                ...state,
                articles: state.articles.filter(res => res.article_id !== action.payload),
                loading: false
            }
        case SET_MEDIA:
            let oldDataM = state.medias;
            let newDataMed = action.payload;
            oldDataM = oldDataM.concat(newDataMed);
            let updatedMedia = new Map();
            for (const tag of oldDataM) {
                updatedMedia.set(tag.post_id, tag);
            }
            let mResult = [...updatedMedia.values()]
            return {
                ...state,
                medias: mResult,
                mediaHasMore: action.hasmore,
                mCurrentPage: action.currentPage,
                loading: false
            }
        case SET_PENDING_MEDIA:
            let oldDataPenMed = state.pendingMedias;
            let newDataPendMed = action.payload;
            oldDataPenMed = oldDataPenMed.concat(newDataPendMed);
            let updatedPMedia = new Map();
            for (const tag of oldDataPenMed) {
                updatedPMedia.set(tag.post_id, tag);
            }
            let mPResult = [...updatedPMedia.values()]
            return {
                ...state,
                pendingMedias: mPResult,
                pendingMediaHasMore: action.hasmore,
                mpCurrentPage: action.currentPage,
                loading: false
            }
        case DELETE_MEDIA:
            return {
                ...state,
                medias: state.medias.filter(res => res.post_id !== action.payload),
                loading: false
            }
        case SET_GROUP:
            return {
                ...state,
                groups: action.payload,
                loading: false
            }
        case SET_MY_GROUP:
            return {
                ...state,
                myGroups: action.payload,
                loading: false
            }
        case SET_REQUESTED_LIST:
            return {
                ...state,
                requesters: action.payload,
                loading: false
            }
        case SET_PATRIOT_USER:
            let oldDataU = state.patriotUsers;
            let newDataU = action.payload;
            let currentPage = action.currentPage;
            oldDataU = (currentPage === 1 ? newDataU : oldDataU.concat(newDataU))
            let updatedData = new Map();
            for (const user of oldDataU) {
                updatedData.set(user.id, user);
            }
            let result = [...updatedData.values()]
            return {
                ...state,
                patriotUsers: result,
                patriotUsersHasMore: action.hasmore,
                pgCurrentPage: currentPage,
                loading: false
            }
        case DELETE_USER:
            return {
                ...state,
                patriotUsers: state.patriotUsers.filter(res => res.id !== action.payload),
                loading: false
            }
        case ARTICLE_DETAIL:
            return {
                ...state,
                articleDetail: action.payload,
                loading: false
            }
        case MEDIA_DETAIL:
            return {
                ...state,
                mediaDetail: action.payload,
                loading: false
            }
        case SET_STATES:
            return {
                ...state,
                states: action.payload,
                loading: false
            }
        case SET_USER_DETAIL:
            return {
                ...state,
                userDetail: action.payload,
                loading: false
            }
        case SET_FLAGGED:
            return {
                ...state,
                flaggedItems: action.payload,
                loading: false
            }
        case CHANGE_POST_STATUS:
            if (action.postType === 1) {
                return {
                    ...state,
                    pendingArticles: state.pendingArticles.filter(res => res.article_id !== action.payload),
                    loading: false
                }
            } else if (action.postType === 2) {
                return {
                    ...state,
                    pendingEvents: state.pendingEvents.filter(res => res.eventid !== action.payload),
                    loading: false
                }
            }
            else if (action.postType === 3) {
                return {
                    ...state,
                    pendingMedias: state.pendingMedias.filter(res => res.post_id !== action.payload),
                    loading: false
                }
            }
        case GROUP_ACTION:
            return {
                ...state,
                groups: state.groups.filter(res => res.groupid !== action.payload),
                loading: false
            }
        case DELETE_LEGAL:
            return {
                ...state,
                legals: state.legals.filter(res => res.legal_id !== action.payload),
                loading: false
            }
        case DELETE_AFF:
            return {
                ...state,
                affiliates: state.affiliates.filter(res => res.aff_id !== action.payload),
                loading: false
            }
        case GRP_REQUEST:
            return {
                ...state,
                requesters: state.requesters.filter(res => res.groupid !== action.payload),
                loading: false
            }
        case UNREAD_NOTIFICATIONS:
            return {
                ...state,
                unreadNotifications: action.payload,
                loading: false
            }
        case SET_NOTIFICATIONS:
            return {
                ...state,
                notifications: action.payload,
                loading: false
            }
        case DELETE_NOTIFICATION:
            return {
                ...state,
                notifications: state.notifications.filter(res => res.notid !== action.payload),
                loading: false
            }
        case SET_CARDS:
            return {
                ...state,
                cards: action.payload,
                loading: false
            }
        default:
            return state;
    }
}