import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { checkUserMultipleLogin } from '../../Redux/actions/userAction';
import { getToken } from '../../Helper/Session';
import { Loader } from '../Common/Loader';

class Header extends Component {

    componentDidMount() {
        let token = getToken() !== null ? JSON.parse(getToken()) : '';
        const params = {
            userid: token.id,
            rand_no: localStorage.getItem('log_token')
        }
        this.props.checkUserMultipleLogin(params);
    }

    render() {
        const { title, backUrl, toUrl, rightText, saveBtn, loading } = this.props;
        return (
            <header className="header">
                <div className="container-fluid">
                    <div className="header-section">
                        <Link className="header-section__back" to={backUrl}>
                            <img src="../images/back.svg" alt="Back" />
                        </Link>
                        <h2 className="page-title">{title}</h2>
                        {rightText ? (rightText === 'Exit' ? (<Link to={backUrl} className="text-white">{rightText}</Link>) : <Link to={toUrl}><button type="button" className={rightText === 'plus' ? 'add' : 'header-section__text'}>&nbsp;</button>{rightText !== 'plus' ? rightText : ''}</Link>) : (<button type="button" className="header-section__blank">&nbsp;</button>)}
                        {saveBtn ?
                            !loading ? <input type="submit" className="saveBtn" value='Save' /> : <Loader /> : ''
                        }
                    </div>
                </div>
            </header>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.user
})

const mapActionsToProps = {
    checkUserMultipleLogin
}

export default connect(mapStateToProps, mapActionsToProps)(Header);