import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { logoutUser } from '../../Redux/actions/userAction';

class Logout extends Component {

    handleLogout = () => {
        this.props.logoutUser();
    }

    render() {
        return (
            <>
                <Link to="/login" onClick={this.handleLogout} className="text-white">
                    Log Out
                </Link>
            </>
        )
    }
}


const mapStateToProps = (state) => ({
    UI: state.UI
})

const mapActionToProps = {
    logoutUser
}

export default connect(mapStateToProps, mapActionToProps)(Logout);