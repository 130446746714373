import React, { Component } from 'react';
import Map from '../Map/Map';
import { connect } from 'react-redux';
import $ from "jquery";
import Header from '../Layout/Header';
import { addNotification } from '../../Redux/actions/userAction';
import { getToken } from '../../Helper/Session';
import { Loader } from '../Common/Loader';

class MapNotification extends Component {
    constructor(props) {
        super(props)
        this.state = {
            lat: 0,
            lng: 0
        }
    }

    componentDidMount() {
        this.setState({
            lat: this.props.match.params.lat,
            lng: this.props.match.params.lng
        })
        $('#goingModal').modal('show');
    }

    handleGoing = (e) => {
        e.preventDefault();
        let token = getToken() !== null ? JSON.parse(getToken()) : '';
        const params = {
            foruser: this.props.location.state.fromId,
            fromuser: token.id,
            type: "GoingForCall",
            apikey: process.env.REACT_APP_API_KEY
        }
        const latLng = {
            lat: this.props.match.params.lat,
            lng: this.props.match.params.lng
        }
        this.props.addNotification(params, latLng);
    }

    render() {
        const { UI: { loading } } = this.props;
        return (
            <section className="main-Section">
                <Header
                    title={'Map'}
                    backUrl={'notifications'}
                />
                <div className="container-fluid midSection">
                    <Map
                        lat={parseFloat(this.state.lat)}
                        lng={parseFloat(this.state.lng)}
                    />
                    <div className="modal custom-modal initiateFreedom  fade" id="goingModal" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-body p-0 pt-4">
                                    <div className="logo"><img src="../images/logo.png" alt="Logo" /></div>
                                    <div className="text-block">
                                        <h2>Are you going?</h2>
                                        <p>Let {this.props.location.state.username} know if you're going to their aid?</p>
                                    </div>
                                    <div className="reasonList">
                                        &nbsp;
                                    </div>
                                    <div className="yesNoBtns">
                                        <button className="btn yesBtn" onClick={this.handleGoing}>{loading ? <Loader /> : 'Going'}</button>
                                        <button className="btn" data-dismiss="modal">No</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.user,
    UI: state.UI
})

const mapActionToProps = {
    addNotification
}

export default connect(mapStateToProps, mapActionToProps)(MapNotification);