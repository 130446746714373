import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { allPatriotsUserList } from '../../Redux/actions/homeAction';
import { getToken } from '../../Helper/Session';
import InfiniteScroll from "react-infinite-scroll-component";
import { toast } from 'react-toastify';
import { isEmpty } from "../../Helper/Util";
import Header from '../Layout/Header';
import { Loader } from '../Common/Loader';

class UserList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            owner: '',
            search: '',
            page: 1,
            errors: {}
        }
    }

    componentDidMount() {
        let token = getToken() !== null ? JSON.parse(getToken()) : '';
        const params = {
            apikey: process.env.REACT_APP_API_KEY,
            userid: token.id,
            page: this.state.page
        }
        this.props.allPatriotsUserList(params);
    }

    fetchMoreData = () => {
        let token = getToken() !== null ? JSON.parse(getToken()) : '';
        let newPage = this.state.page;
        newPage++;
        this.setState({ page: newPage });
        const params = {
            apikey: process.env.REACT_APP_API_KEY,
            userid: token.id,
            page: newPage
        }
        this.props.allPatriotsUserList(params)
    };

    handleOwner = (e) => {
        this.setState({
            owner: e.target.value,
            errors: {
                ...this.state.errors,
                [e.target.name]: ''
            }
        });
    }

    validate = () => {
        const { owner } = this.state;
        let errors = {};
        if (isEmpty(owner)) errors.owner = toast.error('Select atleast One User', {
            position: toast.POSITION.TOP_RIGHT
        });
        return errors;
    }

    handleOwnerSubmit = (e) => {
        e.preventDefault()
        const errors = this.validate();
        if (Object.keys(errors).length !== 0) {
            this.setState({ errors });
            return false;
        }
        this.props.history.push('add-group')
    }

    searchKeyword = (e) => {
        this.setState({
            search: e.target.value
        })
    }

    render() {
        const { home: { patriotUsers, patriotUsersHasMore } } = this.props;
        const patriotUser = patriotUsers.filter(result => {
            return result.name.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1;
        });
        let markup = patriotUsers.length > 0 ? patriotUser.length > 0 ? patriotUser.map((result, index) =>
            <li key={index}>
                <div className="userProfile">
                    <figure>
                        <img src={result.url !== '' ? process.env.REACT_APP_API_URL + result.url : '../images/logo.png'} alt="User" />
                    </figure>
                    <figcaption>
                        <h2>{result.name} @ <span>{result.username}</span></h2>
                        <p><i className="location"></i>{result.city}, {result.state}</p>
                    </figcaption>
                </div>
                <label className="userAddIcon">
                    <input
                        type="checkbox"
                        key={result.id}
                        name="owner"
                        value={result.id}
                        checked={this.state.owner === result.id}
                        onChange={e => this.handleOwner(e)}
                    />
                </label>
            </li>
        ) : <p className="no-data">No Data</p> : <Loader />;
        return (
            <section className="main-Section">
                <Header
                    title={'User List'}
                    backUrl={'chat'}
                />
                <div className="container-fluid midSection" style={{ height: "auto" }}>
                    <div className="search-form">
                        <div className="search-box">
                            <input type="text" placeholder="Keywords Search" value={this.state.search} name="search" onChange={this.searchKeyword} className="inp" />
                        </div>
                    </div>
                    <div className="content">
                        <div className="usersList">
                            <InfiniteScroll
                                dataLength={patriotUsers.length}
                                next={this.fetchMoreData}
                                hasMore={patriotUsersHasMore}
                            >
                                <ul>
                                    {markup}
                                </ul>
                            </InfiniteScroll>
                        </div>
                    </div>
                    <div className="fixedBtn">
                        <button className="btn btn-primary" onClick={(e) => this.handleOwnerSubmit(e)}>Save</button>
                    </div>
                </div>
            </section>
        )
    }
}

const mapStateToProps = (state) => ({
    home: state.home,
    UI: state.UI
})

const mapActionToProps = {
    allPatriotsUserList
}

export default connect(mapStateToProps, mapActionToProps)(withRouter(UserList));