import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { mediaList, pendingMediaList, flagMedia, deleteMedia, changePostStatus } from '../../Redux/actions/homeAction';
import { getToken } from '../../Helper/Session';
import InfiniteScroll from "react-infinite-scroll-component";
import moment from 'moment';
import InitiateFreedom from '../Common/InitiateFreedom';
import { Loader, pendLoader } from '../Common/Loader';
import { confirmAlert } from 'react-confirm-alert';
import Header from '../Layout/Header';
import Slider from "react-slick";
import { sliderSettings } from '../../Helper/Util';

class Media extends Component {
    constructor(props) {
        super(props);
        this.state = {
            moreOpened: false,
            moreOpenedPending: false,
            postId: '',
            search: '',
            page: 1,
            page_: 1
        }
    }

    componentDidMount() {
        const params = {
            apikey: process.env.REACT_APP_API_KEY,
            page: this.state.page
        }
        if (!this.props.home.medias.length) {
            this.props.mediaList(params);
        }
    }

    fetchMoreData = () => {
        let newPage = this.state.page;
        newPage++;
        if (this.props.home.mCurrentPage !== this.state.page) {
            newPage = this.props.home.mCurrentPage + 1
        }
        this.setState({ page: newPage });
        const params = {
            apikey: process.env.REACT_APP_API_KEY,
            page: newPage
        }
        this.props.mediaList(params)
    };

    fetchMorePendingData = () => {
        let newPage_ = this.state.page_;
        newPage_++;
        if (this.props.home.mpCurrentPage !== this.state.page_) {
            newPage_ = this.props.home.mpCurrentPage + 1
        }
        this.setState({ page_: newPage_ });
        const params = {
            apikey: process.env.REACT_APP_API_KEY,
            page: newPage_,
            status: 'Pending'
        }
        this.props.pendingMediaList(params)
    };

    moreOpenedToggle = (e, postId) => {
        e.preventDefault();
        this.setState({
            moreOpened: !this.state.moreOpened,
            postId: postId
        });
    }

    moreOpenedTogglePending = (e, postId) => {
        e.preventDefault();
        this.setState({
            moreOpenedPending: !this.state.moreOpenedPending,
            postId: postId
        });
    }

    handleFlag = (e, postId) => {
        e.preventDefault();
        let token = getToken() !== null ? JSON.parse(getToken()) : '';
        const params = {
            post_id: postId,
            fromid: token.id,
            apikey: process.env.REACT_APP_API_KEY
        }
        this.props.flagMedia(params);
        this.setState({
            moreOpened: !this.state.moreOpened,
        });
    }

    handleApproveDeny = (e, postId, status) => {
        e.preventDefault();
        const params = {
            post_id: postId,
            status: status,
            apikey: process.env.REACT_APP_API_KEY
        }
        this.props.changePostStatus(params, 3);
        this.setState({
            moreOpenedPending: !this.state.moreOpenedPending,
        });
    }

    searchKeyword = (e) => {
        this.setState({
            search: e.target.value
        })
    }

    handleDelete = (e, postId) => {
        e.preventDefault();
        const params = {
            postid: postId,
            apikey: process.env.REACT_APP_API_KEY
        }
        confirmAlert({
            title: 'Confirm to submit',
            message: 'Are you sure you want to delete?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        this.props.deleteMedia(params);
                    }
                },
                {
                    label: 'No',
                }
            ]
        });
        this.setState({
            moreOpened: !this.state.moreOpened,
        });
    }

    getPosts = (e, type) => {
        this.setState({
            moreOpened: false,
            moreOpenedPending: false,
            search: ''
        });
        if (type === 1) {
            const params = {
                apikey: process.env.REACT_APP_API_KEY,
                page: this.state.page,
                status: 'Approved'
            }
            if (!this.props.home.medias.length) {
                this.props.mediaList(params);
            }
        } else {
            const pending = {
                apikey: process.env.REACT_APP_API_KEY,
                page: this.state.page_,
                status: 'Pending'
            }
            if (!this.props.home.pendingMedias.length) {
                this.props.pendingMediaList(pending);
            }
        }
    }

    userProfile = (e, userId) => {
        e.preventDefault();
        this.props.history.push(`user-profile-${userId}`, 2)
    }

    render() {
        const { home: { medias, mediaHasMore, pendingMedias, pendingMediaHasMore }, UI: { loading }, user: { user } } = this.props;
        const media = medias.filter(result => {
            return result.post_title.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1;
        });
        const pendingMedia = pendingMedias.filter(result => {
            return result.post_title.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1;
        });
        let posted = medias.length > 0 ? media.length > 0 ? media.map(result => {
            let mediaToShow = result.video_url ? (<iframe height='400px' width="100%"
                src={`https://www.youtube.com/embed/${(result.video_url).split('https://youtu.be/')[1]}`}
            >
            </iframe>) : <Slider className="sliderMain" {...sliderSettings}>
                {result.images.map(m =>
                    <figure>
                        <img src={process.env.REACT_APP_API_URL + m.url} alt="Media" />
                    </figure>
                )}
            </Slider>;
            return (
                <div className="custom-card" key={result.id}>
                    <figure className="custom-card__photo mb-30">
                        {mediaToShow}
                    </figure>
                    <div className="custom-card__date">
                        <span>
                            {moment(result.dateadded).format('MM/DD/YYYY')}
                        </span>
                    </div>
                    <div className="custom-card__user-card">
                        <figure className="user-img cursor-pointer" onClick={(e) => this.userProfile(e, result.user.id)}>
                            <img src={result.user !== null ? result.user.url !== null ? process.env.REACT_APP_API_URL + result.user.url : '../images/logo.png' : '../images/logo.png'} alt="User" />
                        </figure>
                        <figcaption className="custom-card__user-card__info">
                            <span className="more_opt_v" onClick={(e) => this.moreOpenedToggle(e, result.post_id)} >
                                <img src="../images/more-dot-v.svg" alt="fig" />
                            </span>
                            <h4 className="inblockTitle" onClick={(e) => this.userProfile(e, result.user.id)}>{result.user !== '' ? result.user.name : ''}@{result.user !== '' ? result.user.username : ''}</h4>
                        </figcaption>
                    </div>
                    <div className="custom-card__description">
                        <Link to={`media-detail-${result.post_id}`}>
                            <h3>{result.post_title} - {result.statename}</h3>
                        </Link>
                        <p>{result.post_description}</p>
                    </div>
                </div>
            )
        }) : <p className="no-data">No Data</p> : <Loader />;

        let pending = pendingMedia.length > 0 ? pendingMedia.map(result => {
            let mediaToShow = result.video_url ? (<iframe height='400px' width="100%"
                src={`https://www.youtube.com/embed/${(result.video_url).split('https://youtu.be/')[1]}`}
            >
            </iframe>) : <Slider {...sliderSettings}>
                {result.images.map(m =>
                    <figure>
                        <img src={process.env.REACT_APP_API_URL + m.url} alt="Media" />
                    </figure>
                )}
            </Slider>;
            return (
                <div className="custom-card" key={result.id}>
                    <figure className="custom-card__photo mb-30">
                        {mediaToShow}
                    </figure>
                    <div className="custom-card__date">
                        <span>
                            {moment(result.dateadded).format('MM/DD/YYYY')}
                        </span>
                    </div>
                    <div className="custom-card__user-card">
                        <figure className="user-img cursor-pointer" onClick={(e) => this.userProfile(e, result.user.id)}>
                            <img src={result.user !== null ? result.user.url !== null ? process.env.REACT_APP_API_URL + result.user.url : '../images/logo.png' : '../images/logo.png'} alt="User" />
                        </figure>
                        <figcaption className="custom-card__user-card__info">
                            <span className="more_opt_v" onClick={(e) => this.moreOpenedTogglePending(e, result.post_id)} >
                                <img src="../images/more-dot-v.svg" alt="fig" />
                            </span>
                            <h4 className="inblockTitle" onClick={(e) => this.userProfile(e, result.user.id)}>{result.user !== '' ? result.user.name : ''}@{result.user !== '' ? result.user.username : ''}</h4>
                        </figcaption>
                    </div>
                    <div className="custom-card__description">
                        <Link to={`media-detail-${result.post_id}`}>
                            <h3>{result.post_title} - {result.statename}</h3>
                        </Link>
                        <p>{result.post_description}</p>
                    </div>
                </div>
            )
        }) : <p className="no-data">No Data</p>;

        return (
            <section className="main-Section">
                <Header
                    title={'Media'}
                    backUrl={'dashboard'}
                />
                <div className="container-fluid midSection" style={{ height: "auto" }}>
                    <div className="content">
                        <InitiateFreedom />
                        {user !== undefined && user.admin === "1" &&
                            <div className="patriot-group-tab">
                                <ul className="nav nav-tabs" id="myTab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link active" id="home-tab" data-toggle="tab" data-target="#posted-tab" type="button" onClick={(e) => this.getPosts(e, 1)} >Posted</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="profile-tab" data-toggle="tab" data-target="#pending-tab" type="button" onClick={(e) => this.getPosts(e, 2)}>Pending</button>
                                    </li>
                                </ul>
                            </div>
                        }
                        <div className="search-box">
                            <input type="text" placeholder="Keyword Search" value={this.state.search} name="search" onChange={this.searchKeyword} className="inp" />
                        </div>
                        <div className="tab-content" id="myTabContent">
                            <div className="tab-pane fade show active" id="posted-tab" role="tabpanel">
                                <div className="post-container">
                                    <InfiniteScroll
                                        dataLength={media.length}
                                        next={this.fetchMoreData}
                                        hasMore={mediaHasMore}
                                        loader={media.length >= 10 ? <Loader /> : ''}
                                    >
                                        <div className="post-container">
                                            {posted}
                                        </div>
                                    </InfiniteScroll>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="pending-tab" role="tabpanel">
                                <div className="post-container">
                                    <InfiniteScroll
                                        dataLength={pendingMedia.length}
                                        next={this.fetchMoreData}
                                        hasMore={pendingMediaHasMore}
                                        loader={pendingMedia.length > 1 ? <h4><pendLoader /></h4> : ''}
                                    >
                                        <div className="post-container">
                                            {pending}
                                        </div>
                                    </InfiniteScroll>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="action-btn">
                    <span className="cmn-btn addMedia">
                        <Link to="add-media">
                            <img src="../images/plus-big.svg" alt="icon" />
                        </Link>
                    </span>
                </div>
                <div className={this.state.moreOpened ? "bottomCard opened" : "bottomCard"}>
                    {user !== undefined && user.admin === "1" ?
                        <>
                            <Link to={`edit-media-${this.state.postId}`}>
                                <button className="btn btn-default mb-2">Edit</button>
                            </Link>
                            <button className="btn btn-default mb-2" onClick={(e) => this.handleDelete(e, this.state.postId)}>Delete</button>
                        </>
                        : <button className="btn btn-default mb-2" onClick={(e) => this.handleFlag(e, this.state.postId)} disabled={loading ? 'disabled' : ''}>{loading ? 'Loading' : 'Flag'}</button>
                    }
                    <button className="btn btn-plain" onClick={(e) => this.moreOpenedToggle(e)}>Cancel</button>
                </div>
                <div className={this.state.moreOpenedPending ? "bottomCard opened" : "bottomCard"}>
                    {user !== undefined && user.admin === "1" &&
                        <>
                            <button className="btn btn-default mb-2" onClick={(e) => this.handleApproveDeny(e, this.state.postId, 'Approved')}>Approve</button>
                            <Link to={`edit-media-${this.state.postId}`}>
                                <button className="btn btn-default mb-2">Edit</button>
                            </Link>
                            <button className="btn btn-default mb-2" onClick={(e) => this.handleApproveDeny(e, this.state.postId, 'Rejected')}>Deny</button>
                            <button className="btn btn-plain" onClick={(e) => this.moreOpenedTogglePending(e)}>Cancel</button>
                        </>
                    }
                </div>
            </section>
        )
    }
}

const mapStateToProps = (state) => ({
    home: state.home,
    user: state.user,
    UI: state.UI
})

const mapActionToProps = {
    mediaList,
    flagMedia,
    deleteMedia,
    changePostStatus,
    pendingMediaList
}

export default connect(mapStateToProps, mapActionToProps)(Media);