import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom';
import { inputPlaceHolders, isEmail, isEmpty } from "../../Helper/Util";
import axios from "../../Helper/Axios";
import { connect } from 'react-redux';
import { loginUser, signUp } from '../../Redux/actions/userAction';
import $ from "jquery";

class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
      apikey: 'gotyour6_AC4I_BD',
      email: '',
      password: '',
      name: '',
      userName: '',
      r_email: '',
      r_password: '',
      confirm_password: '',
      phone: '',
      emailExist: '',
      phoneExist: '',
      userNameExist: '',
      profileImg: null,
      errors: {},
      login: true,
      signUp: false,
      imgSize: '',
      isAgree: false
    }
  }

  componentDidMount() {
    inputPlaceHolders()
  }

  handleLoginClick = () => {
    this.setState({
      login: !this.state.login,
      signUp: !this.state.signUp
    })
    $('#LoginNow').removeClass('active');
    $('#createNow').removeClass('active');
  };

  handleSignUpClick = () => {
    this.setState({
      signUp: !this.state.signUp,
      login: !this.state.login,
    })
    $('#LoginNow').removeClass('active');
    $('#createNow').removeClass('active');
  };

  handleChange = e => {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value,
      errors: {
        ...this.state.errors,
        [e.target.name]: ''
      }
    })
  };

  validate = () => {
    const { email, password } = this.state;
    let errors = {};
    if (isEmpty(email)) errors.email = true;
    else if (!isEmail(email)) errors.email = true;
    if (isEmpty(password)) errors.password = true;
    return errors;
  }

  isAgreeHandle = e => {
    if (e.target.checked) {
      this.setState({
        isAgree: !this.state.isAgree,
        errors: {
          ...this.state.errors,
          [e.target.name]: ''
        }
      })
    } else {
      this.setState({
        isAgree: !this.state.isAgree,
      })
    }
  };

  // Login

  handleLogin = event => {
    event.preventDefault()
    const errors = this.validate();
    if (Object.keys(errors).length !== 0) {
      this.setState({ errors });
      return false;
    }
    localStorage.setItem('log_token', Math.floor(Math.random() * 9999999) + 111111)
    const params = {
      email: this.state.email,
      password: this.state.password,
      apikey: this.state.apikey,
      rand_no: localStorage.getItem('log_token')
    }
    this.props.loginUser(params, this.props.history);
  }

  // Check for Email

  isEmailExist = (event) => {
    const params = {
      email: event.target.value,
      apikey: this.state.apikey
    }
    axios.post(`user/checkemail.php`, params)
      .then(res => {
        if (res.data.status === '1') {
          this.setState({ emailExist: 'An account with this email address already exists!' })
        } else {
          this.setState({ emailExist: '' })
        }
      }).catch(error => {
        this.setState({ emailExist: '' })
      });
  }

  // Check for Phone Number

  isPhoneExist = (event) => {
    const params = {
      phone: event.target.value,
      apikey: this.state.apikey
    }
    axios.post(`user/checkphone.php`, params)
      .then(res => {
        if (res.data.status === '1') {
          this.setState({ phoneExist: 'This phone no has been already exist!' })
        } else {
          this.setState({ phoneExist: '' })
        }
      }).catch(error => {
        this.setState({ phoneExist: '' })
      });
  }

  // Check for userName

  isUserNameExist = (event) => {
    const params = {
      username: event.target.value,
      apikey: this.state.apikey
    }
    axios.post(`user/checkusername.php`, params)
      .then(res => {
        if (res.data.status === '1') {
          this.setState({ userNameExist: '' })
        } else {
          this.setState({ userNameExist: res.data.message })
        }
      }).catch(error => {
        this.setState({ userNameExist: '' })
      });
  }

  _profileImgFile = (e) => {
    var file = e.target.files[0];
    if (file !== undefined) {
      let reader = new FileReader();
      reader.onload = (e) => {
        let images = e.target.result;
        let img = new Image();
        img.src = images
        img.onload = () => {
          let canvas = document.createElement('canvas')
          const MAX_WIDTH = 400
          const MAX_HEIGHT = 350
          let width = img.width
          let height = img.height
          if (width > height) {
            if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width
              width = MAX_WIDTH
            }
          } else {
            if (height > MAX_HEIGHT) {
              width *= MAX_HEIGHT / height
              height = MAX_HEIGHT
            }
          }
          canvas.width = width
          canvas.height = height
          let ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, width, height);
          this.setState({
            profileImg: canvas.toDataURL().split(';base64')[1],
            imgSize: file.size,
            errors: {
              profileImg: ''
            }
          });
        }
      };
      reader.readAsDataURL(file);
    };
  }

  signUpValidate = () => {
    const { name, userName, phone, r_email, r_password, confirm_password, profileImg, imgSize, isAgree } = this.state;
    let errors = {};
    if (isEmpty(name)) errors.name = true;
    if (isEmpty(userName)) errors.userName = true;
    if (isEmpty(phone)) errors.phone = true;
    if (isEmpty(r_email)) errors.r_email = true;
    else if (!isEmail(r_email)) errors.r_email = true;
    if (isEmpty(r_password)) errors.r_password = true;
    if (isEmpty(confirm_password)) errors.confirm_password = true;
    if (profileImg === null) errors.profileImg = 'Please upload Profile Image';
    if (isAgree === false) errors.isAgree = 'Please Check here to access features';
    // else if (imgSize !== null && imgSize > 1e6) errors.profileImg = 'Size must be less than 1 mb';
    return errors;
  }

  // Signup

  handleSignUp = event => {
    event.preventDefault()
    const errors = this.signUpValidate();
    if (Object.keys(errors).length !== 0) {
      this.setState({ errors });
      return false;
    }
    const params = {
      image: this.state.profileImg,
      name: this.state.name,
      username: this.state.userName,
      email: this.state.r_email,
      password: this.state.r_password,
      phone: this.state.phone,
      apikey: this.state.apikey
    }
    this.props.signUp(params, this.props.history);
  }

  render() {

    const { errors, login, signUp } = this.state;
    const { UI: { loading } } = this.props;
    return (
      <>
        <section className="main-Section">
          <div className="login-tabs">
            <ul className="nav nav-tabs">
              <li>
                <button id="loginLink" className={`${login ? 'navlink active' : 'navlink'}`} data-toggle="tab" data-target="#login" type="button" onClick={this.handleLoginClick}>Login</button>
              </li>
              <li>
                <button id="signupLink" className={`${signUp ? 'navlink active' : 'navlink'}`} data-toggle="tab" data-target="#signup" type="button" onClick={this.handleSignUpClick}>Signup</button>
              </li>
            </ul>
          </div>
          <div className="container-fluid midSection scrollSection">
            <div className="tab-content">
              <div className={`${login ? 'tab-pane fade show active' : 'tab-pane fade'}`} id="login" >
                <div className="content login">
                  <div className="logo">
                    <Link to="/"><img src="./images/logo.png" alt="Logo" /></Link>
                  </div>
                  <form autoComplete="off" onSubmit={this.handleLogin}>
                    <div className="form-block formBox">
                      <fieldset>
                        <span className="placeholder">Email ID</span>
                        <input
                          className={`form-control input__field ${errors.email ? 'inputError' : ''}`} name="email" type="text" value={this.state.email} onChange={this.handleChange} />
                      </fieldset>
                      <fieldset>
                        <span className="placeholder">Password </span>
                        <input className={`form-control input__field ${errors.password ? 'inputError' : ''}`} name="password" type="password" value={this.state.password} onChange={this.handleChange} />
                      </fieldset>
                      <div className="forgot-link">
                        <Link to="forgot-password">Forgot Password?</Link>
                      </div>
                    </div>
                    <input type="submit" className="btn btn-primary" disabled={loading ? 'disabled' : ''} value={loading ? 'Loading...' : 'Login'} /><br />
                  </form>
                  <div className="dont-have-account">Don't have an account? <span id="createNow" data-toggle="tab" data-target="#signup" onClick={this.handleSignUpClick}>Create Now!</span></div>
                </div>
              </div>
              <div className={`${signUp ? 'tab-pane fade show active' : 'tab-pane fade'}`} id="signup">
                <div className="content login">
                  <form onSubmit={this.handleSignUp}>
                    <div className="profile-block">
                      <div className="profileImage">
                        <figure>
                          <img src={this.state.profileImg ? (`data:image/png;base64` + this.state.profileImg) : "./images/profile-pic.png"} alt="Fig" />
                        </figure>
                        <label className="addProfile">
                          <img src="./images/add.png" alt="" />
                          <input type="file" name="profileImg" id="file1" accept="image/jpeg,image/png" onChange={this._profileImgFile} />
                        </label>
                      </div>
                      <div>
                        <h4 className={`${!errors.profileImg ? 'upload-title' : 'd-none'}`}>Upload Profile Picture</h4>
                        <h4 className={`uplodError ${errors.profileImg ? 'upload-title' : ''}`}>{errors.profileImg}</h4>
                      </div>
                    </div>
                    <div className="form-block formBox">
                      <fieldset>
                        <span className="placeholder">Name</span>
                        <input type="text" name="name" className={`form-control input__field ${errors.name ? 'inputError' : ''}`} value={this.state.name} onChange={this.handleChange} autocomplete="off" />
                      </fieldset>
                      <fieldset>
                        <span className="placeholder">User Name</span>
                        <input
                          type="text"
                          className={`form-control input__field ${errors.userName ? 'inputError' : ''}`}
                          value={this.state.userName}
                          name="userName"
                          onChange={this.handleChange}
                          onKeyUp={this.isUserNameExist}
                          autocomplete="off"
                        />
                        <span className="error">{this.state.userNameExist}</span>
                      </fieldset>
                      <fieldset>
                        <span className="placeholder">Mobile Number</span>
                        <input
                          type="number"
                          className={`form-control input__field ${errors.phone ? 'inputError' : ''}`}
                          value={this.state.phone}
                          name="phone"
                          onChange={this.handleChange}
                          onKeyUp={this.isPhoneExist}
                          autocomplete="off"
                        />
                        <span className="error">{this.state.phoneExist}</span>
                      </fieldset>
                      <fieldset>
                        <span className="placeholder">Email ID</span>
                        <input
                          type="text"
                          className={`form-control input__field ${errors.r_email ? 'inputError' : ''}`}
                          value={this.state.r_email}
                          name="r_email"
                          onChange={this.handleChange}
                          onKeyUp={this.isEmailExist}
                          autocomplete="off"
                        />
                        <span className="error">{this.state.emailExist}</span>
                      </fieldset>
                      <fieldset>
                        <span className="placeholder">Password</span>
                        <input type="password" name="r_password" value={this.state.r_password} onChange={this.handleChange} className={`form-control input__field ${errors.r_password ? 'inputError' : ''}`} autocomplete="off" />
                      </fieldset>
                      <fieldset>
                        <span className="placeholder">Confirm Password</span>
                        <input type="password" name="confirm_password" value={this.state.confirm_password} onChange={this.handleChange} className={`form-control input__field ${errors.confirm_password ? 'inputError' : ''}`} autocomplete="off" />
                      </fieldset>
                      <fieldset>
                        <label class="radio-label cstm_checkbox" style={{ "color": "#000" }}>
                          <input type="checkbox" name="isAgree"
                            value={this.state.isAgree} onChange={this.isAgreeHandle}
                          />
                          I acknowledge that this account requires a <b>paid monthly subscription</b> of $2.99 and a payment method will need to be on file before accessing any features.
                          <span class="checkbox-custom" style={{ "border-radius": "0px" }}></span>
                          <span className="error" style={{ "margin": "10px 25px" }}>{errors.isAgree}</span>
                        </label>
                      </fieldset>
                    </div>
                    <input type="submit" className="btn btn-primary" disabled={loading ? 'disabled' : ''} value={loading ? 'Loading...' : 'Register'} />
                    <div className="dont-have-account">Already have account? <span id="LoginNow" data-toggle="tab" data-target="#login" onClick={this.handleLoginClick}>Login</span></div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI
})

const mapActionsToProps = {
  loginUser,
  signUp
}

export default connect(mapStateToProps, mapActionsToProps)(withRouter(Login));