import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getToken } from '../../Helper/Session';
import { withRouter } from 'react-router-dom';
import { reasonType } from "../../Helper/Constant";
import { initiateFreedomCall } from '../../Redux/actions/homeAction';
import $ from "jquery";
import { toast } from 'react-toastify';
import { Loader } from '../Common/Loader';
import { getOS } from "../../Helper/Util";

class InitiateModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reason: '',
            reasonText: '',
            groupArray: [],
            lat: 0,
            lng: 0,
            errors: {},
            isChecked: true,
            otherChecked: false
        }
    }

    handleChange = e => {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value,
            errors: {
                ...this.state.errors,
                [e.target.name]: ''
            }
        })
        if (!this.state.reasonText) {
            this.setState({
                reason: 'Other',
                otherChecked: false
            });
        } else {
            this.setState({
                reason: 'Other',
                otherChecked: true
            });
        }
    }

    handleType = (e) => {
        this.setState({
            reason: e.target.value,
            errors: {
                ...this.state.errors,
                [e.target.name]: ''
            }
        });
        if (e.target.value) {
            this.setState({
                otherChecked: false,
                reasonText: ''
            });
        }
    }

    handleChangeReasonText = (e) => {
        if (e.target.checked) {
            this.setState({
                reason: 'Other',
                otherChecked: true
            });
        }
    }

    handleGroupType = (e, grp) => {
        let groupArray = this.state.groupArray
        if (e.target.checked) {
            groupArray.push(grp.groupid)
        } else {
            const index = this.state.groupArray.indexOf(grp.groupid);
            if (index > -1) {
                this.state.groupArray.splice(index, 1);
            }
        }
        this.setState({
            groupArray
        })
    }

    handleSelectAll = (e, grp) => {
        const { isChecked } = this.state;
        let groupArray = [...this.state.groupArray];
        if (isChecked) {
            for (var i = 0; i < grp.length; i++) {
                if (groupArray.includes(grp[i].groupid) === false) {
                    groupArray.push(grp[i].groupid)
                }
            }
            this.setState({
                isChecked: !this.state.isChecked
            })
        } else {
            this.setState({
                isChecked: !this.state.isChecked
            })
            groupArray = []
        }
        this.setState({ groupArray });
    }

    isLocationEnable = () => {
        if (getOS() === 'iOS') {
            navigator.geolocation.getCurrentPosition(this.showMap);
        } else {
            navigator.geolocation.getCurrentPosition((position) => {
                this.setState({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                })
            });
        }
    }

    step2 = () => {
        this.isLocationEnable();
        if (this.state.groupArray.length === 0) {
            toast.error('Select Group First', {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        } else {
            $('#initiateFreedom2').modal('hide');
            $('#initiateFreedom3').modal('show');
            return true;
        }
    }

    showMap = (position) => {
        this.setState({
            lat: position.coords.latitude,
            lng: position.coords.longitude
        })
    }

    isReasonCheck = () => {
        this.isLocationEnable();
        if (this.state.reason === '') {
            toast.error('Select Reason First', {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        else if (this.state.reason === "Other" && this.state.reasonText === '') {
            toast.error('Enter Reason', {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        else {
            $('#initiateFreedom').modal('hide');
            $('#initiateFreedom2').modal('show');
            return true;
        }
    }

    createGrp = (e) => {
        e.preventDefault();
        $('#initiateFreedom4').modal('hide');
        this.props.history.push('create-patriot-group')
    }

    initiateCall = (e) => {
        e.preventDefault();
        if (this.state.lat === 0) {
            toast.error('Please enable location', {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        let token = getToken() !== null ? JSON.parse(getToken()) : '';
        let group = this.state.groupArray;
        if (group.length > 0) {
            for (let i = 0; i < group.length; i++) {
                const params = {
                    reason: (this.state.reason === 'Gun Seizure' || this.state.reason === 'Home Invasion') ? this.state.reason : this.state.reasonText,
                    groupid: group[i],
                    apikey: process.env.REACT_APP_API_KEY,
                    userid: token.id,
                    lat: this.state.lat,
                    lang: this.state.lng
                }
                this.props.initiateFreedomCall(params, this.props.history);
            }
        }
    }

    render() {
        const { myGroups, UI: { loading } } = this.props;
        const { lat, lng } = this.state;
        let markup = myGroups.length > 0 ? myGroups.map((result, j) =>
            <li className="selectGroup" key={j}>
                <figure>
                    <img src={result.image !== '' ? process.env.REACT_APP_API_URL + result.image : '../images/legal-counsel.png'} alt="User" />
                </figure>
                {result.groupname}
                <label className="radio-label">
                    <input
                        type="checkbox"
                        name="groupid"
                        value={result.groupid}
                        checked={this.state.groupArray.find((u) => (u) === (result.groupid))}
                        onChange={e => this.handleGroupType(e, result)}
                    />
                    <span className="checkbox-custom"></span>
                </label>
            </li>
        ) : <p className="no-data">No Data</p>;
        return (
            <>
                {myGroups.length > 0 ? (
                    <>
                        <div className="modal custom-modal initiateFreedom fade" id="initiateFreedom" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-body">
                                        <div className="logo"><img src="../images/logo.png" alt="Logo" /></div>
                                        <div className="text-block">
                                            <h2>What's the Reason?</h2>
                                        </div>
                                        <div className="reasonList">
                                            <ul>
                                                {reasonType.map((choice, i) =>
                                                    <li key={i}>
                                                        {choice.text}
                                                        <label className="radio-label">
                                                            <input
                                                                type="radio"
                                                                name="reason"
                                                                value={choice.value}
                                                                checked={this.state.reason === choice.value}
                                                                onChange={e => this.handleType(e)}
                                                            />
                                                            <span className="checkbox-custom"></span>
                                                        </label>
                                                    </li>
                                                )}
                                                <li>
                                                    <input type="text" name="reasonText" onChange={this.handleChange} placeholder="Other" value={this.state.reasonText} className="otherInp" />
                                                    <label className="radio-label">
                                                        <input
                                                            type="radio"
                                                            name="reasonText"
                                                            value={this.state.reasonText}
                                                            checked={this.state.otherChecked}
                                                            onChange={e => this.handleChangeReasonText(e)}
                                                        />
                                                        <span className="checkbox-custom"></span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                        <button type="button" className="btn btn-primary" onClick={this.isReasonCheck}>Confirm</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal custom-modal initiateFreedom fade" id="initiateFreedom2" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-body">
                                        <div className="d-flex justify-content-between">
                                            <span data-dismiss="modal">Cancel</span>
                                            <span onClick={(e) => this.handleSelectAll(e, myGroups)} >Select All</span>
                                        </div>
                                        <div className="logo"><img src="../images/logo.png" alt="Logo" /></div>
                                        <div className="text-block">
                                            <h2>Initiate Freedom?</h2>
                                            <p>Select Group</p>
                                        </div>
                                        <div className="reasonList">
                                            <ul className="scrollSection" style={{ maxHeight: '200px' }}>
                                                {markup}
                                            </ul>
                                        </div>
                                        <button type="button" className="btn btn-primary" onClick={this.step2}>Continue</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal custom-modal initiateFreedom  fade" id="initiateFreedom3" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-body p-0 pt-4">
                                        <div className="logo"><img src="../images/logo.png" alt="Logo" /></div>
                                        <div className="text-block">
                                            <h2>Initiate Freedom?</h2>
                                            <p>Confirm request for aid?</p>
                                            <p>Current Lat Long - {lat + ',' + lng}</p>
                                        </div>
                                        <div className="reasonList">
                                            &nbsp;
                                        </div>
                                        <div className="yesNoBtns">
                                            <button className="btn yesBtn"
                                                onClick={this.initiateCall}
                                            >{loading ? <Loader /> : 'Yes'}</button>
                                            <button className="btn" data-dismiss="modal">No</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <div className="modal custom-modal initiateFreedom fade" id="initiateFreedom4" aria-labelledby="exampleModalLabel" aria-modal="true" role="dialog">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-body p-0 pt-4">
                                    <div className="logo"><img src="../images/logo.png" alt="Logo" /></div>
                                    <div className="text-block">
                                        <h2>You do not belong to a Patriot Group yet</h2>
                                        <p>Let`s make one!</p>
                                    </div>
                                    <div className="reasonList">
                                        &nbsp;
                                    </div>
                                    <div className="yesNoBtns">
                                        <button className="btn yesBtn" onClick={this.createGrp}> Create</button>
                                        <button className="btn" data-dismiss="modal">Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    home: state.home,
    UI: state.UI
})

const mapActionToProps = {
    initiateFreedomCall
}

export default connect(mapStateToProps, mapActionToProps)(withRouter(InitiateModal));