import React, { Component } from 'react';
import { inputPlaceHolders, isEmpty } from "../../Helper/Util";
import { connect } from 'react-redux';
import { changeUserPassword } from '../../Redux/actions/userAction';
import Header from '../Layout/Header';
import { getToken } from '../../Helper/Session';

class ChangePassword extends Component {
    constructor(props) {
        super(props)
        this.state = this.getInitialState();
    }

    getInitialState = () => ({
        password: '',
        currentPassword: '',
        confirmPassword: '',
        errors: {}
    });

    componentDidMount() {
        inputPlaceHolders()
    }

    validate = () => {
        const { currentPassword, password, confirmPassword } = this.state;
        let errors = {};
        if (isEmpty(currentPassword)) errors.currentPassword = true;
        if (isEmpty(password)) errors.password = true;
        if (confirmPassword !== password) errors.confirmPassword = true;
        return errors;
    }

    handleChange = (e) => {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value,
            errors: {
                ...this.state.errors,
                [e.target.name]: ''
            }
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const errors = this.validate();
        let token = getToken() !== null ? JSON.parse(getToken()) : '';
        if (Object.keys(errors).length !== 0) {
            this.setState({ errors });
            return false;
        }
        const params = {
            userid: token.id,
            oldpassword: this.state.currentPassword,
            newpassword: this.state.password,
            rand_no: localStorage.getItem('log_token')
        }
        this.props.changeUserPassword(params);
        this.setState(this.getInitialState());
    }

    render() {
        const { errors } = this.state;
        const { UI: { loading } } = this.props;
        return (
            <>
                <section className="main-Section">
                    <Header
                        title={'Change Password'}
                        backUrl={'setting'}
                    />
                    <div className="container-fluid midSection scrollSection">
                        <div className="content login">
                            <div className="changePasswordImage">
                                <figure>
                                    <img src="../images/change-password-icon.png" alt="Fig" />
                                </figure>
                            </div>
                            <form onSubmit={this.handleSubmit}>
                                <div className="form-block formBox">
                                    <fieldset>
                                        <span className="placeholder">Current Password</span>
                                        <input type="password" className={`form-control input__field ${errors.currentPassword ? 'inputError' : ''}`} value={this.state.currentPassword} name="currentPassword" onChange={this.handleChange} autoComplete="off" />
                                    </fieldset>
                                    <fieldset>
                                        <span className="placeholder">New Password</span>
                                        <input type="password" className={`form-control input__field ${errors.password ? 'inputError' : ''}`} value={this.state.password} name="password" onChange={this.handleChange} autoComplete="off" />
                                    </fieldset>
                                    <fieldset>
                                        <span className="placeholder">Confirm New Password</span>
                                        <input type="password" className={`form-control input__field ${errors.confirmPassword ? 'inputError' : ''}`} value={this.state.confirmPassword} name="confirmPassword" onChange={this.handleChange} autoComplete="off" />
                                    </fieldset>
                                </div>
                                <input type="submit" value={loading ? "Loading..." : "Update Password"} className="btn btn-primary elipse" />
                            </form>
                        </div>
                    </div>
                </section>

            </>
        )
    }
}


const mapStateToProps = (state) => ({
    user: state.user,
    UI: state.UI
})

const mapActionToProps = {
    changeUserPassword
}

export default connect(mapStateToProps, mapActionToProps)(ChangePassword);
