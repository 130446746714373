import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { affiliateList, deleteAffiliate } from '../../Redux/actions/homeAction';
import moment from 'moment';
import InitiateFreedom from '../Common/InitiateFreedom';
import InfiniteScroll from "react-infinite-scroll-component";
import Header from '../Layout/Header';
import { Loader } from '../Common/Loader';
import { confirmAlert } from 'react-confirm-alert';

class AffiliatesDonation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search: '',
            moreOpened: false,
            page: 1,
            affId: ''
        }
    }

    componentDidMount() {
        const params = {
            apikey: process.env.REACT_APP_API_KEY,
            page: this.state.page,
            search: this.state.search ? this.state.search : ''
        }
        if (!this.props.home.affiliates.length) {
            this.props.affiliateList(params);
        }
    }

    fetchMoreData = () => {
        let newPage = this.state.page;
        newPage++;
        if (this.props.home.afCurrentPage !== this.state.page) {
            newPage = this.props.home.afCurrentPage + 1
        }
        this.setState({ page: newPage });
        const params = {
            apikey: process.env.REACT_APP_API_KEY,
            page: newPage,
            search: this.state.search ? this.state.search : ''
        }
        this.props.affiliateList(params)
    };

    searchKeyword = (e) => {
        this.setState({
            search: e.target.value,
            page: 1
        });
        const params = {
            apikey: process.env.REACT_APP_API_KEY,
            page: e.target.value !== '' ? this.state.page : 1,
            search: e.target.value
        }
        this.props.affiliateList(params)
    }

    userProfile = (e, userId) => {
        e.preventDefault();
        this.props.history.push(`user-profile-${userId}`, 5)
    }


    moreOpenedToggle = (e, affId, result) => {
        e.preventDefault();
        if (result !== undefined) {
            localStorage.setItem('aff', JSON.stringify(result))
        } else {
            localStorage.removeItem('aff')
        }
        this.setState({
            moreOpened: !this.state.moreOpened,
            affId: affId
        });
    }

    handleDelete = (e, affId) => {
        e.preventDefault();
        const params = {
            affid: affId,
            apikey: process.env.REACT_APP_API_KEY
        }
        confirmAlert({
            title: 'Confirm to submit',
            message: 'Are you sure you want to delete?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        this.props.deleteAffiliate(params);
                    }
                },
                {
                    label: 'No',
                }
            ]
        });
        this.setState({
            moreOpened: !this.state.moreOpened,
        });
    }

    render() {
        const { home: { affiliates, afCurrentPage, affiliatesHasMore }, user: { user } } = this.props;
        let markup = affiliates.length > 0 ? affiliates.map((result, i) =>
            <div className="custom-card" key={i}>
                <figure className="custom-card__photo">
                    <img src={result.aff_url !== '' ? process.env.REACT_APP_API_URL + result.aff_url : '../images/logo.png'} alt="Article" />
                </figure>
                <div className="custom-card__date">
                    <span>
                        {moment(result.dateadded).format('MM/DD/YYYY')}
                    </span>
                </div>
                <div className="custom-card__user-card">
                    <figure className="user-img cursor-pointer" onClick={(e) => this.userProfile(e, result.user.id)}>
                        <img src={result.aff_url === '' ? process.env.REACT_APP_API_URL + result.aff_url : '../images/logo.png'} alt="User" />
                    </figure>
                    <figcaption className="custom-card__user-card__info">
                        {user !== undefined && user.admin === "1" ?
                            <span className="more_opt_v" onClick={(e) => this.moreOpenedToggle(e, result.aff_id, result)} >
                                <img src="../images/more-dot-v.svg" alt="fig" />
                            </span> : ''
                        }
                        <h4 className="inblockTitle" onClick={(e) => this.userProfile(e, result.user.id)}>{result.user !== '' ? result.user.name : ''}@{result.user !== '' ? result.user.username : ''}</h4>
                    </figcaption>
                </div>
                <div className="custom-card__description">
                    <h3 className="counsel-card__info__title" >{result.aff_name} - {result.statename}</h3>
                    <p className="scrollSection">{result.aff_description}</p>
                    <div className="fx-jcfe">
                        <i className="iconx">
                            <a href={result.aff_website} target="_blank" rel="noreferrer" >
                                <img src="../images/glob.svg" alt="fig" />
                            </a>
                        </i>
                    </div>
                </div>
            </div>
        ) : (afCurrentPage === 1 ? <p className="no-data">No Data</p> : '');
        return (
            <section className="main-Section">
                <Header
                    title={'Affiliates/Donations'}
                    backUrl={'dashboard'}
                />
                <div className="container-fluid midSection" style={{ height: "auto" }}>
                    <div className="content">
                        <InitiateFreedom />
                        <div className="search-box">
                            <input type="text" placeholder="Keyword Search" value={this.state.search} name="search" onChange={this.searchKeyword} className="inp" />
                        </div>
                        <div className="post-container">
                            <InfiniteScroll
                                dataLength={affiliates.length}
                                next={this.fetchMoreData}
                                hasMore={affiliatesHasMore}
                                loader={affiliates.length >= 10 ? <Loader /> : ''}
                            >
                                {markup}
                            </InfiniteScroll>
                        </div>
                    </div>
                    {user !== undefined && user.admin === "1" &&
                        <div className="action-btn">
                            <span className="cmn-btn addMedia">
                                <Link to="add-affiliates-donation">
                                    <img src="../images/plus-big.svg" alt="icon" />
                                </Link>
                            </span>
                        </div>
                    }
                    {user !== undefined && user.admin === "1" &&
                        <div className={this.state.moreOpened ? "bottomCard opened" : "bottomCard"}>
                            <Link to={`edit-affiliates-donation-${this.state.affId}`}>
                                <button className="btn btn-default mb-2">Edit</button>
                            </Link>
                            <button className="btn btn-default mb-2" onClick={(e) => this.handleDelete(e, this.state.affId)}>Delete</button>
                            <button className="btn btn-plain" onClick={(e) => this.moreOpenedToggle(e)}>Cancel</button>
                        </div>
                    }
                </div>
            </section>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.user,
    home: state.home,
    UI: state.UI
})

const mapActionToProps = {
    affiliateList,
    deleteAffiliate
}

export default connect(mapStateToProps, mapActionToProps)(AffiliatesDonation);