export const types = [
    { text: 'Firearms Training', value: 'Gun Show' },
    { text: 'Fundraising', value: 'Fundraising' },
    { text: 'Legislative', value: 'Legislative' },
    { text: 'Other', value: 'Other' },
];

export const mediaType = [
    { text: 'Video', value: 'Video' },
    { text: 'Photo', value: 'Photo' }
];

export const reasonType = [
    { text: 'Gun Seizure', value: 'Gun Seizure' },
    { text: 'Home Invasion', value: 'Home Invasion' }
];
