import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { articleList, pendingArticleList, flagArticle, deleteArticle, changePostStatus } from '../../Redux/actions/homeAction';
import { getToken } from '../../Helper/Session';
import InfiniteScroll from "react-infinite-scroll-component";
import moment from 'moment';
import InitiateFreedom from '../Common/InitiateFreedom';
import { Loader } from '../Common/Loader';
import { confirmAlert } from 'react-confirm-alert';
import Header from '../Layout/Header';

class ArticlesLegislation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            moreOpened: false,
            moreOpenedPending: false,
            articleId: '',
            activeTab: 'Posted',
            search: '',
            page: 1,
            page_: 1
        }
    }

    componentDidMount() {
        const params = {
            apikey: process.env.REACT_APP_API_KEY,
            page: this.state.page,
            status: 'Approved',
            search: this.state.search ? this.state.search : ''
        }
        this.props.articleList(params);
    }

    fetchMoreData = () => {
        let newPage = this.state.page;
        newPage++;
        if (this.props.home.aCurrentPage !== this.state.page) {
            newPage = this.props.home.aCurrentPage + 1
        }
        this.setState({ page: newPage });
        const params = {
            apikey: process.env.REACT_APP_API_KEY,
            page: newPage,
            status: 'Approved',
            search: this.state.search ? this.state.search : ''
        }
        this.props.articleList(params)
    };

    fetchMorePendingData = () => {
        let newPage_ = this.state.page_;
        newPage_++;
        this.setState({ page: newPage_ });
        const params = {
            apikey: process.env.REACT_APP_API_KEY,
            page: newPage_,
            status: 'Pending',
            search: this.state.search ? this.state.search : ''
        }
        this.props.pendingArticleList(params)
    };

    moreOpenedToggle = (e, articleId) => {
        e.preventDefault();
        this.setState({
            moreOpened: !this.state.moreOpened,
            articleId: articleId
        });
    }

    moreOpenedTogglePending = (e, articleId) => {
        e.preventDefault();
        this.setState({
            moreOpenedPending: !this.state.moreOpenedPending,
            articleId: articleId
        });
    }

    handleFlag = (e, articleId) => {
        e.preventDefault();
        let token = getToken() !== null ? JSON.parse(getToken()) : '';
        const params = {
            article_id: articleId,
            fromid: token.id,
            apikey: process.env.REACT_APP_API_KEY
        }
        this.props.flagArticle(params);
        this.setState({
            moreOpened: !this.state.moreOpened,
        });
    }

    handleDelete = (e, articleId) => {
        e.preventDefault();
        const params = {
            articleid: articleId,
            apikey: process.env.REACT_APP_API_KEY
        }
        confirmAlert({
            title: 'Confirm to submit',
            message: 'Are you sure you want to delete?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        this.props.deleteArticle(params);
                    }
                },
                {
                    label: 'No',
                }
            ]
        });
        this.setState({
            moreOpened: !this.state.moreOpened,
        });
    }

    searchKeyword = (e) => {
        this.setState({
            search: e.target.value,
            page: 1
        });
        const { activeTab } = this.state;
        if (activeTab === 'Posted') {
            const params = {
                apikey: process.env.REACT_APP_API_KEY,
                page: e.target.value !== '' ? this.state.page : 1,
                search: e.target.value,
                status: 'Approved'
            }
            this.props.articleList(params)
        } else {
            const params_ = {
                apikey: process.env.REACT_APP_API_KEY,
                page: e.target.value !== '' ? this.state.page_ : 1,
                status: 'Pending',
                search: e.target.value
            }
            this.props.pendingArticleList(params_)
        }
    }

    getArticlePosts = (e, type) => {
        this.setState({
            moreOpened: false,
            moreOpenedPending: false,
            search: ''
        });
        if (type === 1) {
            this.setState({
                activeTab: 'Posted'
            });
            const params = {
                apikey: process.env.REACT_APP_API_KEY,
                page: this.state.page,
                status: 'Approved'
            }
            this.props.articleList(params);
        } else {
            this.setState({
                activeTab: 'Pending'
            });
            const pending = {
                apikey: process.env.REACT_APP_API_KEY,
                page: this.state.page_,
                status: 'Pending'
            }
            this.props.pendingArticleList(pending);
        }
    }

    handleApproveDeny = (e, articleId, status) => {
        e.preventDefault();
        const params = {
            article_id: articleId,
            status: status,
            apikey: process.env.REACT_APP_API_KEY
        }
        this.props.changePostStatus(params, 1);
        this.setState({
            moreOpenedPending: !this.state.moreOpenedPending,
        });
    }

    userProfile = (e, userId) => {
        e.preventDefault();
        this.props.history.push(`user-profile-${userId}`, 4)
    }

    render() {
        const { home: { articles, articlesHasMore, aCurrentPage, pendingArticles, pendingArticlesHasMore, peCurrentPage }, UI: { loading }, user: { user } } = this.props;
        let posted = articles.length > 0 ? articles.map((result, index) => {
            return (
                <div className="custom-card" key={index}>
                    <figure className="custom-card__photo">
                        <img src={result.url !== '' ? process.env.REACT_APP_API_URL + result.url : '../images/logo.png'} alt="Article" />
                    </figure>
                    <div className="custom-card__date">
                        <span>
                            {moment(result.dateadded).format('MM/DD/YYYY')}
                        </span>
                    </div>
                    <div className="custom-card__user-card">
                        <figure className="user-img cursor-pointer" onClick={(e) => this.userProfile(e, result.user.id)}>
                            <img src={result.user !== null ? result.user.url !== null ? process.env.REACT_APP_API_URL + result.user.url : '../images/logo.png' : '../images/logo.png'} alt="User" />
                        </figure>
                        <figcaption className="custom-card__user-card__info">
                            <span className="more_opt_v" onClick={(e) => this.moreOpenedToggle(e, result.article_id)} >
                                <img src="../images/more-dot-v.svg" alt="fig" />
                            </span>
                            <h4 className="inblockTitle" onClick={(e) => this.userProfile(e, result.user.id)}>{result.user !== '' ? result.user.name : ''}@{result.user !== '' ? result.user.username : ''}</h4>
                        </figcaption>
                    </div>
                    <div className="custom-card__description">
                        <Link to={`articles-legislation-detail-${result.article_id}`}>
                            <h3>{result.article_title} - {result.statename}</h3>
                        </Link>
                        <p className="scrollSection">{result.article_description}</p>
                        <div className="fx-jcfe">
                            <i className="iconx">
                                <a href={result.weburl} target="_blank" rel="noreferrer">
                                    <img src="../images/glob.svg" alt="fig" />
                                </a>
                            </i>
                        </div>
                    </div>
                </div>
            )
        }) : (aCurrentPage === 1 ? <p className="no-data">No Data</p> : '');

        let pending = pendingArticles.length > 0 ? pendingArticles.map((res, i) => {
            return (
                <div className="custom-card" key={i}>
                    <figure className="custom-card__photo">
                        <img src={res.url !== '' ? process.env.REACT_APP_API_URL + res.url : '../images/logo.png'} alt="Article" />
                    </figure>
                    <div className="custom-card__date">
                        <span>
                            {moment(res.dateadded).format('MM/DD/YYYY')}
                        </span>
                    </div>
                    <div className="custom-card__user-card">
                        <figure className="user-img cursor-pointer" onClick={(e) => this.userProfile(e, res.user.id)}>
                            <img src={res.user !== null ? res.user.url !== null ? process.env.REACT_APP_API_URL + res.user.url : '../images/logo.png' : '../images/logo.png'} alt="User" />
                        </figure>
                        <figcaption className="custom-card__user-card__info">
                            <span className="more_opt_v" onClick={(e) => this.moreOpenedTogglePending(e, res.article_id)} >
                                <img src="../images/more-dot-v.svg" alt="fig" />
                            </span>
                            <h4 className="inblockTitle" onClick={(e) => this.userProfile(e, res.user.id)}>{res.user !== '' ? res.user.name : ''}@{res.user !== '' ? res.user.username : ''}</h4>
                        </figcaption>
                    </div>
                    <div className="custom-card__description">
                        <Link to={`articles-legislation-detail-${res.article_id}`}>
                            <h3>{res.article_title} - {res.statename}</h3>
                        </Link>
                        <p className="scrollSection">{res.article_description}</p>
                        <div className="fx-jcfe">
                            <i className="iconx">
                                <a href={res.weburl} target="_blank" rel="noreferrer">
                                    <img src="../images/glob.svg" alt="fig" />
                                </a>
                            </i>
                        </div>
                    </div>
                </div>
            )
        }) : (peCurrentPage === 1 ? <p className="no-data">No Data</p> : '');
        return (
            <section className="main-Section">
                <Header
                    title={'Articles & Legislation'}
                    backUrl={'dashboard'}
                />
                <div className="container-fluid midSection" style={{ height: "auto" }}>
                    <div className="content">
                        <InitiateFreedom />
                        {user !== undefined && user.admin === "1" &&
                            <div className="patriot-group-tab">
                                <ul className="nav nav-tabs" id="myTab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link active" id="home-tab" data-toggle="tab" data-target="#aposted-tab" type="button" onClick={(e) => this.getArticlePosts(e, 1)}>Posted</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="profile-tab" data-toggle="tab" data-target="#apending-tab" type="button" onClick={(e) => this.getArticlePosts(e, 2)}>Pending</button>
                                    </li>
                                </ul>
                            </div>
                        }
                        <div className="search-box">
                            <input type="text" placeholder="Keyword Search" value={this.state.search} name="search" onChange={this.searchKeyword} className="inp" />
                        </div>
                        <div className="tab-content" id="myTabContent">
                            <div className="tab-pane fade show active" id="aposted-tab" role="tabpanel">
                                <div className="post-container">
                                    <InfiniteScroll
                                        dataLength={articles.length}
                                        next={this.fetchMoreData}
                                        hasMore={articlesHasMore}
                                        loader={articles.length >= 10 ? <Loader /> : ''}
                                    >
                                        <ul>
                                            {posted}
                                            {aCurrentPage === 0 ? <Loader /> : ''}
                                        </ul>
                                    </InfiniteScroll>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="apending-tab" role="tabpanel">
                                <div className="post-container">
                                    <InfiniteScroll
                                        dataLength={pendingArticles.length}
                                        next={this.fetchMorePendingData}
                                        hasMore={pendingArticlesHasMore}
                                        loader={pendingArticles.length >= 10 ? <Loader /> : ''}
                                    >
                                        <ul>
                                            {pending}
                                        </ul>
                                    </InfiniteScroll>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="action-btn">
                    <span className="cmn-btn addMedia">
                        <Link to="add-article">
                            <img src="../images/plus-big.svg" alt="icon" />
                        </Link>
                    </span>
                </div>
                <div className={this.state.moreOpened ? "bottomCard opened" : "bottomCard"}>
                    {user !== undefined && user.admin === "1" ?
                        <>
                            <Link to={`edit-article-${this.state.articleId}`}>
                                <button className="btn btn-default mb-2">Edit</button>
                            </Link>
                            <button className="btn btn-default mb-2" onClick={(e) => this.handleDelete(e, this.state.articleId)}>Delete</button>
                        </>
                        : <button className="btn btn-default mb-2" onClick={(e) => this.handleFlag(e, this.state.articleId)} disabled={loading ? 'disabled' : ''}>{loading ? 'Loading' : 'Flag'}</button>
                    }
                    <button className="btn btn-plain" onClick={(e) => this.moreOpenedToggle(e)}>Cancel</button>
                </div>
                <div className={this.state.moreOpenedPending ? "bottomCard opened" : "bottomCard"}>
                    {user !== undefined && user.admin === "1" &&
                        <>
                            <button className="btn btn-default mb-2" onClick={(e) => this.handleApproveDeny(e, this.state.articleId, 'Approved')}>Approve</button>
                            <Link to={`edit-article-${this.state.articleId}`}>
                                <button className="btn btn-default mb-2">Edit</button>
                            </Link>
                            <button className="btn btn-default mb-2" onClick={(e) => this.handleApproveDeny(e, this.state.articleId, 'Rejected')}>Deny</button>
                            <button className="btn btn-plain" onClick={(e) => this.moreOpenedTogglePending(e)}>Cancel</button>
                        </>
                    }
                </div>
            </section>
        )
    }
}

const mapStateToProps = (state) => ({
    home: state.home,
    user: state.user,
    UI: state.UI
})

const mapActionToProps = {
    articleList,
    flagArticle,
    deleteArticle,
    changePostStatus,
    pendingArticleList
}

export default connect(mapStateToProps, mapActionToProps)(ArticlesLegislation);