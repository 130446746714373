import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Logout from '../Common/Logout';

class BecomeMember extends Component {

    render() {
        return (
            <section className="main-Section">
                <header className="header">
                    <div className="container-fluid">
                        <div className="header-section">
                            <Link to="our-mission" className="header-section__back">
                                <img src="../images/back.svg" alt="Back" />
                            </Link>
                            <h2 className="page-title">Become a Member</h2>
                            <Logout />
                        </div>
                    </div>
                </header>
                <div className="container-fluid midSection scrollSection our-mission">
                    <div className="content">
                        <div className="become-member-content">
                            <div className="logo"><img src="../images/logo.png" alt="Logo" /></div>
                            <h2>Become a Member?</h2>
                            <p>Subscribe to a monthly plan for<br /> just <strong>$2.99</strong> to get access to all<br /> features of the app.</p>
                        </div>
                        <Link to="subscribe">
                            <button type="button" className="btn btn-primary">Subscribe Now</button>
                        </Link>
                    </div>
                </div>
            </section>
        )
    }
}

export default BecomeMember;