import React, { Component } from 'react';
import { connect } from 'react-redux';
import { editPost, mediaDetail } from '../../Redux/actions/homeAction';
import { stateList } from '../../Redux/actions/userAction';
import { getToken } from '../../Helper/Session';
import { inputPlaceHolders, isEmpty, getBase64Image } from "../../Helper/Util";
import { mediaType } from "../../Helper/Constant";
import { Loader } from '../Common/Loader';
import Header from '../Layout/Header';

class EditMedia extends Component {
    constructor(props) {
        super(props)
        this.state = {
            post_id: '',
            title: '',
            state: '',
            you_tube_link: '',
            description: '',
            type: 'Video',
            image: [],
            url: [],
            height: 0,
            width: 0,
            errors: {}
        }
    }
    componentDidUpdate() {
        inputPlaceHolders()
    }

    componentDidMount() {
        this.setState({
            post_id: this.props.match.params.id
        })
        const params = {
            apikey: process.env.REACT_APP_API_KEY,
        }
        this.props.stateList(params);
        const mParams = {
            apikey: process.env.REACT_APP_API_KEY,
            post_id: this.props.match.params.id
        }
        this.props.mediaDetail(mParams);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { home: { mediaDetail } } = nextProps;
        let mediaUrls = mediaDetail.images ? mediaDetail.images : [];
        let prevPropImg = this.props.home.mediaDetail ? this.props.home.mediaDetail.images : 0
        if (mediaUrls.length > 0) {
            for (const index in mediaUrls) {
                if (mediaUrls[index] !== prevPropImg) {
                    let imgAry = [];
                    let imgLen = mediaUrls.length;
                    if (imgLen > 0) {
                        for (var i = 0; i < imgLen; i++) {
                            getBase64Image(process.env.REACT_APP_API_URL + mediaUrls[i].url).then(res => {
                                imgAry.push(res.split(';base64')[1])
                                this.setState({ image: imgAry })
                            })
                        }
                    }
                }
            }
        }
        this.setState({
            title: mediaDetail.post_title ? mediaDetail.post_title : '',
            description: mediaDetail.post_description ? mediaDetail.post_description : '',
            state: mediaDetail.stateid ? mediaDetail.stateid : '',
            you_tube_link: mediaDetail.video_url ? mediaDetail.video_url : '',
            url: mediaDetail.images ? mediaDetail.images : [],
            type: mediaDetail.video_url ? 'Video' : 'Photo',
        })
    }

    handleChange = e => {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value,
            errors: {
                ...this.state.errors,
                [e.target.name]: ''
            }
        })
    }

    handleType = (e) => {
        this.setState({
            type: e.target.value,
            errors: {
                ...this.state.errors,
                [e.target.name]: ''
            }
        });
        if (e.target.value === 'Photo') {
            this.setState({
                you_tube_link: ''
            })
        }
    }

    handleImgChange = (e) => {
        var file = e.target.files[0];
        let reader = new FileReader();
        let image = this.state.image;
        reader.onload = (e) => {
            let images = e.target.result;
            image.push(images.split(';base64')[1])
            // this.setState({
            //     image: images.split(';base64')[1],
            //     errors: {
            //         image: ''
            //     }
            // });
            this.setState({
                image
            })
        };
        reader.readAsDataURL(file);
    };

    handleDelete = (img) => {
        let image = this.state.image
        const index = this.state.image.indexOf(img);
        if (index > -1) {
            this.state.image.splice(index, 1);
        }
        this.setState({
            image
        })
    };

    validate = () => {
        const { title, description, type, you_tube_link } = this.state;
        let errors = {};
        if (isEmpty(title)) errors.title = true;
        if (isEmpty(description)) errors.description = true;
        if (type === 'Video') {
            if (isEmpty(you_tube_link)) errors.you_tube_link = true;
        }
        return errors;
    }

    handleSubmit = (e) => {
        e.preventDefault()
        let token = getToken() !== null ? JSON.parse(getToken()) : '';
        const errors = this.validate();
        if (Object.keys(errors).length !== 0) {
            this.setState({ errors });
            return false;
        }
        const { home: { mCurrentPage } } = this.props;
        if (this.state.type === 'Photo') {
            const params = {
                post_id: this.state.post_id,
                post_title: this.state.title,
                post_description: this.state.description,
                state: this.state.state,
                images: this.state.image,
                height: this.state.height,
                width: this.state.width,
                userid: token.id,
                apikey: process.env.REACT_APP_API_KEY
            }
            this.props.editPost(params, this.props.history, token.admin, mCurrentPage);
        }
        else {
            const params = {
                post_id: this.state.post_id,
                post_title: this.state.title,
                post_description: this.state.description,
                state: this.state.state,
                video_url: this.state.you_tube_link,
                height: this.state.height,
                width: this.state.width,
                userid: token.id,
                apikey: process.env.REACT_APP_API_KEY
            }
            this.props.editPost(params, this.props.history, token.admin, mCurrentPage);
        }
    }

    render() {
        const { errors } = this.state;
        const { home: { states }, UI: { loading } } = this.props;
        let stateMarkup = !loading ? states !== undefined && states.length > 0 ?
            states.map(result => <option value={result.id} selected={result.id === this.state.state} key={result.id}>{result.name}</option>) :
            <p>No Data</p> : <Loader />;
        return (
            <section className="main-Section">
                <form className="form-block formBox" onSubmit={this.handleSubmit}>
                    <Header
                        title={'Edit Media'}
                        backUrl={'media'}
                        saveBtn={true}
                        loading={loading}
                    />
                    <div className="container-fluid midSection scrollSection">
                        <div className="content">
                            <fieldset>
                                <span className="placeholder">Title</span>
                                <input type="text" name="title" className={`form-control input__field ${errors.title ? 'inputError' : ''}`} value={this.state.title} onChange={this.handleChange} />
                            </fieldset>
                            <div className="d-flex mb-25">
                                {mediaType.map((choice, i) =>
                                    <label className={`radio-label roundType ${choice.value === 'Photo' ? 'ml-30' : ''}`}>
                                        <input
                                            type="radio"
                                            key={i}
                                            name="type"
                                            value={choice.value}
                                            checked={this.state.type === choice.value}
                                            onChange={e => this.handleType(e)}
                                        />
                                        <span className="checkbox-custom"></span>
                                        <span className="labelName">{choice.text}</span>
                                    </label>
                                )}
                            </div>
                            <fieldset className="selectInput redAro">
                                <span className="placeholder">State(Optional)</span>
                                <select className={`form-control input__field ${errors.state ? 'inputError' : ''}`} name="state" value={this.state.state} onChange={this.handleChange}>
                                    <option value=''>Select State</option>
                                    {stateMarkup}
                                </select>
                            </fieldset>
                            {this.state.type === 'Video' &&
                                <fieldset>
                                    <span className="placeholder">You Tube Link</span>
                                    <input type="text" name="you_tube_link" className={`form-control input__field ${errors.you_tube_link ? 'inputError' : ''}`} value={this.state.you_tube_link} onChange={this.handleChange} />
                                </fieldset>
                            }
                            <fieldset className="input--filled">
                                <span className="placeholder">Description</span>
                                <textarea name="description" placeholder="Start Typing here" className={`form-control input__field ${errors.description ? 'inputError' : ''}`} value={this.state.description} onChange={this.handleChange} ></textarea>
                            </fieldset>
                            {this.state.type === 'Photo' &&
                                <div className="uploadedItems">
                                    {this.state.image.map((img, im) => {
                                        return (
                                            <div className="uploadedItems__item">
                                                <img className="uploadedFig" src={`data:image/png;base64` + img} alt="fig"></img>
                                                {this.state.image.length > 1 &&
                                                    <button type="button" className="cross-red-btn" aria-label="Close"
                                                        onClick={() => this.handleDelete(img)}
                                                    >&nbsp;</button>
                                                }
                                            </div>
                                        )
                                    })}
                                    <div className="uploadedItems__item">
                                        <label className="uploadedItems__item__addMore">
                                            <input type="file" name="image" onChange={this.handleImgChange} />
                                            <img src="/images/add-upload-photo.svg" alt="Add" />
                                        </label>
                                    </div>
                                    <div>
                                        <h4 className={`uplodError ${errors.image ? 'upload-title mb-0' : ''}`}>Please Upload Image</h4>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </form>
            </section>
        )
    }
}

const mapStateToProps = (state) => ({
    home: state.home,
    UI: state.UI
})

const mapActionToProps = {
    editPost,
    stateList,
    mediaDetail
}

export default connect(mapStateToProps, mapActionToProps)(EditMedia);