import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { myGroupsList } from '../../Redux/actions/homeAction';
import { Loader } from '../Common/Loader';
import { getToken } from '../../Helper/Session';
import { db } from "../Services/firebase";
import Header from '../Layout/Header';
import { addNewChat, getMyChats } from '../../Redux/actions/chatAction';

class Members extends Component {
    constructor(props) {
        super(props)
        this.state = {
            groupId: '',
            ownerId: '',
            search: '',
            members: []
        }
    }

    componentDidMount() {
        let token = getToken() !== null ? JSON.parse(getToken()) : '';
        this.setState({
            groupId: this.props.match.params.id
        })
        const params = {
            apikey: process.env.REACT_APP_API_KEY,
            userid: token.id
        }
        this.props.myGroupsList(params);
        this.props.getMyChats(token.id);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { home: { myGroups } } = nextProps
        let item = myGroups.find(item => item.groupid === this.state.groupId);
        this.setState({
            ownerId: item ? item.id : '',
            members: item ? item.members : []
        })
    }

    searchKeyword = (e) => {
        this.setState({
            search: e.target.value
        })
    }

    handleChat = (e, toUser) => {
        e.preventDefault()
        let token = getToken() !== null ? JSON.parse(getToken()) : '';
        let users = [toUser, token];
        let userObj = {};
        let unreadObj = {};
        for (let i = 0; i < users.length; i++) {
            userObj[users[i].id] = users[i].id;
            unreadObj['unread' + users[i].id] = 0;
        }
        const { chat: { myChats } } = this.props;
        let myRef = db.ref().push();
        const params = {
            authUser: token.id,
            id: myRef.key,
            title: '',
            url: '',
            unreadObj: 0,
            users: userObj
        }
        if (myChats.length > 0) {
            for (let i = 0; i < myChats.length; i++) {
                if (JSON.stringify(myChats[i].users) === JSON.stringify(userObj) && myChats[i].url === '') {
                    this.props.history.push(`chat-${myChats[i].id}`);
                    localStorage.setItem('chatDetail', JSON.stringify(myChats[i]));
                    return false
                }
            }
            this.props.addNewChat(params, users);
            this.props.history.push(`chat-${myRef.key}`);
        } else {
            this.props.addNewChat(params, users);
            this.props.history.push(`chat-${myRef.key}`);
        }
    }

    render() {
        const { members, ownerId } = this.state;
        const member = members.filter(result => {
            return result.name.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1;
        });
        let token = getToken() !== null ? JSON.parse(getToken()) : '';
        let markup = members.length > 0 ? member.length > 0 ? member.map((result, i) => {
            return (
                <li key={i}>
                    <div className="userProfile">
                        <Link className="insideLink" to={`user-detail-${this.props.match.params.id}-${result.id}`}>
                            <figure>
                                <img src={result.url !== '' ? process.env.REACT_APP_API_URL + result.url : '../images/logo.png'} alt="User" />
                            </figure>
                            <figcaption>
                                <h2><strong>{ownerId === result.id ? "(Admin)" : ''}</strong> {result.name} @<span>{result.username}</span></h2>
                                <p><i className="location"></i>{result.city}, {result.state}</p>
                            </figcaption>
                        </Link>
                    </div>
                    <div className="chatWithUser" onClick={(e) => this.handleChat(e, result)}>{token.id !== result.id ? <img src={'../images/chat-icon-member.svg'} alt="Chat" /> : ''} </div>
                </li>
            )
        }) : <p className="no-data">No Data</p> : <Loader />;

        return (
            <section className="main-Section">
                <Header
                    title={'Members'}
                    backUrl={`patriot-group-and-request`}
                />
                <div className="container-fluid midSection scrollSection userListCon">
                    <div className="search-form">
                        <div className="form">
                            <input type="text" placeholder="Search members" value={this.state.search} name="search" onChange={this.searchKeyword} className="searchInput" />
                        </div>
                    </div>
                    <div className="content">
                        <h4 className="size18">All Members</h4>
                        <div className="usersList">
                            <ul>
                                {markup}
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

const mapStateToProps = (state) => ({
    home: state.home,
    chat: state.chat,
    UI: state.UI
})

const mapActionToProps = {
    myGroupsList,
    addNewChat,
    getMyChats
}
export default connect(mapStateToProps, mapActionToProps)(Members);