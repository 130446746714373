import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { flagMedia, mediaDetail } from '../../Redux/actions/homeAction';
import { getToken } from '../../Helper/Session';
import { Loader } from '../Common/Loader';
import moment from 'moment';
import InitiateFreedom from '../Common/InitiateFreedom';
import { confirmAlert } from 'react-confirm-alert';
import Header from '../Layout/Header';
import Slider from "react-slick";
import { sliderSettings } from '../../Helper/Util';

class MediaDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            moreOpened: false,
            postId: ''
        }
    }

    componentDidMount() {
        const postId = this.props.match.params.id;
        const params = {
            apikey: process.env.REACT_APP_API_KEY,
            post_id: postId
        }
        this.props.mediaDetail(params);
    }

    moreOpenedToggle = (e, postId) => {
        e.preventDefault();
        this.setState({
            moreOpened: !this.state.moreOpened,
            postId: postId
        });
    }

    handleDelete = (e, postId) => {
        e.preventDefault();
        const params = {
            postid: postId,
            apikey: process.env.REACT_APP_API_KEY
        }
        confirmAlert({
            title: 'Confirm to submit',
            message: 'Are you sure you want to delete?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        this.props.deleteMedia(params);
                    }
                },
                {
                    label: 'No',
                }
            ]
        });
        this.setState({
            moreOpened: !this.state.moreOpened,
        });
    }

    handleFlag = (e, postId) => {
        e.preventDefault();
        let token = getToken() !== null ? JSON.parse(getToken()) : '';
        const params = {
            post_id: postId,
            fromid: token.id,
            apikey: process.env.REACT_APP_API_KEY
        }
        this.props.flagMedia(params);
        this.setState({
            moreOpened: !this.state.moreOpened,
        });
    }

    userProfile = (e, userId) => {
        e.preventDefault();
        this.props.history.push(`user-profile-${userId}`, 2)
    }
    render() {
        const { home: { mediaDetail }, UI: { loading }, user: { user } } = this.props;
        let markup = !loading ? Object.keys(mediaDetail).length > 0 ?
            <div className="custom-card">
                <figure className="custom-card__photo">
                    {mediaDetail.video_url ?
                        (<iframe height='400px' width="100%"
                            src={`https://www.youtube.com/embed/${(mediaDetail.video_url).split('https://youtu.be/')[1]}`}
                            frameborder="0" allowfullscreen>
                        </iframe>) : <Slider className="sliderMain" {...sliderSettings}>
                            {mediaDetail.images.map(m =>
                                <figure>
                                    <img src={process.env.REACT_APP_API_URL + m.url} alt="Media" />
                                </figure>
                            )}
                        </Slider>
                    }
                </figure>
                <div className="custom-card__date">
                    <span>
                        {moment(mediaDetail.dateadded).format('MM/DD/YYYY')}
                    </span>
                </div>
                <div className="custom-card__user-card">
                    <figure className="user-img cursor-pointer" onClick={(e) => this.userProfile(e, mediaDetail.user.id)}>
                        <img src={mediaDetail.user !== undefined ? process.env.REACT_APP_API_URL + mediaDetail.user.url : '../images/logo.png'} alt="User" />
                    </figure>
                    <figcaption className="custom-card__user-card__info">
                        <span className="more_opt_v" onClick={(e) => this.moreOpenedToggle(e, mediaDetail.post_id)} >
                            <img src="../images/more-dot-v.svg" alt="fig" />
                        </span>
                        <h4 className="inblockTitle" onClick={(e) => this.userProfile(e, mediaDetail.user.id)}>{mediaDetail.user !== undefined ? mediaDetail.user.name : ''}@{mediaDetail.user !== undefined ? mediaDetail.user.username : ''}</h4>
                    </figcaption>
                </div>
                <div className="custom-card__description">
                    <Link to={`media-detail-${mediaDetail.post_id}`}>
                        <h3>{mediaDetail.post_title}</h3>
                    </Link>
                    <p>{mediaDetail.post_description}</p>
                </div>
            </div>

            : <p className="no-data">No Data</p> : <Loader />
        return (
            <section className="main-Section">
                <Header
                    title={'Media Detail'}
                    backUrl={'media'}
                />
                <div className="container-fluid midSection scrollSection">
                    <div className="content">
                        <InitiateFreedom />
                        <div className="post-container">
                            {markup}
                        </div>
                    </div>
                </div>
                <div className={this.state.moreOpened ? "bottomCard opened" : "bottomCard"}>
                    {user !== undefined && user.admin === "1" ?
                        <>
                            <Link to={`edit-media-${this.state.postId}`}>
                                <button className="btn btn-default mb-2">Edit</button>
                            </Link>
                            <button className="btn btn-default mb-2" onClick={(e) => this.handleDelete(e, this.state.postId)}>Delete</button>
                        </>
                        : <button className="btn btn-default mb-2" onClick={(e) => this.handleFlag(e, this.state.postId)} disabled={loading ? 'disabled' : ''}>{loading ? 'Loading' : 'Flag'}</button>
                    }
                    <button className="btn btn-plain" onClick={(e) => this.moreOpenedToggle(e)}>Cancel</button>
                </div>
            </section>
        )
    }
}

const mapStateToProps = (state) => ({
    home: state.home,
    user: state.user,
    UI: state.UI
})

const mapActionToProps = {
    flagMedia,
    mediaDetail
}

export default connect(mapStateToProps, mapActionToProps)(MediaDetail);