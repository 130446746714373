import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getToken } from '../../Helper/Session';
import { inputPlaceHolders, isEmpty, currentTime, getDate, validURL } from "../../Helper/Util";
import { db, storage } from "../Services/firebase";
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import { setChatMessageAsRead, getChatDetail, deleteChat } from '../../Redux/actions/chatAction';
import { sendNotification } from '../../Redux/actions/userAction';
import { Loader } from '../Common/Loader';

class Chat extends Component {
    constructor(props) {
        super(props);
        this.state = {
            content: '',
            moreOpened: false,
            errors: {},
            msgId: '',
            grpName: '',
            messages: [],
            image: null,
            currentCount: 10,
            myChatMsgs: [],
            loading: false,
            fileloading: false,
            percent: 0
        };
        this.myRef = React.createRef();
    }

    componentDidUpdate(prevProps) {
        if (!this.state.content) {
            var messageBody = document.querySelector('.messages-container');
            messageBody.scrollTop = messageBody.scrollHeight - messageBody.clientHeight;
        }
        if (prevProps.chat.chatDetail.lastmessagedate !== this.props.chat.chatDetail.lastmessagedate) {
            var messageBody = document.querySelector('.messages-container');
            messageBody.scrollTop = messageBody.scrollHeight - messageBody.clientHeight;
            this.updateChatMessages();
        }
    }

    updateChatMessages = () => {
        let msgId = this.props.match.params.id;
        let token = getToken() !== null ? JSON.parse(getToken()) : '';
        this.props.setChatMessageAsRead(msgId, token);
        this.props.getChatDetail(msgId, this.props.history);
    }

    moreOpenedToggle = (e, users) => {
        this.setState({
            moreOpened: !this.state.moreOpened,
        });
        if (users !== undefined) {
            localStorage.setItem('chatUsers', JSON.stringify(Object.values(users)))
        } else {
            localStorage.removeItem('chatUsers')
        }
    }

    removeUnreadKey = (chatId) => {
        let unreadData = JSON.parse(localStorage.getItem('unreadCount'));
        if (unreadData !== null && unreadData.length > 0) {
            const index = unreadData.find((res) => res.id === chatId);
            if (index !== undefined) {
                if (index.id === chatId) {
                    unreadData.splice(index.id, 1);
                    localStorage.setItem("unreadCount", JSON.stringify(unreadData))
                }
            }
        }
    }

    async componentDidMount() {
        inputPlaceHolders();
        let token = getToken() !== null ? JSON.parse(getToken()) : '';
        this.setState({
            msgId: this.props.match.params.id
        })
        let msgId = this.props.match.params.id;
        this.removeUnreadKey(msgId);
        this.props.getChatDetail(msgId, this.props.history);
        this.props.setChatMessageAsRead(msgId, token);
        this.setState({ loading: true });
        try {
            db.ref("Messages").child(msgId).on("value", snapshot => {
                let myChatMsgs = [];
                snapshot.forEach((snap) => {
                    myChatMsgs.push(snap.val());
                });
                let userData = JSON.parse(localStorage.getItem('userMedia'));
                if (userData !== null) {
                    for (let j = 0; j < myChatMsgs.length; j++) {
                        if (token.id !== myChatMsgs[j].from) {
                            for (let k = 0; k < userData.length; k++) {
                                if (myChatMsgs[j].from === userData[k].id) {
                                    myChatMsgs[j].name = userData[k].name
                                    myChatMsgs[j].img = userData[k].img
                                }
                            }
                        }
                    }
                }
                myChatMsgs.sort(function (a, b) { return a.time - b.time })
                this.setState({ myChatMsgs, loading: false });
            });
        } catch (error) {
            this.setState({ loading: false });
        }
    }

    handleChange = (e) => {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value,
            errors: {
                ...this.state.errors,
                [e.target.name]: ''
            }
        })
    }

    validate = () => {
        const { content } = this.state;
        let errors = {};
        if (isEmpty(content)) errors.content = true;
        return errors;
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const errors = this.validate();
        if (Object.keys(errors).length !== 0) {
            this.setState({ errors });
            return false;
        }
        let detail = JSON.parse(localStorage.getItem('chatDetail'));
        let toUsers = Object.keys(detail.users);
        let token = getToken() !== null ? JSON.parse(getToken()) : '';
        let index = toUsers.indexOf(token.id);
        if (index > -1) {
            toUsers.splice(index, 1);
        }
        let chatId = this.props.match.params.id;
        let text = this.state.content;
        let mediaType = 0;
        if (validURL(text) === true) {
            mediaType = 5
        }
        try {
            db.ref("Chats").child(chatId).once("value", (snap) => {
                if (snap.val() !== null) {
                    if (Object.keys(snap.val().users).length === 1) {
                        this.props.history.push('chat');
                        toast.error('User left the chat!', {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        return false
                    }
                    let myRef = db.ref().child(chatId).push();
                    let msgKeyExist = db.ref('Messages').child(chatId);
                    msgKeyExist.once('value', function (snap) {
                        db.ref('Messages').child(chatId).child(myRef.key).update({
                            from: token.id,
                            to: toUsers,
                            id: myRef.key,
                            status: false,
                            text: text,
                            mediaType: mediaType,
                            mailed: 0,
                            time: currentTime()
                        });
                    });
                    db.ref('Chats').child(chatId).update({
                        lastmessage: text,
                        lastmessagedate: currentTime(),
                    });
                    db.ref('MyChats').child(token.id).child(chatId).update({
                        lastmessage: text,
                        lastmessagedate: currentTime(),
                    });
                    for (let i = 0; i < toUsers.length; i++) {
                        const sendParam = {
                            apikey: process.env.REACT_APP_API_KEY,
                            foruser: toUsers[i],
                            fromuser: token.id,
                            type: "Message",
                            chatid: chatId
                        }
                        this.props.sendNotification(sendParam);
                        db.ref('MyChats').child(toUsers[i]).child(chatId).update({
                            lastmessage: text,
                            lastmessagedate: currentTime(),
                        });
                    }
                } else {
                    this.props.history.push('chat');
                    toast.error('Chat doesn`t exist!', {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            });
        } catch (error) {
            toast.error('Something went wrong!', {
                position: toast.POSITION.TOP_RIGHT
            });
        }
        this.setState({ content: '' });
    }

    deleteChatOrLeftGroup = (e, chatId, type) => {
        e.preventDefault();
        this.props.deleteChat(chatId, type, this.props.history);
        this.setState({
            moreOpened: !this.state.moreOpened,
        });
    }

    editChat = (e) => {
        e.preventDefault()
        const { msgId } = this.state;
        let users = JSON.parse(localStorage.getItem('chatUsers'));
        this.props.history.push(`edit-chat-${msgId}`, users)
    }

    uploadImg = (uploadImg, file) => {
        let mediaType = 0;
        let pdfName = '';
        if (file.type.split('/')[0] === 'audio') {
            mediaType = 1;
        } else if (file.type.split('/')[0] === 'video') {
            mediaType = 2;
        } else if (file.type.split('/')[0] === 'application') {
            mediaType = 3;
            pdfName = file.name
        } else if (file.type.split('/')[0] === 'image') {
            mediaType = 4;
        }
        let token = getToken() !== null ? JSON.parse(getToken()) : '';
        let detail = JSON.parse(localStorage.getItem('chatDetail'))
        let toUsers = Object.keys(detail.users);
        const index = toUsers.indexOf(token.id);
        let chatKey = this.props.match.params.id;
        if (index > -1) {
            toUsers.splice(index, 1);
        }
        db.ref("Chats").child(chatKey).once("value", (snap) => {
            if (snap.val() !== null) {
                if (Object.keys(snap.val().users).length === 1) {
                    this.props.history.push('chat');
                    toast.error('User left the chat!', {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    return false
                }
            }
            this.setState({ fileloading: true })
            if (uploadImg !== null) {
                const storageRef = storage.ref();
                const encodedImg = file.name.split('.')[0] + '_' + currentTime() + '.' + file.name.split('.')[1];
                const imageRef = storageRef.child("message_images/" + encodedImg);
                imageRef.put(uploadImg)
                    .then((res) => {
                        if (res.state === 'success') {
                            let myRef = db.ref().child(chatKey).push();
                            let msgKeyExist = db.ref('Messages').child(chatKey);
                            msgKeyExist.once('value', function (snap) {
                                db.ref('Messages').child(chatKey).child(myRef.key).update({
                                    from: token.id,
                                    to: toUsers,
                                    id: myRef.key,
                                    status: false,
                                    imageUrl: "https://firebasestorage.googleapis.com/v0/b/got-your-6.appspot.com/o/message_images" + encodeURIComponent('/' + encodedImg) + "?alt=media&token=" + uuidv4(),
                                    imageHeight: 300,
                                    imageWidth: 300,
                                    mediaType: mediaType,
                                    pdfName: pdfName,
                                    mailed: 0,
                                    time: currentTime()
                                });
                            });
                            this.setState({ fileloading: false });
                        }
                    }).catch((error) => {
                        toast.error(error.message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    });
            } else {
                this.setState({ fileloading: false });
                toast.error('Please upload an image first', {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        });
    }

    onImageChange = (e) => {
        const reader = new FileReader();
        let file = e.target.files[0];
        if (file) {
            reader.onload = () => {
                if (reader.readyState === 2) {
                    this.setState({
                        image: file
                    });
                    this.uploadImg(this.state.image, file)
                }
            };
            reader.readAsDataURL(e.target.files[0]);
        } else {
            this.setState({
                image: null
            });
        }
    }

    render() {
        const { moreOpened, errors, myChatMsgs, loading } = this.state;
        let chatDetail = JSON.parse(localStorage.getItem('chatDetail'));
        let token = getToken() !== null ? JSON.parse(getToken()) : '';
        let markup = !loading ? (myChatMsgs.length > 0 ? myChatMsgs.map((value, key) => {
            let text = '';
            if (value.mediaType === 0) {
                text = <p className="msg">{value.text}</p>
            }
            else if (value.mediaType === 1) {
                text = <audio controls autoplay>
                    <source src={value.imageUrl} type="audio/ogg" />
                </audio>
            }
            else if (value.mediaType === 2) {
                text = <video style={{ "height": "200px", "width": "100%" }} controls autoplay>
                    <source src={value.imageUrl} type="video/mp4" />
                </video>
            }
            else if (value.mediaType === 3) {
                text = <a href={value.imageUrl} target='_blank' className="pdf-btn">{value.pdfName} <i class="far fa-file-pdf"></i></a>
            }
            else if (value.mediaType === 4) {
                text = <a class="example-image-link" href={value.imageUrl} data-lightbox="example-set"><img class="example-image" src={value.imageUrl} alt="Img" /></a>
            }
            else if (value.mediaType === 5) {
                text = <a href={value.text} target='_blank' className="web-link">{value.text}</a>
            }
            return (
                value.from === token.id ?
                    <div className="messages-loop mineMsg" key={key}>
                        <div className="messages-loop__info bdr tertiary">
                            <h5 className="size13 date">{value.time ? getDate(value.time) : ''}</h5>
                            {text}
                        </div>
                        <div className="seen">
                            {value.status ? <i className="fas fa-check-double" aria-hidden="true"></i> : <i className="fas fa-check-double unread" aria-hidden="true"></i>}
                        </div>
                    </div>
                    : <div className="messages-loop">
                        <figure className="user-img">
                            <img src={value.img ? process.env.REACT_APP_API_URL + value.img : '../images/logo.png'} alt="User" />
                        </figure>
                        <div className="messages-loop__info">
                            <div className="messages-loop__info__inside">
                                <h5 className="size13 date">{value.time ? getDate(value.time) : ''}</h5>
                                {text}
                            </div>
                            <h6 className="messages-loop__info__userName">{value.name ? value.name : 'Unknown User'}</h6>
                        </div>
                        {/* <div className="date-separator"  >
                            <span className="date-separator__date">Yesterday</span>
                        </div> */}
                    </div>
            )
        }) : <p className="start-chat">Start your chat here...</p>
        ) : <Loader />
        return (
            <section className="main-Section">
                <header className="header">
                    <div className="container-fluid">
                        <div className="header-section">
                            <Link to="chat">
                                <img src="../images/back.svg" alt="Back" />
                            </Link>
                            <h2 className="page-title">{!loading ? (chatDetail.title ? chatDetail.title : 'Unknown User') : <Loader />}</h2>
                            <button type="button" className="dot-v" onClick={(e) => this.moreOpenedToggle(e, chatDetail.users)}>&nbsp;</button>
                        </div>
                    </div>
                </header>
                <div className="container-fluid midSection chatPage">
                    <div className="messages-container scrollSection">
                        {markup}
                    </div>
                    <div className="messages-reply-section" ref={this.myRef}>
                        {this.state.fileloading ?
                            <div className="text-center loader-main fileUploader">
                                <div className="spinner-border spinner-border-sm" role="status">
                                </div>
                                <span className="size13 ml-1">Please wait</span>
                            </div>
                            : ''}
                        <div className="messages-reply-section__inside">
                            <form onSubmit={this.handleSubmit}>
                                <div className="inputLeft">
                                    <label className="uploadFileBtn">
                                        <input type="file" accept="file_extension|audio/*|video/*|image/*" onChange={(e) => this.onImageChange(e)} />
                                    </label>
                                    <input
                                        rows="2"
                                        placeholder="Enter a message"
                                        className={`inp ${errors.content ? 'inputError' : ''}`}
                                        name="content"
                                        onChange={this.handleChange} value={this.state.content}
                                    />
                                </div>
                                <div className="message-reply-action">
                                    <input className="msg-send-btn" type="submit" value='' />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className={moreOpened ? "bottomCard opened" : "bottomCard"}>
                    {token.id === chatDetail.admin ? (
                        <>
                            {(Object.keys(chatDetail.users).length !== 1) &&
                                <button className="btn btn-default mb-2" onClick={(e) => this.editChat(e, chatDetail)}>Edit Group Chat Details</button>
                            }
                            <button className="btn btn-default mb-2" onClick={(e) => this.deleteChatOrLeftGroup(e, this.state.msgId, true)}>Delete Chat</button>
                        </>
                    ) :
                        <button className="btn btn-default mb-2" onClick={(e) => this.deleteChatOrLeftGroup(e, this.state.msgId, false)}>Leave Chat</button>
                    }
                    <button className="btn btn-plain" onClick={(e) => this.moreOpenedToggle(e)}>Cancel</button>
                </div>
            </section>
        )
    }
}

const mapStateToProps = (state) => ({
    UI: state.UI,
    user: state.user,
    home: state.home,
    chat: state.chat
})

const mapActionToProps = {
    setChatMessageAsRead,
    getChatDetail,
    deleteChat,
    sendNotification
}

export default connect(mapStateToProps, mapActionToProps)(withRouter(Chat));