import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { addCard } from '../../Redux/actions/userAction';
import Header from '../Layout/Header';

class CreatePatriotGroup extends Component {
    constructor(props) {
        super(props)
        this.state = {
            groupid: '',
        }
    }

    componentDidMount() {
        this.setState({
            groupid: this.props.match.params.grpId
        })
    }

    render() {
        const { groupid } = this.state;
        let url = groupid ? `edit-group-${groupid}` : 'add-group';
        let isEdit = groupid ? `patroit-group-users-${groupid}` : 'patroit-group-users';
        let label = groupid ? `Continue to Update` : 'Continue';
        let title = groupid ? `Update Patriot Group` : 'Create Patriot Group';
        return (
            <section className="main-Section">
                <Header
                    title={title}
                    backUrl={'dashboard'}
                    rightText={'Exit'}
                />
                <div className="container-fluid midSection scrollSection create-patriotCon">
                    <div className="content">
                        <div className="create-patriot-content">
                            <ul className="patriotGroup">
                                <li>
                                    <label className="radio-label">
                                        <input type="radio" checked name="group" />
                                        <span className="checkbox-custom"></span>
                                    </label>
                                    <Link to={isEdit}>
                                        <figure><img src="../images/patriot-group-img1.png" alt="Patroit Group" /></figure>
                                    </Link>
                                    <figcaption>
                                        <h2>Patriots</h2>
                                        <p>Select individual people and invite them to be part of your Patroit Group</p>
                                    </figcaption>
                                </li>
                                <li>
                                    <Link to="gy-patroit-group">
                                        <figure><img src="../images/patriot-group-img2.png" alt="Join Patroit Group" /></figure>
                                    </Link>
                                    <figcaption>
                                        <h2>Patriot Groups</h2>
                                        <p>Find and join existing Patroit Groups.</p>
                                        <p>Request to join.</p>
                                    </figcaption>
                                </li>
                            </ul>
                        </div>
                        <Link to={url}>
                            <button type="button" className="btn btn-primary noshadow">{label}</button>
                        </Link>
                    </div>
                </div>
            </section>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.user,
    UI: state.UI
})

const mapActionToProps = {
    addCard
}

export default connect(mapStateToProps, mapActionToProps)(withRouter(CreatePatriotGroup));