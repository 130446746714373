import $ from "jquery";
import moment from 'moment';

export function inputPlaceHolders() {
  $(".input__field").each(function () {
    var currentInput = this;

    if ($(currentInput).val() !== "") {
      $(currentInput).parents('fieldset').addClass("input--filled");
    }

    $(currentInput).off();
    $(currentInput).on('focus', onFocus);
    $(currentInput).on('blur', onBlur);
  });

  function onFocus() {
    $(this).parents('fieldset').addClass('input--filled');
  }

  function onBlur(c) {
    if ($(this).val() === "") {
      $(this).parents('fieldset').removeClass("input--filled");
    }
  }
}

export const isKeyExist = (arr, obj) => {
  return arr.includes(obj)
}

export function isEmail(email) {
  var reg = /^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return reg.test(String(email).toLowerCase());
}

export function isEmpty(str) {
  if (typeof (str) === 'number') {
    if (str.toString().trim() === '' || str.toString().trim() === null) return true;
  } else {
    if (str.trim() === '' || str.trim() === null) return true;
  }
}

export function isMobile(mobile) {
  var reg = /^[0][1-9]\d{9}$|^[1-9]\d{9}$/;
  return reg.test(mobile);
}

export function isNumeric(val) {
  var reg = /^\s*[+-]?(\d+|\.\d+|\d+\.\d+|\d+\.)(e[+-]?\d+)?\s*$/;
  return reg.test(val);
}

export function isWebsite(url) {
  var reg = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/;
  if (!reg.test(url)) {
    return false;
  }
  return true;
}

export function maxLength(val) {
  if (val.length > 250) {
    return true
  }
  return false
}

export function hundredLen(val) {
  if (val.length > 100) {
    return true
  }
  return false
}

export function fiftyLen(val) {
  if (val.length > 50) {
    return true
  }
  return false
}

export function twentyLen(val) {
  if (val.length > 20) {
    return true
  }
  return false
}

export function thirtyLen(val) {
  if (val.length > 30) {
    return true
  }
  return false
}

export function fifteenLen(val) {
  if (val.length > 15) {
    return true
  }
  return false
}

export function tenLen(val) {
  if (val.length > 10) {
    return true
  }
  return false
}

export function getTime(date) {
  var d = new Date(date);
  var hours = d.getHours();
  var minutes = d.getMinutes();
  var ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;
  var strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
}

export function time12To24Format(time12h) {
  const [time, modifier] = time12h.split(' ');
  let [hours, minutes] = time.split(':');
  if (hours === '12') {
    hours = '00';
  }
  if (modifier === 'PM') {
    hours = parseInt(hours, 10) + 12;
  }
  return `${hours}:${minutes}:00`;
}

export function currentTime() {
  let time = Math.round((new Date()).getTime() / 1000);
  return time
}

export function msToTime(ms) {
  let minutes = (ms / (60)).toFixed(1);
  if (minutes < 60) return parseInt(minutes);
}

export function getTimeStampByDate(data) {
  let time = Math.round(data.getTime() / 1000)
  return time
}

export function getDate(data) {
  let time = new Date(data * 1000);
  return moment.utc(time).local().format('MM/DD/yyyy hh:mm a')
}

export function titleCase(str) {
  if (str !== '' && str !== undefined) {
    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(' ');
  }
}

export function convertUTCDateToLocalDate(dateInUtc) {
  //Convert to local timezone
  return new Date(dateInUtc.getTime() - dateInUtc.getTimezoneOffset() * 60 * 1000);
}

export const getBase64Image = async (url) => {
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = function () {
      const base64data = reader.result;
      resolve(base64data);
    }
  });
}

export const sliderSettings = {
  dots: true,
  arrows: false,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
};

export function getOS() {
  var userAgent = window.navigator.userAgent,
    platform = window.navigator.platform,
    macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
    windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
    iosPlatforms = ['iPhone', 'iPad', 'iPod'],
    os = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'Mac OS';
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = 'iOS';
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'Windows';
  } else if (/Android/.test(userAgent)) {
    os = 'Android';
  } else if (!os && /Linux/.test(platform)) {
    os = 'Linux';
  }
  return os;
}

export function onVisibilityChange() {
  var pageVisible = true;
  function handleVisibilityChange() {
    if (document.hidden) {
      pageVisible = false;
    } else {
      pageVisible = true;
    }
    // some function call
  }
  document.addEventListener("visibilitychange", handleVisibilityChange, false);
  window.addEventListener('focus', function () {
    pageVisible = true;
    // some function call
  }, false);
  window.addEventListener('blur', function () {
    pageVisible = false;
    // some function call
  }, false);
  return pageVisible
}

export function validURL(str) {
  var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
  return !!pattern.test(str);
}

export const parseTimestamp = (timestamp) => {
  return timestamp.toLocaleDateString('en-US', { timeZone: 'UTC' })
}