import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { myGroupsList, deleteGroup, muteUnmuteGroup, joinGrpRequestersList, groupRequest, leaveGroup } from '../../Redux/actions/homeAction';
import InitiateFreedom from '../Common/InitiateFreedom';
import { Loader } from '../Common/Loader';
import { getToken } from '../../Helper/Session';
import { confirmAlert } from 'react-confirm-alert';
import { db } from "../Services/firebase";
import { toast } from 'react-toastify';
import Header from '../Layout/Header';

class PatriotGroupAndRequest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            moreOpened: false,
            groupid: '',
            isAuth: '',
            title: '',
            url: '',
            grpUsers: [],
            isChecked: true
        }
    }

    moreOpenedToggle = (e, groupId, isAuth, grpName, grpImg, members, result) => {
        e.preventDefault();
        const { isChecked } = this.state;
        let grpUsers = [...this.state.grpUsers];
        if (isChecked) {
            for (var i = 0; i < members.length; i++) {
                grpUsers.push(members[i].id)
            }
            this.setState({
                isChecked: !this.state.isChecked
            })
        } else {
            this.setState({
                isChecked: !this.state.isChecked
            })
            grpUsers = []
        }
        this.setState({
            moreOpened: !this.state.moreOpened,
            groupid: groupId,
            isAuth: isAuth,
            title: grpName,
            url: process.env.REACT_APP_API_URL + grpImg,
            grpUsers
        });
        if (result !== undefined) {
            localStorage.setItem('grp', JSON.stringify(grpUsers))
        } else {
            localStorage.removeItem('grp')
        }
    }

    toggleFalse = (e) => {
        e.preventDefault();
        this.setState({
            moreOpened: false,
        });
    }

    componentDidMount() {
        let token = getToken() !== null ? JSON.parse(getToken()) : '';
        const params = {
            apikey: process.env.REACT_APP_API_KEY,
            userid: token.id
        }
        this.props.myGroupsList(params);
        this.props.joinGrpRequestersList(params);
    }

    handleDelete = (e, groupid) => {
        e.preventDefault();
        let token = getToken() !== null ? JSON.parse(getToken()) : '';
        const params = {
            groupid: groupid,
            apikey: process.env.REACT_APP_API_KEY
        }
        confirmAlert({
            title: 'Delete Confirmation',
            message: 'Are you sure you want to delete?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        this.props.deleteGroup(params, token.id);
                        this.setState({
                            moreOpened: !this.state.moreOpened,
                        });
                    }
                },
                {
                    label: 'No',
                }
            ]
        });
    }

    muteUnmute = (e, groupid) => {
        e.preventDefault();
        let token = getToken() !== null ? JSON.parse(getToken()) : '';
        let isChecked = e.target.checked;
        let status = false;
        if (isChecked) {
            status = true;
        }
        const params = {
            groupid: groupid,
            apikey: process.env.REACT_APP_API_KEY,
            userid: token.id,
            mute: status
        }
        this.props.muteUnmuteGroup(params)
    }

    acceptRejectRequest = (e, groupid, userid, status) => {
        e.preventDefault();
        let token = getToken() !== null ? JSON.parse(getToken()) : '';
        const params = {
            groupid: groupid,
            apikey: process.env.REACT_APP_API_KEY,
            userid: userid,
            join: status
        }
        this.props.groupRequest(params, token.id)
    }

    handleLeftGroup = (e, groupid) => {
        e.preventDefault();
        let token = getToken() !== null ? JSON.parse(getToken()) : '';
        const params = {
            groupid: groupid,
            userid: token.id,
            apikey: process.env.REACT_APP_API_KEY
        }
        this.props.leaveGroup(params, token.id);
        this.setState({
            moreOpened: false,
        });
    }

    handleChat = (e) => {
        e.preventDefault()
        let token = getToken() !== null ? JSON.parse(getToken()) : '';
        let authUser = token.id;
        let users = this.state.grpUsers;
        let userObj = {};
        let unreadObj = {};
        let groupid = this.state.groupid;
        let title = this.state.title;
        let chatImg = this.state.url;
        for (let i = 0; i < users.length; i++) {
            userObj[users[i]] = users[i];
            unreadObj['unread' + users[i]] = 0;
        }
        let history = this.props.history;
        try {
            let isExist = db.ref('Chats').child(groupid);
            isExist.once('value', function (snap) {
                if (snap.val() !== null) {
                    let index = Object.keys(snap.val().users).indexOf(token.id);
                    if (index === -1) {
                        let locSet = {
                            admin: authUser,
                            id: groupid,
                            title: title,
                            url: chatImg,
                            unreadObj: 0,
                            users: userObj
                        };
                        db.ref('Chats').child(groupid).update({
                            admin: authUser,
                            id: groupid,
                            title: title,
                            url: chatImg,
                            unreadObj: 0,
                            users: userObj
                        });
                        for (let i = 0; i < users.length; i++) {
                            let isExist = db.ref('MyChats').child(users[i]);
                            isExist.once('value', function (snap) {
                                db.ref('MyChats').child(users[i]).child(groupid).update({
                                    admin: authUser,
                                    id: groupid,
                                    title: title,
                                    url: chatImg,
                                    unreadObj: 0,
                                    users: userObj
                                });
                            })
                        }
                        localStorage.setItem('chatDetail', JSON.stringify(locSet));
                        history.push(`chat-${groupid}`)
                    } else {
                        history.push(`chat-${groupid}`);
                        localStorage.setItem('chatDetail', JSON.stringify(snap.val()));
                    }
                } else {
                    let locSet = {
                        admin: authUser,
                        id: groupid,
                        title: title,
                        url: chatImg,
                        unreadObj: 0,
                        users: userObj
                    };
                    db.ref('Chats').child(groupid).update({
                        admin: authUser,
                        id: groupid,
                        title: title,
                        url: chatImg,
                        unreadObj: 0,
                        users: userObj
                    });
                    for (let i = 0; i < users.length; i++) {
                        let isExist = db.ref('MyChats').child(users[i]);
                        isExist.once('value', function (snap) {
                            db.ref('MyChats').child(users[i]).child(groupid).update({
                                admin: authUser,
                                id: groupid,
                                title: title,
                                url: chatImg,
                                unreadObj: 0,
                                users: userObj
                            });
                        })
                    }
                    localStorage.setItem('chatDetail', JSON.stringify(locSet));
                    history.push(`chat-${groupid}`)
                }
            })
        } catch (error) {
            toast.error('Something went wrong!', {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    render() {
        const { moreOpened } = this.state;
        const { user: { user }, home: { myGroups, requesters }, UI: { loading } } = this.props;
        let markup = !loading ? myGroups.length > 0 ? myGroups.map((result, i) => {
            let members = result.members;
            let sliceMembers = members !== null && members.length >= 2 ? members.slice(0, 2) : members;
            return (
                <li key={i}>
                    <div className="userProfile" >
                        <figure>
                            <img src={result.image !== '' ? process.env.REACT_APP_API_URL + result.image : '../images/logo.png'} alt="User" />
                        </figure>
                        <figcaption>
                            <h2>{result.groupname}</h2>
                            <label className="switch">
                                <input
                                    type="checkbox"
                                    onClick={(e) => this.muteUnmute(e, result.groupid)}
                                    checked={result.muted === '1' ? true : false}
                                />
                                <span className="slider round"></span>
                            </label>
                        </figcaption>
                    </div>
                    <div className="addGroup moreOpt more_opt_v" onClick={(e) => this.moreOpenedToggle(e, result.groupid, result.id, result.groupname, result.image, members !== null ? members : 0, result)}>
                        <img src="../images/more-option.svg" alt="" />
                    </div>
                    <div className="users-loop">
                        {members !== null && members.length > 0 && sliceMembers.map((result, k) =>
                            <figure className="user-fig" key={k}>
                                <img src={result.url !== '' ? process.env.REACT_APP_API_URL + result.url : '../images/logo.png'} alt="User" />
                            </figure>
                        )}
                        <Link className="viewBtn ml-2 mr-2" to={`members-${result.groupid}`}>
                            <img src="../images/view.svg" alt="View all" />
                        </Link>
                    </div>
                </li>
            )
        }) : <p className="no-data">No Data</p> : <Loader />;

        let requestMarkup = !loading ? requesters.length > 0 ? requesters.map((resp, j) =>
            <li key={j}>
                <div className="userProfile" >
                    <figure>
                        <img src={resp.user.url !== '' ? process.env.REACT_APP_API_URL + resp.user.url : '../images/logo.png'} alt="User" />
                    </figure>
                    <figcaption>
                        <h2>{resp.user.name} @{resp.user.username}</h2>
                        <p className="pl-0">{resp.user.name} &nbsp;
                            {resp.status === "JoinGroupInvitation" && "requested to join your group"}
                            {resp.status === "JoinGroupInvitationAccepted" && "accepted your request to join your group"}
                            {resp.status === "JoinGroupInvitationRejected" && "rejected your request to join your group"}
                            {resp.status === "RequestedToJoin" && "requested to join your group"}
                            {resp.status === "JoinGroupRequest" && "requested to join your group"}
                            {resp.status === "JoingGroupRequestAccepted" && "accepted your request to join their group"}
                            {resp.status === "JoingGroupRequestRejected" && "rejected your request to join their group"}
                            &nbsp;
                            {resp.groupname}</p>
                    </figcaption>
                </div>
                <div>
                    <button type="button">
                        <img src="../images/checked.svg"
                            onClick={(e) => this.acceptRejectRequest(e, resp.groupid, resp.userid, true)}
                            alt="check"
                        />
                    </button>
                    <button type="button">
                        <img src="../images/red-cross.svg"
                            onClick={(e) => this.acceptRejectRequest(e, resp.groupid, resp.userid, false)}
                            alt="cross" />
                    </button>
                </div>
            </li>
        ) : <p className="no-data">No Data</p> : <Loader />;
        return (
            <section className="main-Section">
                <Header
                    title={'Patriot Group'}
                    backUrl={`dashboard`}
                    toUrl={`create-patriot-group`}
                    rightText='plus'
                />
                <div className="container-fluid midSection scrollSection">
                    <div className="content">
                        <InitiateFreedom />
                        <div className="patriot-group-tab">
                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link active" id="home-tab" data-toggle="tab" data-target="#patriot-group-tab" type="button">Patriot Group</button>
                                </li>
                                <li className="nav-item" role="presentation" onClick={(e) => this.toggleFalse(e)}>
                                    <button className="nav-link" id="profile-tab" data-toggle="tab" data-target="#requests-tab" type="button">Requests</button>
                                </li>
                            </ul>
                        </div>
                        <div className="tab-content" id="myTabContent">
                            <div className="tab-pane fade show active" id="patriot-group-tab" role="tabpanel">
                                <div className="usersList">
                                    <ul>
                                        {markup}
                                    </ul>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="requests-tab" role="tabpanel">
                                <div className="usersList">
                                    <ul>
                                        {requestMarkup}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={moreOpened ? "bottomCard opened" : "bottomCard"}>
                    {this.state.isAuth === (user !== undefined ? user.id : '') &&
                        <>
                            <Link to={`edit-patriot-group-${this.state.groupid}`}><button className="btn btn-default mb-2">Edit</button> </Link>
                            <button className="btn btn-default mb-2" onClick={(e) => this.handleDelete(e, this.state.groupid)}>Delete</button>
                        </>
                    }
                    {this.state.isAuth !== (user !== undefined ? user.id : '') &&
                        <button className="btn btn-default mb-2" onClick={(e) => this.handleLeftGroup(e, this.state.groupid)}>Leave Group</button>
                    }
                    <Link to={`chat-${this.state.groupid}`}>
                        <button className="btn btn-default mb-2" onClick={(e) => this.handleChat(e)}>Chat</button>
                    </Link>
                    <button className="btn btn-plain" onClick={(e) => this.moreOpenedToggle(e)}>Cancel</button>
                </div>
            </section>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.user,
    home: state.home,
    UI: state.UI
})

const mapActionToProps = {
    myGroupsList,
    deleteGroup,
    muteUnmuteGroup,
    joinGrpRequestersList,
    groupRequest,
    leaveGroup
}

export default connect(mapStateToProps, mapActionToProps)(withRouter(PatriotGroupAndRequest));