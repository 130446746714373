import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { isEmpty, inputPlaceHolders } from '../../Helper/Util'
import { connect } from 'react-redux';
import { addCard } from '../../Redux/actions/userAction';
import { getToken } from '../../Helper/Session';
import Logout from '../Common/Logout';

class Subscribe extends Component {
    constructor(props) {
        super(props)
        this.state = {
            errors: {},
            loading: false,
            cvc: '',
            expMonth: '',
            expYear: '',
            cardNumber: '',
            name: '',
        }
    }

    handleChange = (e) => {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value,
            errors: {
                ...this.state.errors,
                [e.target.name]: ''
            }
        })
    }

    componentDidMount() {
        inputPlaceHolders()
        this.loadStripe();
    }


    validate = () => {
        const { name, expMonth, expYear, cardNumber, cvc } = this.state;
        let errors = {};
        if (isEmpty(name)) errors.name = true;
        if (isEmpty(expMonth)) errors.expMonth = true;
        if (isEmpty(expYear)) errors.expYear = true;
        if (isEmpty(cardNumber)) errors.cardNumber = true;
        if (cvc.length < 3) errors.cvc = true;
        else if (cvc.length > 3) errors.cvc = 'Invalid cvc No';
        return errors;
    }

    loadStripe = () => {
        if (!window.document.getElementById('stripe-script')) {
            var s = window.document.createElement("script");
            s.id = "stripe-script";
            s.type = "text/javascript";
            s.src = "https://js.stripe.com/v2/";
            s.onload = () => {
                window.Stripe.setPublishableKey(process.env.REACT_APP_STRIPE_KEY)
            }
            window.document.body.appendChild(s);
        }
    }

    handlePayment = (e) => {
        e.preventDefault();
        const errors = this.validate();
        const { user: { user } } = this.props;
        let isSubscribe = user && user.subscriptionid !== '' ? true : false;
        if (Object.keys(errors).length !== 0) {
            this.setState({ errors });
            return false;
        }
        let token = getToken() !== null ? JSON.parse(getToken()) : '';
        window.Stripe.createToken({
            number: this.state.cardNumber,
            exp_month: this.state.expMonth,
            exp_year: this.state.expYear,
            cvc: this.state.cvc,
            name: this.state.name,
        }, (status, response) => {
            const params = {
                userid: token.id,
                cardnumber: this.state.cardNumber,
                cardholdername: this.state.name,
                expirydate: this.state.expMonth + `/` + this.state.expYear,
                cvc: this.state.cvc,
                source: response.id,
                apikey: process.env.REACT_APP_API_KEY
            }
            this.props.addCard(params, isSubscribe, this.props.history);
        });
    }

    render() {
        const { errors } = this.state;
        const { user: { user }, UI: { loading } } = this.props;
        let url = user && user.subscriptionid === '' ? 'become-member' : 'subscription-info'
        return (
            <section className="main-Section">
                <header className="header">
                    <div className="container-fluid">
                        <div className="header-section">
                            <Link to={url} className="header-section__back">
                                <img src="../images/back.svg" alt="Back" />
                            </Link>
                            <h2 className="page-title">Add New Card </h2>
                            {user && user.subscriptionid === '' ? <Logout /> : ''}
                        </div>
                    </div>
                </header>
                <div className="container-fluid midSection scrollSection payNow">
                    <div className="paynowCon">
                        {/* <div className="selectedCard">
                            <img src="../images/master-card.png" alt="Card" />
                            <label className="radio-label">
                                <input type="radio" name="paypal" />
                                <span className="checkbox-custom"></span>
                            </label>
                        </div> */}
                        <div className="paybox">
                            <form onSubmit={this.handlePayment}>
                                <div className="form-block formBox">
                                    <fieldset>
                                        <span className="placeholder">Card Number</span>
                                        <input type="text" className={`form-control input__field ${errors.cardNumber ? 'inputError' : ''}`} name="cardNumber" maxLength="16" onChange={this.handleChange} value={this.state.cardNumber} autocomplete="off" />
                                    </fieldset>
                                    <fieldset>
                                        <span className="placeholder">Cardholder Name</span>
                                        <input type="text" name="name" className={`form-control input__field ${errors.name ? 'inputError' : ''}`} onChange={this.handleChange} value={this.state.name} autocomplete="off" />
                                    </fieldset>

                                    <div className="row">
                                        <div className="col-md-3">
                                            <fieldset className="selectInput redAro">
                                                <span className="placeholder">Expiry Month</span>
                                                <select name="expMonth" className={`form-control input__field ${errors.expMonth ? 'inputError' : ''}`} onChange={this.handleChange}>
                                                    <option value="">MM</option>
                                                    {[...Array(12)].map((month, i) => {
                                                        const monthValue = i + 1;
                                                        return (
                                                            <>
                                                                <option value={monthValue}>{monthValue}</option>
                                                            </>
                                                        )
                                                    })}
                                                </select>
                                            </fieldset>
                                        </div>
                                        <div className="col-md-3">
                                            <fieldset className="selectInput redAro">
                                                <span className="placeholder">Expiry Year</span>
                                                <select name="expYear" className={`form-control input__field ${errors.expYear ? 'inputError' : ''}`} onChange={this.handleChange}>
                                                    <option value="">YYYY</option>
                                                    {[...Array(25)].map((year, i) => {
                                                        const yearValue = i + 2020;
                                                        return (
                                                            <>
                                                                <option value={yearValue}>{yearValue}</option>
                                                            </>
                                                        )
                                                    })}
                                                </select>
                                            </fieldset>
                                        </div>
                                        <div className="col-md-2">
                                            <fieldset>
                                                <span className="placeholder">CVC</span>
                                                <input type="password" name="cvc" className={`form-control input__field ${errors.cvc ? 'inputError' : ''}`} maxLength="3" onChange={this.handleChange} value={this.state.cvc} autocomplete="off" />
                                            </fieldset>
                                        </div>
                                    </div>
                                    <div className="savecredit">
                                        <label className="checkbox-label">
                                            <input type="checkbox" name="" />
                                            <span className="checkbox-custom"></span>
                                        </label> Save credit card information
                                    </div>
                                    {/* <div className="swichCheckbox">
                                        <label className="switch">
                                            <input type="checkbox" name="" checked="" />
                                            <span className="slider round"></span>
                                        </label>
                                    </div> */}
                                    <input type="submit" className="btn btn-primary" disabled={loading ? 'disabled' : ''} value={loading ? 'Loading...' : 'Pay Now'} />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
const mapStateToProps = (state) => ({
    user: state.user,
    UI: state.UI
})

const mapActionToProps = {
    addCard
}

export default connect(mapStateToProps, mapActionToProps)(withRouter(Subscribe));