import { DELETE_USER, DELETE_NOTIFICATION, SET_STATES, UNREAD_NOTIFICATIONS, SET_CARDS, SET_NOTIFICATIONS, SET_USER, SET_ERRORS, CLEAR_ERRORS, LOADING_UI, SET_UNAUTHENTICATED, LOADING_USER } from '../types'
import axios from '../../Helper/Axios';
import customAxios from 'axios';
import { toast } from 'react-toastify';
import $ from "jquery";
import { getToken } from '../../Helper/Session';
import { getOS } from '../../Helper/Util';
import { updateUserMedia } from './chatAction';

// SingUp user

export const signUp = (params, history) => (dispatch) => {
    dispatch({ type: LOADING_UI });
    axios.post('user/register.php', params)
        .then(res => {
            if (res.data.status === '1') {
                const userParam = {
                    apikey: process.env.REACT_APP_API_KEY,
                    userid: res.data.data.user.id
                }
                setAuthorizationHeader(JSON.stringify(res.data.data.user));
                const mediaParam = {
                    id: res.data.data.user.id,
                    name: res.data.data.user.name,
                    image: res.data.data.user.url
                }
                dispatch(updateUserMedia(mediaParam));
                dispatch(getUserData(userParam));
                toast.success(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
                history.push('user/our-mission')
            } else {
                toast.error(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
            dispatch({ type: CLEAR_ERRORS })
        }).catch(error => {
            dispatch({ type: SET_ERRORS })
            toast.error(error.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        });
}

//Login user

export const loginUser = (params, history) => (dispatch) => {
    dispatch({ type: LOADING_UI });
    axios.post('user/login.php', params)
        .then(res => {
            if (res.data.status === '1') {
                const type = res.data.data.user.admin;
                const userParam = {
                    apikey: process.env.REACT_APP_API_KEY,
                    userid: res.data.data.user.id
                }
                dispatch(getUserData(userParam));
                setAuthorizationHeader(JSON.stringify(res.data.data.user));
                const mediaParam = {
                    id: res.data.data.user.id,
                    name: res.data.data.user.name,
                    image: res.data.data.user.url
                }
                dispatch(updateUserMedia(mediaParam));
                toast.success(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
                checkUserType(type, history)
            } else {
                toast.error(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
            dispatch({ type: CLEAR_ERRORS });
        }).catch(error => {
            dispatch({ type: SET_ERRORS });
            toast.error(error.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        });
}

// Get user by Id

export const getUserData = (params, history) => (dispatch) => {
    dispatch({ type: LOADING_USER })
    axios.post('user/getuserbyid.php', params)
        .then(res => {
            if (res.data.status === '1') {
                dispatch({
                    type: SET_USER,
                    payload: res.data.data
                });
                setAuthorizationHeader(JSON.stringify(res.data.data.user));
                if (history !== undefined) history.push('dashboard');
            } else {
                dispatch({
                    type: SET_USER,
                    payload: {}
                });
            }
        }).catch(error => {
            if (error.response.status !== '1') {
                window.location.href = '/';
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                dispatch({ type: CLEAR_ERRORS });
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        });
}

// change password

export const changeUserPassword = (params) => (dispatch) => {
    dispatch({ type: LOADING_UI });
    axios.post('user/changepassword.php', params)
        .then(res => {
            if (res.data.status === '1') {
                toast.success(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                toast.error(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            dispatch({ type: CLEAR_ERRORS })
        }).catch(error => {
            if (error.response.status !== '1') {
                window.location.href = '/';
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                dispatch({ type: CLEAR_ERRORS });
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        });
}

// update Profile

export const updateProfile = (params, isTrue) => (dispatch) => {
    dispatch({ type: LOADING_UI });
    axios.post('user/updateuser.php', params)
        .then(res => {
            if (res.data.status === '1') {
                const userParam = {
                    apikey: process.env.REACT_APP_API_KEY,
                    userid: params.userid
                }
                dispatch(getUserData(userParam))
                if (isTrue) {
                    toast.success(res.data.message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    const mediaParam = {
                        id: params.userid,
                        name: params.name,
                        image: res.data.url
                    }
                    dispatch(updateUserMedia(mediaParam))
                }
            } else {
                toast.error(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            dispatch({ type: CLEAR_ERRORS })
        }).catch(error => {
            if (error.response.status !== '1') {
                window.location.href = '/';
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                dispatch({ type: CLEAR_ERRORS });
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        });
}


export const removeFCMToken = (params) => () => {
    axios.post('user/updateuser.php', params)
        .then(res => {
        }).catch(error => {
            console.log('Error', error)
        });
}

// Delete User Account

export const deleteUserAccount = (params, history) => (dispatch) => {
    dispatch({ type: LOADING_USER })
    axios.post('user/deleteuser.php', params)
        .then(res => {
            if (res.data.status === '1') {
                dispatch(logoutUser());
                history.push('/');
                toast.success(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                toast.error(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            dispatch({ type: CLEAR_ERRORS })
        }).catch(error => {
            if (error.response.status !== '1') {
                window.location.href = '/';
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                dispatch({ type: CLEAR_ERRORS });
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        });
}

// Delete User

export const deleteUser = (params, history) => (dispatch) => {
    dispatch({ type: LOADING_UI })
    axios.post('user/deleteuser.php', params)
        .then(res => {
            if (res.data.status === '1') {
                toast.success(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
                dispatch({
                    type: DELETE_USER,
                    payload: params.userid
                })
                history.push('users')
            } else {
                toast.error(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            dispatch({ type: CLEAR_ERRORS })
        }).catch(error => {
            if (error.response.status !== '1') {
                window.location.href = '/';
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                dispatch({ type: CLEAR_ERRORS });
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        });
}

// Add Card

export const addCard = (params, isSubscribe, history) => (dispatch) => {
    dispatch({ type: LOADING_UI })
    axios.post('user/addcard.php', params)
        .then(res => {
            if (res.data.status === '1') {
                toast.success(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
                if (isSubscribe === false) {
                    const subParams = {
                        apikey: process.env.REACT_APP_API_KEY,
                        userid: params.userid
                    }
                    dispatch(addSubscription(subParams, history));
                }
            } else {
                toast.error(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            dispatch({ type: CLEAR_ERRORS })
        }).catch(error => {
            if (error.response.status !== '1') {
                window.location.href = '/';
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                dispatch({ type: CLEAR_ERRORS });
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        });
}

// Card List
export const cardList = (params) => (dispatch) => {
    dispatch({ type: LOADING_UI })
    axios.post('user/loadcards.php', params)
        .then(res => {
            if (res.data.status === '1') {
                dispatch({
                    type: SET_CARDS,
                    payload: res.data.data
                });
            } else {
                dispatch({
                    type: SET_CARDS,
                    payload: []
                });
            }
            dispatch({ type: CLEAR_ERRORS })
        }).catch(error => {
            if (error.response.status !== '1') {
                window.location.href = '/';
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                dispatch({ type: CLEAR_ERRORS });
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        });
}

// Delete Card

export const deleteCard = (params) => (dispatch) => {
    dispatch({ type: LOADING_UI })
    axios.post('user/deletecard.php', params)
        .then(res => {
            if (res.data.status === '1') {
                toast.success('Card removed!', {
                    position: toast.POSITION.TOP_RIGHT
                });
                const cardParams = {
                    apikey: process.env.REACT_APP_API_KEY,
                    userid: params.userid,
                }
                dispatch(cardList(cardParams))
            } else {
                toast.error(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            dispatch({ type: CLEAR_ERRORS })
        }).catch(error => {
            if (error.response.status !== '1') {
                window.location.href = '/';
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                dispatch({ type: CLEAR_ERRORS });
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        });
}

// User Subscription

export const addSubscription = (params, history) => (dispatch) => {
    dispatch({ type: LOADING_UI })
    axios.post('user/subscribeuser.php', params)
        .then(res => {
            if (res.data.status === '1') {
                const userParam = {
                    apikey: process.env.REACT_APP_API_KEY,
                    userid: params.userid
                }
                dispatch(getUserData(userParam, history));
            } else {
                toast.error(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            dispatch({ type: CLEAR_ERRORS })
        }).catch(error => {
            if (error.response.status !== '1') {
                window.location.href = '/';
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                dispatch({ type: CLEAR_ERRORS });
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        });
}

// Cancel subscription

export const cancelSubscription = (params) => (dispatch) => {
    dispatch({ type: LOADING_UI })
    axios.post('user/cancelsubscription.php', params)
        .then(res => {
            if (res.data.status === '1') {
                const userParam = {
                    apikey: process.env.REACT_APP_API_KEY,
                    userid: params.userid
                }
                dispatch(getUserData(userParam))
                toast.success(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                toast.error(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            dispatch({ type: CLEAR_ERRORS })
        }).catch(error => {
            if (error.response.status !== '1') {
                window.location.href = '/';
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                dispatch({ type: CLEAR_ERRORS });
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        });
}

// Notifications List

export const notificationList = (params) => (dispatch) => {
    dispatch({ type: LOADING_UI })
    axios.post('user/notifications.php', params)
        .then(res => {
            if (res.data.status === '1') {
                dispatch({
                    type: SET_NOTIFICATIONS,
                    payload: res.data.data
                });
            } else {
                dispatch({
                    type: SET_NOTIFICATIONS,
                    payload: []
                });
            }
            dispatch({ type: CLEAR_ERRORS })
        }).catch(error => {
            if (error.response.status !== '1') {
                window.location.href = '/';
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                dispatch({ type: CLEAR_ERRORS });
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        });
}

// Unread Notifications

export const unreadNotification = (params) => (dispatch) => {
    axios.post('user/getunreadnotifications.php', params)
        .then(res => {
            if (res.data.status === '1') {
                dispatch({
                    type: UNREAD_NOTIFICATIONS,
                    payload: res.data.data.data.unread
                });
            } else {
                dispatch({
                    type: UNREAD_NOTIFICATIONS,
                    payload: 0
                });
            }
            dispatch({ type: CLEAR_ERRORS })
        }).catch(error => {
            if (error.response.status !== '1') {
                window.location.href = '/';
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                dispatch({ type: CLEAR_ERRORS });
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        });
}


// Read Notifications

export const readNotification = (params) => (dispatch) => {
    axios.post('user/setnotificationsread.php', params)
        .then(res => {
            if (res.data.status === '1') {
                // dispatch(notificationList(params))
            }
            dispatch({ type: CLEAR_ERRORS })
        }).catch(error => {
            if (error.response.status !== '1') {
                window.location.href = '/';
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                dispatch({ type: CLEAR_ERRORS });
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        });
}

// State List

export const stateList = (params) => (dispatch) => {
    // dispatch({ type: LOADING_UI })
    axios.post('user/stateslist.php', params)
        .then(res => {
            if (res.data.status === '1') {
                dispatch({
                    type: SET_STATES,
                    payload: res.data.data
                });
            } else {
                toast.error(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            dispatch({ type: CLEAR_ERRORS })
        }).catch(error => {
            if (error.response.status !== '1') {
                window.location.href = '/';
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                dispatch({ type: CLEAR_ERRORS });
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        });
}

export const setAuthorizationHeader = (data) => {
    localStorage.setItem('GY6_token', data);
}

export const checkUserType = (type, history) => {
    if (type === '1') {
        history.push('/user/dashboard');
    }
    else {
        history.push('/user/dashboard');
    }
}

export const logoutUser = () => (dispatch) => {
    let token = getToken() !== null ? JSON.parse(getToken()) : '';
    const params = {
        userid: token.id,
        token: ''
    }
    dispatch(removeFCMToken(params))
    localStorage.removeItem('GY6_token');
    localStorage.removeItem('fbToken');
    localStorage.removeItem('event');
    localStorage.removeItem('legal');
    localStorage.removeItem('grp');
    localStorage.removeItem('log_token');
    localStorage.removeItem('unreadCount');
    localStorage.removeItem('chatUserMedia');
    localStorage.removeItem('userMedia');
    localStorage.removeItem('chatDetail');
    localStorage.removeItem('chatUsers');
    dispatch({ type: SET_UNAUTHENTICATED })
}

// Delete Notification

export const deleteNotification = (params) => (dispatch) => {
    axios.post('user/deletenotification.php', params)
        .then(res => {
            if (res.data.status === '1') {
                dispatch({
                    type: DELETE_NOTIFICATION,
                    payload: params.notificationid,
                });
                toast.success(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                toast.error(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            dispatch({ type: CLEAR_ERRORS })
        }).catch(error => {
            if (error.response.status !== '1') {
                window.location.href = '/';
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                dispatch({ type: CLEAR_ERRORS });
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        });
}


// Add Notification

export const addNotification = (params, latLng) => (dispatch) => {
    dispatch({ type: LOADING_UI })
    axios.post('user/addnotification.php', params)
        .then(res => {
            if (res.data.status === '1') {
                toast.success('Notification Sent', {
                    position: toast.POSITION.TOP_RIGHT
                });
                $('#goingModal').modal('hide');
                if (getOS() === 'iOS') {
                    window.location = `${`http://maps.apple.com/?daddr=${latLng.lat + ',' + latLng.lng}`}`;
                } else {
                    // window.location = `https://waze.com/ul?ll=${latLng.lat},${latLng.lng}&navigate=yes`;
                    window.location = `https://www.google.com/maps/dir//${latLng.lat + ',' + latLng.lng}`;
                }
            } else {
                toast.error(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            dispatch({ type: CLEAR_ERRORS })
        }).catch(error => {
            if (error.response.status !== '1') {
                window.location.href = '/';
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                dispatch({ type: CLEAR_ERRORS });
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        });
}

// send notification
export const sendNotification = (params) => (dispatch) => {
    axios.post('user/addnotification.php', params)
        .then(res => {
            dispatch({ type: CLEAR_ERRORS })
        }).catch(error => {
            console.log('Err', error)
        });
}

// Send Chat Email
export const chatAlert = (params) => () => {
    customAxios.post('https://gotyour6app.online/forget/public/api/v1/send-message', params)
        .then(res => {
        }).catch(error => {
            toast.error(error.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        });
}
// Sms Email Enable Disable

export const smsEmailEnableDisable = (params) => (dispatch) => {
    dispatch({ type: LOADING_UI })
    axios.post('user/smsEmailEnableDisable.php', params)
        .then(res => {
            if (res.data.status === '1') {
                const userParam = {
                    apikey: process.env.REACT_APP_API_KEY,
                    userid: params.userid
                }
                dispatch(getUserData(userParam))
                toast.success(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                toast.error(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            dispatch({ type: CLEAR_ERRORS })
        }).catch(error => {
            if (error.response.status !== '1') {
                window.location.href = '/';
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                dispatch({ type: CLEAR_ERRORS });
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        });
}

// Check user login token

export const checkUserMultipleLogin = (params) => (dispatch) => {
    axios.post('user/checkUserMultipleLogin.php', params)
        .then(res => {
            if (res.data.status !== '1') {
                dispatch(logoutUser());
                window.location.href = '/';
            }
            dispatch({ type: CLEAR_ERRORS })
        }).catch(error => {
            console.log("Error", error)
        });
}
