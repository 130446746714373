import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { allPatriotsUserList } from '../../Redux/actions/homeAction';
import { getToken } from '../../Helper/Session';
import { toast } from 'react-toastify';
import InfiniteScroll from "react-infinite-scroll-component";
import { storage } from "../Services/firebase";
import $ from 'jquery';
import { v4 as uuidv4 } from 'uuid';
import { isEmpty } from "../../Helper/Util";
import { Loader } from '../Common/Loader';
import { addNewChat, getMyChats, getChatDetail } from '../../Redux/actions/chatAction';

class EditChat extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search: '',
            chatId: '',
            errors: {},
            page: 1,
            users: [],
            title: '',
            grpImg: '',
            url: null,
            isEdit: true
        }
    }

    componentDidMount() {
        let token = getToken() !== null ? JSON.parse(getToken()) : '';
        let users = this.props.location.state !== undefined ? this.props.location.state : [];
        this.setState({
            chatId: this.props.match.params.id,
            users: users
        });
        const params = {
            apikey: process.env.REACT_APP_API_KEY,
            search: this.state.search ? this.state.search : '',
            page: this.state.page
        }
        this.props.allPatriotsUserList(params);
        this.props.getMyChats(token.id);
        this.props.getChatDetail(this.props.match.params.id)
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { chat: { chatDetail } } = nextProps
        this.setState({
            title: chatDetail ? chatDetail.title : '',
            grpImg: chatDetail ? chatDetail.url : '',
        })
    }

    fetchMoreData = () => {
        let newPage = this.state.page;
        newPage++;
        if (this.props.home.pgCurrentPage !== this.state.page) {
            newPage = this.props.home.pgCurrentPage + 1
        }
        this.setState({ page: newPage });
        const params = {
            apikey: process.env.REACT_APP_API_KEY,
            page: newPage,
            search: this.state.search ? this.state.search : ''
        }
        this.props.allPatriotsUserList(params)
    };

    searchKeyword = (e) => {
        this.setState({
            search: e.target.value,
            page: 1
        });
        const params = {
            apikey: process.env.REACT_APP_API_KEY,
            page: e.target.value !== '' ? this.state.page : 1,
            search: e.target.value
        }
        this.props.allPatriotsUserList(params)
    }

    handleChange = e => {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value,
            errors: {
                ...this.state.errors,
                [e.target.name]: ''
            }
        })
    }

    handleImgChange = (e) => {
        this.setState({
            url: e.target.files[0],
            errors: {
                url: ''
            }
        });
    };

    toggleCheckboxChange = (e, s) => {
        // let users = this.state.users
        // if (e.target.checked) {
        //     users.push(s.id)
        // } else {
        //     const index = this.state.users.indexOf(s.id);
        //     if (index > -1) {
        //         this.state.users.splice(index, 1);
        //     }
        // }
        // this.setState({
        //     users
        // })
        let array = [...this.state.users];
        let index = array.indexOf(s.id);
        if (e.target.checked) {
            if (index !== -1) {
                array.splice(index, 1);
                this.setState({ users: array });
            }
        } else {
            this.setState(prevState => ({
                users: [...prevState.users, s.id]
            }))
        }
    }

    validate = () => {
        const { users } = this.state;
        let errors = {};
        if (users.length === 1) errors.users = toast.error('Select at least One User', {
            position: toast.POSITION.TOP_RIGHT
        });
        return errors;
    }

    validateGrp = () => {
        const { title } = this.state;
        let errors = {};
        if (isEmpty(title)) errors.title = true;
        return errors;
    }

    handleSubmit = (e) => {
        e.preventDefault()
        const errors = this.validate();
        if (Object.keys(errors).length !== 0) {
            this.setState({ errors });
            return false;
        }
        if (this.state.grpImg || this.state.users.length > 2) {
            $('#grpChat').modal('show');
            return false;
        }
        let token = getToken() !== null ? JSON.parse(getToken()) : '';
        let users = this.state.users;
        let isEdit = this.state.isEdit;
        let userObj = {};
        let unreadObj = {};
        for (let i = 0; i < users.length; i++) {
            userObj[users[i]] = users[i];
            unreadObj['unread' + users[i]] = 0;
        }
        const { chat: { myChats } } = this.props;
        let myRef = this.state.chatId;
        const params = {
            authUser: token.id,
            id: myRef,
            title: '',
            url: '',
            unreadObj: 0,
            users: userObj
        }
        if (myChats.length > 0) {
            for (let i = 0; i < myChats.length; i++) {
                if (JSON.stringify(myChats[i].users) === JSON.stringify(userObj) && myChats[i].url === '') {
                    this.props.history.push(`chat-${myChats[i].id}`);
                    localStorage.setItem('chatDetail', JSON.stringify(myChats[i]));
                    return false
                }
            }
            this.props.addNewChat(params, users, isEdit);
            this.props.history.push(`chat-${myRef}`);
        } else {
            this.props.addNewChat(params, users, isEdit);
            this.props.history.push(`chat-${myRef}`);
        }
    }

    handleGroupSubmit = (e) => {
        e.preventDefault()
        const errors = this.validateGrp();
        if (Object.keys(errors).length !== 0) {
            this.setState({ errors });
            return false;
        }
        let token = getToken() !== null ? JSON.parse(getToken()) : '';
        let users = this.state.users;
        let isEdit = this.state.isEdit;
        let userObj = {};
        let unreadObj = {};
        let chatImg = this.state.url;
        let title = this.state.title;
        let storageRef = storage.ref();
        let imageUrl = '';
        if (chatImg !== null) {
            let encodedImg = chatImg.name.split('.')[0] + '_' + Date.now() + '.' + chatImg.name.split('.')[1];
            let imageRef = storageRef.child("ChatImages/" + encodedImg);
            imageRef.put(chatImg)
                .then((res) => {
                });
            imageUrl = "https://firebasestorage.googleapis.com/v0/b/got-your-6.appspot.com/o/ChatImages" + encodeURIComponent('/' + encodedImg) + "?alt=media&token=" + uuidv4();
        } else {
            imageUrl = this.state.grpImg;
        }
        for (let i = 0; i < users.length; i++) {
            userObj[users[i]] = users[i];
            unreadObj['unread' + users[i]] = 0;
        }
        const { chat: { myChats } } = this.props;
        let myRef = this.state.chatId;
        const params = {
            authUser: token.id,
            id: myRef,
            title: title,
            url: imageUrl,
            unreadObj: 0,
            users: userObj
        }
        if (myChats.length > 0) {
            for (let i = 0; i < myChats.length; i++) {
                if (JSON.stringify(myChats[i].users) === JSON.stringify(userObj)) {
                    if (myChats[i].title !== title || myChats[i].url !== imageUrl) {
                        this.props.addNewChat(params, users, isEdit);
                        this.props.history.push(`chat-${myChats[i].id}`)
                    } else {
                        localStorage.setItem('chatDetail', JSON.stringify(myChats[i]));
                        this.props.history.push(`chat-${myChats[i].id}`)
                    }
                    $('#grpChat').modal('hide');
                    return false
                }
            }
            this.props.addNewChat(params, users, isEdit);
            this.props.history.push(`chat-${myRef}`)
        } else {
            this.props.addNewChat(params, users, isEdit);
            this.props.history.push(`chat-${myRef}`)
        }
        $('#grpChat').modal('hide');
    }

    render() {
        let token = getToken() !== null ? JSON.parse(getToken()) : '';
        const { home: { patriotUsers, patriotUsersHasMore, pgCurrentPage } } = this.props;
        const { errors, users } = this.state;
        let markup = patriotUsers.length > 0 ? patriotUsers.map((result, i) => {
            if (result.id !== token.id) {
                return (
                    <li key={i}>
                        <div className="userProfile">
                            <figure>
                                <img src={result.url !== '' ? process.env.REACT_APP_API_URL + result.url : '../images/logo.png'} alt="User" />
                            </figure>
                            <figcaption>
                                <h2>{result.name} @<span>{result.username}</span></h2>
                                <p><i className="location"></i>{result.city}, {result.state}</p>
                            </figcaption>
                        </div>
                        <div className={users.length > 0 && users.includes(result.id) === true ? 'userAddIcon added' : 'userAddIcon'}>
                            <input
                                type="checkbox"
                                className='form-control'
                                id={result.id}
                                value={result.id}
                                checked={users.find((u) => u.id === result.id)}
                                onChange={(e) => this.toggleCheckboxChange(e, result)}
                            />
                        </div>
                    </li >
                )
            }
        }) : (pgCurrentPage === 1 ? <p className="no-data">No Data</p> : '');
        return (
            <section className="main-Section">
                <header className="header">
                    <div className="container-fluid">
                        <div className="header-section">
                            <Link to="chat" className="header-section__back">
                                <img src="../images/back.svg" alt="Back" />
                            </Link>
                            <h2 className="page-title">Users </h2>
                            <button type="button" className="saveBtn" onClick={(e) => this.handleSubmit(e)}>Next</button>
                        </div>
                    </div>
                </header>
                <div className="container-fluid midSection" style={{ height: "auto" }}>
                    <div className="content">
                        <div className="search-box">
                            <input type="text" placeholder="Search" value={this.state.search} name="search" onChange={this.searchKeyword} className="inp" />
                        </div>
                    </div>
                    <div className="content">
                        <div className="usersList">
                            <InfiniteScroll
                                dataLength={patriotUsers.length}
                                next={this.fetchMoreData}
                                hasMore={patriotUsersHasMore}
                                loader={patriotUsers.length >= 10 ? <Loader /> : ''}
                            >
                                <ul>
                                    {markup}
                                    {pgCurrentPage === 0 ? <Loader /> : ''}
                                </ul>
                            </InfiniteScroll>
                        </div>
                    </div>
                </div>
                <div className="modal custom-modal initiateFreedom fade" id="grpChat" aria-labelledby="exampleModalLabel" aria-modal="true" role="dialog">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-body p-0 pt-4">
                                <div className="logo"><img src="../images/logo.png" alt="Logo" /></div>
                                <form onSubmit={this.handleGroupSubmit}>
                                    <div className="grpchat-text-block">
                                        <fieldset className="mb-10">
                                            <input type="text" placeholder="Title" name="title" className={`form-control ${errors.title ? 'inputError' : ''}`} value={this.state.title} onChange={this.handleChange} />
                                        </fieldset>
                                        <input type="file" name="url" className={`form-control input__field`} onChange={this.handleImgChange} />
                                        {/* <div className="profileImage">
                                            <figure>
                                                <img src={this.state.grpImg ? this.state.grpImg : this.state.url} alt="Fig" />
                                            </figure>
                                            <label className="addProfile">
                                                <img src="../images/add.png" alt="User" />
                                                <input type="file" name="image" onChange={this.handleImgChange} />
                                            </label>
                                        </div> */}
                                    </div>
                                    <div className="yesNoBtns">
                                        <button type="button" className="btn saveBtn" onClick={(e) => this.handleGroupSubmit(e)}>Create</button>
                                        <button className="btn" data-dismiss="modal">Cancel</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

const mapStateToProps = (state) => ({
    home: state.home,
    chat: state.chat,
    UI: state.UI
})

const mapActionToProps = {
    allPatriotsUserList,
    addNewChat,
    getMyChats,
    getChatDetail
}

export default connect(mapStateToProps, mapActionToProps)(withRouter(EditChat));