import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom';
import { inputPlaceHolders, isEmail, isEmpty } from "../../Helper/Util";
import axios from "../../Helper/Axios";
import { connect } from 'react-redux';
import { signUp } from '../../Redux/actions/userAction';

class Signup extends Component {
    constructor(props) {
        super(props)
        this.state = {
            apikey: 'gotyour6_AC4I_BD',
            name: '',
            userName: '',
            r_email: '',
            r_password: '',
            confirm_password: '',
            phone: '',
            emailExist: '',
            phoneExist: '',
            userNameExist: '',
            profileImg: null,
            errors: {},
        }
    }

    componentDidMount() {
        inputPlaceHolders()
    }

    handleChange = e => {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value,
            errors: {
                ...this.state.errors,
                [e.target.name]: ''
            }
        })
    };

    validate = () => {
        const { email, password } = this.state;
        let errors = {};
        if (isEmpty(email)) errors.email = true;
        else if (!isEmail(email)) errors.email = true;
        if (isEmpty(password)) errors.password = true;
        return errors;
    }

    // Check for Email 

    isEmailExist = (event) => {
        const params = {
            email: event.target.value,
            apikey: this.state.apikey
        }
        axios.post(`user/checkemail.php`, params)
            .then(res => {
                if (res.data.status === '1') {
                    this.setState({ emailExist: 'This email has been already exist!' })
                } else {
                    this.setState({ emailExist: '' })
                }
            }).catch(error => {
                this.setState({ emailExist: '' })
            });
    }

    // Check for Phone Number

    isPhoneExist = (event) => {
        const params = {
            phone: event.target.value,
            apikey: this.state.apikey
        }
        axios.post(`user/checkphone.php`, params)
            .then(res => {
                if (res.data.status === '1') {
                    this.setState({ phoneExist: 'This phone no has been already exist!' })
                } else {
                    this.setState({ phoneExist: '' })
                }
            }).catch(error => {
                this.setState({ phoneExist: '' })
            });
    }

    // Check for userName

    isUserNameExist = (event) => {
        const params = {
            username: event.target.value,
            apikey: this.state.apikey
        }
        axios.post(`user/checkusername.php`, params)
            .then(res => {
                if (res.data.status === '1') {
                    this.setState({ userNameExist: '' })
                } else {
                    this.setState({ userNameExist: res.data.message })
                }
            }).catch(error => {
                this.setState({ userNameExist: '' })
            });
    }

    _profileImgFile = (e) => {
        var file = e.target.files[0];
        let reader = new FileReader();
        reader.onload = (e) => {
            let images = e.target.result;
            this.setState({
                profileImg: images.split(';base64')[1],
                errors: {
                    profileImg: ''
                }
            });
        };
        reader.readAsDataURL(file);
    };


    signUpValidate = () => {
        const { name, userName, phone, r_email, r_password, confirm_password, profileImg } = this.state;
        let errors = {};
        if (isEmpty(name)) errors.name = true;
        if (isEmpty(userName)) errors.userName = true;
        if (isEmpty(phone)) errors.phone = true;
        if (isEmpty(r_email)) errors.r_email = true;
        else if (!isEmail(r_email)) errors.r_email = true;
        if (isEmpty(r_password)) errors.r_password = true;
        if (isEmpty(confirm_password)) errors.confirm_password = true;
        if (profileImg === null) errors.profileImg = true;
        return errors;
    }

    // Signup

    handleSignUp = event => {
        event.preventDefault()
        const errors = this.signUpValidate();
        if (Object.keys(errors).length !== 0) {
            this.setState({ errors });
            return false;
        }
        const params = {
            image: this.state.profileImg,
            name: this.state.name,
            username: this.state.userName,
            email: this.state.r_email,
            password: this.state.r_password,
            phone: this.state.phone,
            apikey: this.state.apikey
        }
        this.props.signUp(params, this.props.history);
    }

    render() {
        const { errors } = this.state;
        const { UI: { loading } } = this.props;
        return (
            <>
                <section className="main-Section">
                    <div className="login-tabs">
                        <ul className="nav nav-tabs">
                            <li>
                                <button className="navlink" id="profile-tab" data-toggle="tab" data-target="#signup" type="button">Signup</button>
                            </li>
                        </ul>
                    </div>
                    <div className="container-fluid midSection scrollSection">
                        <div className="content login">
                            <form onSubmit={this.handleSignUp}>
                                <div className="profile-block">
                                    <div className="profileImage">
                                        <figure>
                                            <img src="./images/profile-pic.png" alt="Fig" />
                                        </figure>
                                        <button href="javascript:void(0)" className="addProfile">
                                            <img src="./images/add.png" alt="" />
                                            <input type="file" name="profileImg" id="file1" accept="image/jpeg,image/png" onChange={this._profileImgFile} />
                                        </button>
                                        <span className={`form-control input__field ${errors.profileImg ? 'inputError' : ''}`}>Choose Image</span>
                                    </div>
                                    <span className="upload-title">Upload<br /> Profile Picture</span>
                                </div>
                                <div className="form-block formBox">
                                    <fieldset>
                                        <span className="placeholder">Name</span>
                                        <input type="text" name="name" className={`form-control input__field ${errors.name ? 'inputError' : ''}`} value={this.state.name} onChange={this.handleChange} autocomplete="off" />
                                    </fieldset>
                                    <fieldset>
                                        <span className="placeholder">User Name</span>
                                        <input
                                            type="text"
                                            className={`form-control input__field ${errors.userName ? 'inputError' : ''}`}
                                            value={this.state.userName}
                                            name="userName"
                                            onChange={this.handleChange}
                                            onKeyUp={this.isUserNameExist}
                                            autocomplete="off"
                                        />
                                        <span className="error">{this.state.userNameExist}</span>
                                    </fieldset>
                                    <fieldset>
                                        <span className="placeholder">Mobile Number</span>
                                        <input
                                            type="number"
                                            className={`form-control input__field ${errors.phone ? 'inputError' : ''}`}
                                            value={this.state.phone}
                                            name="phone"
                                            onChange={this.handleChange}
                                            onKeyUp={this.isPhoneExist}
                                            autocomplete="off"
                                        />
                                        <span className="error">{this.state.phoneExist}</span>
                                    </fieldset>
                                    <fieldset>
                                        <span className="placeholder">Email ID</span>
                                        <input
                                            type="text"
                                            className={`form-control input__field ${errors.r_email ? 'inputError' : ''}`}
                                            value={this.state.r_email}
                                            name="r_email"
                                            onChange={this.handleChange}
                                            onKeyUp={this.isEmailExist}
                                            autocomplete="off"
                                        />
                                        <span className="error">{this.state.emailExist}</span>
                                    </fieldset>
                                    <fieldset>
                                        <span className="placeholder">Password</span>
                                        <input type="password" name="r_password" value={this.state.r_password} onChange={this.handleChange} className={`form-control input__field ${errors.r_password ? 'inputError' : ''}`} autocomplete="off" />
                                    </fieldset>
                                    <fieldset>
                                        <span className="placeholder">Confirm Password</span>
                                        <input type="password" name="confirm_password" value={this.state.confirm_password} onChange={this.handleChange} className={`form-control input__field ${errors.confirm_password ? 'inputError' : ''}`} autocomplete="off" />
                                    </fieldset>
                                </div>
                                <input type="submit" className="btn btn-primary" disabled={loading ? 'disabled' : ''} value={loading ? 'Loading...' : 'Register'} />
                                <div className="dont-have-account">Already have account? <Link to="login">Login</Link></div>
                            </form>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.user,
    UI: state.UI
})

const mapActionsToProps = {
    signUp
}

export default connect(mapStateToProps, mapActionsToProps)(withRouter(Signup));