import React from 'react';
import { Link } from 'react-router-dom';

const Error = () => {
    return (
        <>
            <div className="error-page">
                <div className="error-card">
                    <figure className="mb-10">
                        <img src="../images/sad-emoji.png" alt="sad" height="70" />
                    </figure>
                    <h2 className="error-card__title">Oops, page not found</h2>
                    <p>We are very sorry for the inconvenience. It looks like you're trying to access a page that has been deleted or never even existed.</p>
                    <Link to="/" className="btn btn-primary btn-sm elipse">Back to Home</Link>
                </div>
            </div>
        </>
    );
};

export default Error;
