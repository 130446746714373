import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getUserDetail, flagUser } from '../../Redux/actions/homeAction';
import { getToken } from '../../Helper/Session';
import { db } from "../Services/firebase";
import { titleCase } from '../../Helper/Util';
import Header from '../Layout/Header';
import { Loader } from '../Common/Loader';
import { addNewChat, getMyChats } from '../../Redux/actions/chatAction';

class MemberDetail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            groupId: ''
        }
    }

    componentDidMount() {
        let token = getToken() !== null ? JSON.parse(getToken()) : '';
        this.setState({
            groupId: this.props.match.params.groupId
        })
        const params = {
            apikey: process.env.REACT_APP_API_KEY,
            userid: this.props.match.params.userId
        }
        this.props.getUserDetail(params, this.props.history);
        this.props.getMyChats(token.id);
    }

    handleFlag = (e, userId) => {
        e.preventDefault();
        let token = getToken() !== null ? JSON.parse(getToken()) : '';
        const params = {
            user_id: userId,
            fromid: token.id,
            apikey: process.env.REACT_APP_API_KEY
        }
        this.props.flagUser(params);
    }

    handleChat = (e, toUser) => {
        e.preventDefault()
        let token = getToken() !== null ? JSON.parse(getToken()) : '';
        let users = [toUser, token];
        let userObj = {};
        let unreadObj = {};
        for (let i = 0; i < users.length; i++) {
            userObj[users[i].id] = users[i].id;
            unreadObj['unread' + users[i].id] = 0;
        }
        const { chat: { myChats } } = this.props;
        let myRef = db.ref().push();
        const params = {
            authUser: token.id,
            id: myRef.key,
            title: '',
            url: '',
            unreadObj: 0,
            users: userObj
        }
        if (myChats.length > 0) {
            for (let i = 0; i < myChats.length; i++) {
                if (JSON.stringify(myChats[i].users) === JSON.stringify(userObj) && myChats[i].url === '') {
                    this.props.history.push(`chat-${myChats[i].id}`);
                    localStorage.setItem('chatDetail', JSON.stringify(myChats[i]));
                    return false
                }
            }
            this.props.addNewChat(params, users);
            this.props.history.push(`chat-${myRef.key}`);
        } else {
            this.props.addNewChat(params, users);
            this.props.history.push(`chat-${myRef.key}`);
        }
    }

    render() {
        const { groupId } = this.state;
        const { home: { userDetail }, UI: { loading } } = this.props;
        let token = getToken() !== null ? JSON.parse(getToken()) : '';
        return (
            <section className="main-Section">
                <Header
                    title={'Profile'}
                    backUrl={`members-${groupId}`}
                />
                <div className="container-fluid midSection scrollSection userListCon">
                    <div className="content bg-gray">
                        <div className="memberDetailPage">
                            {!loading ?
                                <div className="memberDetailPage__inside">
                                    <div className="memberDetailPage__inside__info">
                                        {token.id === userDetail.id ?
                                            <Link to="update-profile" className={token.id === userDetail.id ? "flatUser edit" : "flatUser"}></Link>
                                            :
                                            <button className="flatUser" onClick={(e) => this.handleFlag(e, userDetail !== undefined ? userDetail.id : 0)}></button>
                                        }
                                        <figure className="memberDetailPage__inside__info__logo">
                                            <img src={userDetail !== undefined && userDetail.url !== '' ? process.env.REACT_APP_API_URL + userDetail.url : '../images/logo.png'} alt={userDetail.name} />
                                        </figure>
                                        <h5>{userDetail !== undefined ? userDetail.name : ''}</h5>
                                        <p>@{userDetail !== undefined ? titleCase(userDetail.username) : ''}</p>
                                        <p><i className="fas fa-map-marker-alt"></i> {userDetail.address + userDetail.city}, {userDetail.state}</p>
                                        {token.id !== userDetail.id ?
                                            <div className="memberDetailPage__inside__info__chat" onClick={(e) => this.handleChat(e, userDetail)}>
                                                <span>- -</span> <button className="chatBtn"></button> <span>- -</span>
                                            </div> : ''
                                        }
                                    </div>
                                </div>
                                : <Loader />
                            }
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

const mapStateToProps = (state) => ({
    home: state.home,
    chat: state.chat,
    UI: state.UI
})

const mapActionToProps = {
    getUserDetail,
    flagUser,
    addNewChat,
    getMyChats
}
export default connect(mapStateToProps, mapActionToProps)(withRouter(MemberDetail));