import { toast } from 'react-toastify';
import { db } from "../../Component/Services/firebase";
import { MY_CHATS, CHAT_DETAIL, LOADING_UI, CLEAR_ERRORS, DELETE_DETAIL } from '../types'
import { getToken } from '../../Helper/Session';
import { currentTime } from "../../Helper/Util";
import { sendNotification } from './userAction';

// Chat Details
export const getChatDetail = (chatId, history) => (dispatch) => {
    dispatch({ type: LOADING_UI })
    try {
        db.ref("Chats").child(chatId).on("value", snap => {
            if (snap.val() !== null) {
                dispatch({
                    type: CHAT_DETAIL,
                    payload: snap.val(),
                    userData: {}
                })
            } else {
                history.push('chat');
                // toast.error('Chat doesn`t exist!', {
                //     position: toast.POSITION.TOP_RIGHT
                // });
            }
        });
        dispatch({ type: CLEAR_ERRORS })
    } catch (error) {
        dispatch({ type: CLEAR_ERRORS })
        toast.error('Something went wrong!', {
            position: toast.POSITION.TOP_RIGHT
        });
    }
}

// My Chat Lists
export const getMyChats = (authUser) => (dispatch) => {
    dispatch({ type: LOADING_UI })
    try {
        let updatedData = [];
        db.ref("MyChats").child(authUser).on("value", (snap) => {
            if (snap.val() !== null) {
                snap.forEach(function (data) {
                    let userIds = data.val().users !== undefined ? Object.keys(data.val().users) : [];
                    userIds.forEach(function (uId) {
                        if (uId !== authUser) {
                            db.ref("Users").child(uId).on('value', function (mediaSnap) {
                                var obj = {};
                                if (mediaSnap.val() !== null) {
                                    obj = { 'id': mediaSnap.val().id, 'name': mediaSnap.val().name, 'img': mediaSnap.val().img };
                                    updatedData.push(obj)
                                    dispatch({
                                        type: MY_CHATS,
                                        payload: Object.values(snap.val()),
                                        userData: updatedData
                                    })
                                }
                            });
                        }
                    })
                });
                dispatch({
                    type: MY_CHATS,
                    payload: Object.values(snap.val()),
                    userData: updatedData
                })
            }
        });
        dispatch({ type: CLEAR_ERRORS });
    } catch (error) {
        dispatch({ type: CLEAR_ERRORS });
        toast.error('Something went wrong!', {
            position: toast.POSITION.TOP_RIGHT
        });
    }
}

// Chat Messages
export const getMyChatMessages = (chatId, authUser) => (dispatch) => {
    // try {
    //     let userMedia = [];
    //     let userIds = [];
    //     db.ref("Messages").child(chatId).once("value", snap => {
    //         if (snap.val() !== null) {
    //             snap.forEach(function (data) {
    //                 if (data.val().from !== authUser.id) {
    //                     if (userIds.indexOf(data.val().from) == -1) {
    //                         userIds.push(data.val().from);
    //                         return true;
    //                     }
    //                     return false;
    //                 }
    //             });
    //         }
    //         for (let i = 0; i < userIds.length; i++) {
    //             db.ref("Users").child(userIds[0]).once('value', function (mediaSnap) {
    //                 var obj = {};
    //                 obj = { 'id': mediaSnap.val().id, 'name': mediaSnap.val().name, 'img': mediaSnap.val().img };
    //                 if (userMedia.length > 0) {
    //                     for (let i = 0; i < userMedia.length; i++) {
    //                         if (userMedia[i].id !== mediaSnap.val().id) {
    //                             userMedia.push(obj);
    //                         }
    //                     }
    //                 } else {
    //                     userMedia.push(obj);
    //                 }
    //             });
    //         }
    //         dispatch({
    //             type: MY_CHAT_MSGS,
    //             payload: Object.values(snap.val()),
    //             userData: userMedia
    //         });
    //     });
    //     dispatch({ type: CLEAR_ERRORS })
    // } catch (error) {
    //     dispatch({ type: CLEAR_ERRORS })
    //     toast.error('Something went wrong!', {
    //         position: toast.POSITION.TOP_RIGHT
    //     });
    // }
}

// Delete Chats
export const deleteChat = (chatId, type, history) => (dispatch) => {
    dispatch({ type: LOADING_UI })
    try {
        let token = getToken() !== null ? JSON.parse(getToken()) : '';
        let chatKeyExist = db.ref('Chats').child(chatId);
        chatKeyExist.once('value', function (snap) {
            if (snap.val() !== null) {
                let chatUsers = snap.val().users;
                let cd = JSON.parse(localStorage.getItem('chatDetail'));
                if (cd.id === snap.val().id) {
                    localStorage.removeItem('chatDetail');
                    localStorage.removeItem('chatUsers');
                    for (var user of Object.keys(chatUsers)) {
                        if (type === true) {
                            if (token.id === chatUsers[user]) {
                                db.ref('Chats').child(chatId).remove();
                                db.ref('MyChats').child(chatUsers[user]).child(chatId).remove();
                                db.ref('Messages').child(chatId).remove();
                            } else {
                                db.ref('MyChats').child(chatUsers[user]).child(chatId).remove();
                            }
                        } else {
                            if (token.id === chatUsers[user]) {
                                db.ref('MyChats').child(chatUsers[user]).child(chatId).remove();
                                db.ref('Chats').child(chatId).child('users').child(chatUsers[user]).remove();
                            } else {
                                db.ref('MyChats').child(chatUsers[user]).child(chatId).child('users').child(token.id).remove();
                            }
                        }
                    }
                }
                dispatch({
                    type: DELETE_DETAIL,
                    payload: chatId,
                })
            }
        });
        history.push('chat');
    } catch (error) {
        toast.error('Something went wrong!', {
            position: toast.POSITION.TOP_RIGHT
        });
    }
}

// Update message as read
export const setChatMessageAsRead = (chatId, token) => (dispatch) => {
    try {
        db.ref("Messages").child(chatId).orderByChild("status").equalTo(false).once("child_added", (snapshot) => {
            if (Object.keys(snapshot.val()).length > 0) {
                if (snapshot.val().to[0] === token.id) {
                    db.ref('Messages').child(chatId).child(snapshot.val().id).update({
                        status: true
                    });
                }
            }
        });
        dispatch({ type: CLEAR_ERRORS })
    } catch (error) {
        dispatch({ type: CLEAR_ERRORS })
        toast.error('Something went wrong!', {
            position: toast.POSITION.TOP_RIGHT
        });
    }
}

// Add New Chat
export const addNewChat = (params, userArray, isEdit) => (dispatch) => {
    try {
        db.ref('Chats').child(params.id).update({
            admin: params.authUser,
            id: params.id,
            title: params.title,
            url: params.url,
            unreadObj: 0,
            users: params.users
        });
        for (let i = 0; i < userArray.length; i++) {
            let uId;
            let username;
            if (isEdit) {
                uId = userArray[i];
            } else {
                uId = userArray[i].id;
                username = userArray[i].name;
                db.ref('Users').child(uId).update({
                    id: uId,
                    name: username,
                    img: userArray[i].url
                });
            }
            let isExist = db.ref('MyChats').child(uId);
            isExist.once('value', function (snap) {
                db.ref('MyChats').child(uId).child(params.id).update({
                    admin: params.authUser,
                    id: params.id,
                    title: params.title,
                    url: params.url,
                    unreadObj: 0,
                    users: params.users
                });
            });
            let locSet = {
                admin: params.authUser,
                id: params.id,
                title: params.title ? params.title : username,
                url: params.url,
                unreadObj: 0,
                users: params.users
            }
            localStorage.setItem('chatDetail', JSON.stringify(locSet));
        }
    } catch (error) {
        dispatch({ type: CLEAR_ERRORS })
        toast.error('Something went wrong!', {
            position: toast.POSITION.TOP_RIGHT
        });
    }
}

// Update user media
export const updateUserMedia = (params) => () => {
    try {
        if (params.image) {
            db.ref('Users').child(params.id).update({
                id: params.id,
                name: params.name,
                img: params.image
            });
        } else {
            db.ref('Users').child(params.id).update({
                name: params.name
            });
        }
    } catch (error) {
        console.log('error', error);
    }
}