import React, { Component } from 'react';
import InitiateModal from './InitiateModal';
import { connect } from 'react-redux';
import { myGroupsList } from '../../Redux/actions/homeAction';
import { getToken } from '../../Helper/Session';

class InitiateFreedom extends Component {
    constructor(props) {
        super(props);
        this.state = {
            toggle: false,
        }
    }

    componentDidMount() {
        let token = getToken() !== null ? JSON.parse(getToken()) : '';
        const params = {
            apikey: process.env.REACT_APP_API_KEY,
            userid: token.id
        }
        if (!this.props.home.myGroups.length) {
            this.props.myGroupsList(params);
        }
    }

    isGrpAvl = (e, count) => {
        e.preventDefault();
        if (count > 0) {
            this.setState({
                toggle: true
            });
        }
    }

    render() {
        const { home: { myGroups } } = this.props;
        return (
            <>
                <div className="initiate-freedom-btn-block" data-toggle="modal" data-target={this.state.toggle ? '#initiateFreedom' : '#initiateFreedom4'} onClick={(e) => this.isGrpAvl(e, myGroups.length)}>
                    <button className="btn btn-primary lg text-uppercase">Initiate Freedom</button>
                </div>
                <InitiateModal
                    myGroups={myGroups}
                />
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    home: state.home,
})

const mapActionToProps = {
    myGroupsList
}

export default connect(mapStateToProps, mapActionToProps)(InitiateFreedom);