import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { legalList, deleteLegal } from '../../Redux/actions/homeAction';
import InfiniteScroll from "react-infinite-scroll-component";
import InitiateFreedom from '../Common/InitiateFreedom';
import { Loader } from '../Common/Loader';
import Header from '../Layout/Header';
import { confirmAlert } from 'react-confirm-alert';

class LegelCounsel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search: '',
            moreOpened: false,
            page: 1,
            legalId: ''
        }
    }

    componentDidMount() {
        const params = {
            apikey: process.env.REACT_APP_API_KEY,
            page: this.state.page,
            search: this.state.search ? this.state.search : ''
        }
        if (!this.props.home.legals.length) {
            this.props.legalList(params);
        }
    }

    fetchMoreData = () => {
        let newPage = this.state.page;
        newPage++;
        if (this.props.home.leCurrentPage !== this.state.page) {
            newPage = this.props.home.leCurrentPage + 1
        }
        this.setState({ page: newPage });
        const params = {
            apikey: process.env.REACT_APP_API_KEY,
            page: newPage,
            search: this.state.search ? this.state.search : ''
        }
        this.props.legalList(params)
    };

    searchKeyword = (e) => {
        this.setState({
            search: e.target.value,
            page: 1
        });
        const params = {
            apikey: process.env.REACT_APP_API_KEY,
            page: e.target.value !== '' ? this.state.page : 1,
            search: e.target.value
        }
        this.props.legalList(params)
    }

    moreOpenedToggle = (e, legalId, result) => {
        e.preventDefault();
        if (result !== undefined) {
            localStorage.setItem('legal', JSON.stringify(result))
        } else {
            localStorage.removeItem('legal')
        }
        this.setState({
            moreOpened: !this.state.moreOpened,
            legalId: legalId
        });
    }

    handleDelete = (e, legalId) => {
        e.preventDefault();
        const params = {
            legalid: legalId,
            apikey: process.env.REACT_APP_API_KEY
        }
        confirmAlert({
            title: 'Confirm to submit',
            message: 'Are you sure you want to delete?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        this.props.deleteLegal(params);
                    }
                },
                {
                    label: 'No',
                }
            ]
        });
        this.setState({
            moreOpened: !this.state.moreOpened,
        });
    }

    render() {
        const { home: { legals, legalHasMore, leCurrentPage }, user: { user } } = this.props;
        let markup = legals.length > 0 ? legals.map((result, i) =>
            <div className="counsel-card" key={i}>
                <figure className="counsel-card__fig">
                    <img src={result.legal_url ? process.env.REACT_APP_API_URL + result.legal_url : '../images/logo.png'} alt="Article" />
                </figure>
                <figcaption className="counsel-card__info">
                    {user !== undefined && user.admin === "1" ?
                        <div className="custom-card__user-card__info">
                            <span className="more_opt_v" onClick={(e) => this.moreOpenedToggle(e, result.legal_id, result)} >
                                <img src="../images/more-dot-v.svg" alt="fig" />
                            </span>
                        </div>
                        : ''
                    }
                    <h4 className="counsel-card__info__title" >{result.legal_name} - {result.statename}</h4>
                    <p className="scrollSection">{result.legal_description}</p>
                    <div className="fx-jcfe">
                        <a href={result.legal_website} target="_blank" rel="noreferrer" className="iconx"><img src="../images/glob.svg" alt="Globe" /></a>
                        <a href={`tel:${result.legal_phone}`} className="iconx"><img src="../images/call.svg" alt="Call" /></a>
                        <a href={`mailto::${result.legal_email}`} className="iconx"><img src="../images/email.svg" alt="Email" /></a>
                    </div>
                </figcaption>
            </div>
        ) : (leCurrentPage === 1 ? <p className="no-data">No Data</p> : '');
        return (
            <section className="main-Section">
                <Header
                    title={'Legal Counsel'}
                    backUrl={'dashboard'}
                />
                <div className="container-fluid midSection" style={{ height: "auto" }}>
                    <div className="content">
                        <InitiateFreedom />
                        <div className="search-box">
                            <input type="text" placeholder="Keyword Search" value={this.state.search} name="search" onChange={this.searchKeyword} className="inp" />
                        </div>
                        <div className="post-container">
                            <InfiniteScroll
                                dataLength={legals.length}
                                next={this.fetchMoreData}
                                hasMore={legalHasMore}
                                loader={legals.length >= 10 ? <Loader /> : ''}
                            >
                                <ul>
                                    {markup}
                                    {leCurrentPage === 0 ? <Loader /> : ''}
                                </ul>
                            </InfiniteScroll>
                        </div>
                    </div>
                    {user !== undefined && user.admin === "1" &&
                        <div className="action-btn">
                            <span className="cmn-btn addMedia">
                                <Link to="add-legel-counsel">
                                    <img src="../images/plus-big.svg" alt="icon" />
                                </Link>
                            </span>
                        </div>
                    }
                    {user !== undefined && user.admin === "1" &&
                        <div className={this.state.moreOpened ? "bottomCard opened" : "bottomCard"}>
                            <Link to={`edit-legel-counsel-${this.state.legalId}`}>
                                <button className="btn btn-default mb-2">Edit</button>
                            </Link>
                            <button className="btn btn-default mb-2" onClick={(e) => this.handleDelete(e, this.state.legalId)}>Delete</button>
                            <button className="btn btn-plain" onClick={(e) => this.moreOpenedToggle(e)}>Cancel</button>
                        </div>
                    }
                </div>
            </section>
        )
    }
}

const mapStateToProps = (state) => ({
    home: state.home,
    user: state.user,
    UI: state.UI
})

const mapActionToProps = {
    legalList,
    deleteLegal
}

export default connect(mapStateToProps, mapActionToProps)(LegelCounsel);