export const getUser = () => {
  const userStr = localStorage.getItem('user');
  if (userStr) return JSON.parse(userStr);
  else return null;
}

export const getToken = () => {
  return localStorage.getItem('GY6_token') || null;
}

export const removeUserSession = () => {
  localStorage.removeItem('GY6_token');
}

export const isKeyExist = (arr, obj) => {
  return arr.includes(obj)
}

export const checkFBKey = (obj, userId, authUser) => {
  for (var data of Object.keys(obj)) {
    for (var userIds of Object.keys(obj[data].users)) {
      if (Object.keys(obj[data].users).length === 2) {
        if (parseInt(authUser) !== parseInt(userIds)) {
          if (parseInt(userId) === parseInt(userIds)) {
            return data;
          }
        }
      }
    }
  }
}
