import React, { Component } from 'react';
import { inputPlaceHolders, isEmpty, isMobile } from "../../Helper/Util";
import { connect } from 'react-redux';
import { updateProfile } from '../../Redux/actions/userAction';
import { getToken } from '../../Helper/Session';
import Header from '../Layout/Header';

class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            username: '',
            phone: '',
            email: '',
            image: null,
            errors: {},
        };
    }

    componentDidUpdate() {
        inputPlaceHolders()
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { user: { user } } = nextProps;
        if (user !== undefined) {
            this.setState({
                name: user.name ? user.name : '',
                username: user.username ? user.username : '',
                phone: user.phone ? user.phone : '',
                email: user.email ? user.email : '',
            })
        }
    }

    validate = () => {
        const { name, username, phone } = this.state;
        let errors = {};
        if (isEmpty(name)) errors.name = true;
        if (isEmpty(username)) errors.username = true;
        if (isEmpty(phone)) errors.phone = true;
        else if (!isMobile(phone)) errors.phone = true;
        return errors;
    }

    handleChange = (e) => {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value,
            errors: {
                ...this.state.errors,
                [e.target.name]: ''
            }
        });
    }

    handleImgChange = (e) => {
        var file = e.target.files[0];
        let reader = new FileReader();
        reader.onload = (e) => {
            let images = e.target.result;
            this.setState({
                image: images.split(';base64')[1],
                errors: {
                    image: ''
                }
            });
        };
        reader.readAsDataURL(file);
    };

    handleSubmit = (e) => {
        e.preventDefault();
        let token = getToken() !== null ? JSON.parse(getToken()) : '';
        const errors = this.validate();
        if (Object.keys(errors).length !== 0) {
            this.setState({ errors });
            return false;
        }
        if (this.state.image) {
            const params = {
                userid: token.id,
                name: this.state.name,
                username: this.state.username,
                phone: this.state.phone,
                email: this.state.email,
                image: this.state.image
            }
            this.props.updateProfile(params, true);
        } else {
            const params = {
                userid: token.id,
                name: this.state.name,
                username: this.state.username,
                phone: this.state.phone,
                email: this.state.email
            }
            this.props.updateProfile(params, true);
        }
    }

    render() {
        const { errors } = this.state;
        const { UI: { loading }, user: { user } } = this.props;
        return (
            <>
                <section className="main-Section">
                    <Header
                        title={'Edit Profile'}
                        backUrl={'dashboard'}
                    />
                    <div className="container-fluid midSection scrollSection">
                        <div className="content login mg-top-40">
                            <form onSubmit={this.handleSubmit}>
                                <div className="form-block formBox profile-manage">
                                    <div className="profileBrowse">
                                        <div className="profileImage">
                                            <figure>
                                                {this.state.image ? (
                                                    <img src={this.state.image ? (`data:image/png;base64` + this.state.image) : "./images/profile-pic.png"} alt="Fig" />
                                                ) :
                                                    <img src={user !== undefined && user.url !== '' ? process.env.REACT_APP_API_URL + user.url : '../images/logo.png'} alt="Fig" />
                                                }
                                            </figure>
                                            <button className="addProfile">
                                                <img src="../images/add-profile-plus.svg" alt="Add" />
                                                <input type="file" name="image" className={`form-control input__field ${errors.images ? 'inputError' : ''}`} onChange={this.handleImgChange} />
                                            </button>
                                        </div>
                                    </div>
                                    <fieldset>
                                        <span className="placeholder">Name</span>
                                        <input type="text" className={`form-control input__field ${errors.name ? 'inputError' : ''}`} name="name" value={this.state.name} onChange={this.handleChange} autoComplete="off" />
                                    </fieldset>
                                    <fieldset>
                                        <span className="placeholder">Username</span>
                                        <input type="text" className={`form-control input__field ${errors.username ? 'inputError' : ''}`} name="username" value={this.state.username} onChange={this.handleChange} autoComplete="off" />
                                    </fieldset>
                                    <fieldset>
                                        <span className="placeholder">Email</span>
                                        <input type="text" className={`form-control input__field ${errors.email ? 'inputError' : ''}`} name="email" value={this.state.email} onChange={this.handleChange} autoComplete="off" />
                                    </fieldset>
                                    <fieldset>
                                        <span className="placeholder">Mobile Number</span>
                                        <input type="number" className={`form-control input__field ${errors.phone ? 'inputError' : ''}`} name="phone" value={this.state.phone} onChange={this.handleChange} autoComplete="off" />
                                    </fieldset>
                                </div>
                                <input type="submit" disabled={loading ? 'disabled' : ''} value={loading ? "Loading..." : "Update"} className="btn btn-primary" />
                            </form>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}


const mapStateToProps = (state) => ({
    user: state.user,
    UI: state.UI
})

const mapActionToProps = {
    updateProfile
}

export default connect(mapStateToProps, mapActionToProps)(Profile);
