import React, { Component } from 'react';
import { connect } from 'react-redux';
import { flagArticle, articleDetail } from '../../Redux/actions/homeAction';
import { getToken } from '../../Helper/Session';
import { Loader } from '../Common/Loader';
import moment from 'moment';
import InitiateFreedom from '../Common/InitiateFreedom';
import { confirmAlert } from 'react-confirm-alert';
import Header from '../Layout/Header';

class ArticlesLegislationDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            moreOpened: false,
            articleId: ''
        }
    }

    componentDidMount() {
        const articleId = this.props.match.params.id;
        const params = {
            apikey: process.env.REACT_APP_API_KEY,
            article_id: articleId
        }
        this.props.articleDetail(params);
    }

    moreOpenedToggle = (e, articleId) => {
        e.preventDefault();
        this.setState({
            moreOpened: !this.state.moreOpened,
            articleId: articleId
        });
    }

    handleFlag = (e, articleId) => {
        e.preventDefault();
        let token = getToken() !== null ? JSON.parse(getToken()) : '';
        const params = {
            article_id: articleId,
            fromid: token.id,
            apikey: process.env.REACT_APP_API_KEY
        }
        this.props.flagArticle(params);
        this.setState({
            moreOpened: !this.state.moreOpened,
        });
    }
    handleDelete = (e, articleId) => {
        e.preventDefault();
        const params = {
            articleid: articleId,
            apikey: process.env.REACT_APP_API_KEY
        }
        confirmAlert({
            title: 'Confirm to submit',
            message: 'Are you sure you want to delete?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        this.props.deleteArticle(params);
                    }
                },
                {
                    label: 'No',
                }
            ]
        });
        this.setState({
            moreOpened: !this.state.moreOpened,
        });
    }

    userProfile = (e, userId) => {
        e.preventDefault();
        this.props.history.push(`user-profile-${userId}`, 4)
    }

    render() {
        const { home: { articleDetail }, UI: { loading }, user: { user } } = this.props;
        let markup = !loading ? Object.keys(articleDetail).length > 0 ?
            <div className="custom-card">
                <figure className="custom-card__photo">
                    <img src={articleDetail.url !== '' ? process.env.REACT_APP_API_URL + articleDetail.url : '../images/logo.png'} alt="Article" />
                </figure>
                <div className="custom-card__date">
                    <span>
                        {moment(articleDetail.dateadded).format('MM/DD/YYYY')}
                    </span>
                </div>
                <div className="custom-card__user-card">
                    <figure className="user-img cursor-pointer" onClick={(e) => this.userProfile(e, articleDetail.user.id)}>
                        <img src={articleDetail.user !== null ? articleDetail.user.url !== null ? process.env.REACT_APP_API_URL + articleDetail.user.url : '../images/logo.png' : '../images/logo.png'} alt="User" />
                    </figure>
                    <figcaption className="custom-card__user-card__info">
                        <span className="more_opt_v" onClick={(e) => this.moreOpenedToggle(e, articleDetail.article_id)} >
                            <img src="../images/more-dot-v.svg" alt="fig" />
                        </span>
                        <h4 className="inblockTitle" onClick={(e) => this.userProfile(e, articleDetail.user.id)}>{articleDetail.user !== undefined ? articleDetail.user.name : ''}@{articleDetail.user !== undefined ? articleDetail.user.username : ''}</h4>
                    </figcaption>
                </div>
                <div className="custom-card__description">
                    <h3>{articleDetail.article_title} - {articleDetail.statename}</h3>
                    <p className="scrollSection">{articleDetail.article_description}</p>
                </div>
            </div>
            : <p className="no-data">No Data</p> : <Loader />
        return (
            <section className="main-Section">
                <Header
                    title={'Articles & Legislation'}
                    backUrl={`articles-legislation`}
                />
                <div className="container-fluid midSection scrollSection">
                    <div className="content">
                        <InitiateFreedom />
                        <div className="post-container">
                            {markup}
                        </div>
                    </div>
                </div>
                <div className="action-btn">
                    <a href={articleDetail.weburl} target="_blank" rel="noreferrer" >
                        <img src="../images/glob.svg" alt="fig" />
                    </a>
                </div>
                <div className={this.state.moreOpened ? "bottomCard opened" : "bottomCard"}>
                    {user !== undefined && user.admin === "1" ?
                        <button className="btn btn-default mb-2"
                            onClick={(e) => this.handleDelete(e, this.state.articleId)}
                        >Delete</button>
                        :
                        <button className="btn btn-default mb-2"
                            onClick={(e) => this.handleFlag(e, this.state.articleId)}
                        >Flag</button>
                    }
                    <button className="btn btn-plain" onClick={(e) => this.moreOpenedToggle(e)}>Cancel</button>
                </div>
            </section>
        )
    }
}

const mapStateToProps = (state) => ({
    home: state.home,
    user: state.user,
    UI: state.UI
})

const mapActionToProps = {
    flagArticle,
    articleDetail
}

export default connect(mapStateToProps, mapActionToProps)(ArticlesLegislationDetail);