import React, { Component } from 'react';
import Header from './Component/Layout/Header';

class AboutUs extends Component {

    render() {
        return (
            <>
                <section className="main-Section">
                    <Header
                        title={'About Us'}
                        backUrl={'setting'}
                    />
                    <div className="container-fluid midSection scrollSection our-mission">
                        <div className="content">
                            <div className="our-mission-content">
                                <p> Got Your 6 is a CIVILIAN owned company FOR CIVILIANS. Our mission is simple: to ensure the freedoms of our 2nd Amendment be as prevalent for future generations as they were when the United States was founded.  Our goal is to defeat gun grabbing politicians and organizations at the legislative level, judicial level and community level.  When tyranny comes to your community, it takes a community effort to overcome it, and we want to give everyone the outreach they need to overcome tyranny when it is presented to them. Got Your 6 will always be unapologetically pro-civilian and unapologetically pro-2A. </p>
                                <p> Got Your 6 is an app like nothing else on the market to be able to effectively fight these battles and put the power back into the hands of the individual.  We no longer have to sit on the sidelines wondering what we can do to help.  You can help right here, right now, on all fronts. If we ALL do not start getting involved very quickly our Right to Keep and Bear Arms will be nothing but a story we tell to future generations. The work we put in today will pay dividends for our children and our children's children. Join now and see for yourself the measurable impact that Got Your 6 is having on our 2nd Amendment rights. </p>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default AboutUs;
