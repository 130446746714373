import React, { Component } from 'react';
import { connect } from 'react-redux';
import { cardList, deleteCard } from '../../Redux/actions/userAction';
import { getToken } from '../../Helper/Session';
import { Loader } from '../Common/Loader';
import { confirmAlert } from 'react-confirm-alert';
import Header from '../Layout/Header';

class SubscriptionInfo extends Component {

    componentDidMount() {
        let token = getToken() !== null ? JSON.parse(getToken()) : '';
        const params = {
            apikey: process.env.REACT_APP_API_KEY,
            userid: token.id
        }
        this.props.cardList(params);
    }

    removeCard = (e, cardId) => {
        e.preventDefault();
        let token = getToken() !== null ? JSON.parse(getToken()) : '';
        const params = {
            apikey: process.env.REACT_APP_API_KEY,
            userid: token.id,
            stripecardid: cardId
        }
        confirmAlert({
            title: 'Confirm to submit',
            message: 'Are you sure you want to remove the card?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        this.props.deleteCard(params);
                    }
                },
                {
                    label: 'No',
                }
            ]
        });
    }

    render() {
        const { home: { cards }, UI: { loading } } = this.props;
        let markup = !loading ? cards.length > 0 ? cards.map((result, i) =>
            <li key={i}>
                <h4 className="font-bold title">{result.cardbrand}</h4>
                <div className="fx-jcsb-ac">
                    <span className="cardNumber"><i className="fas fa-credit-card"></i> {result.cardnumber}</span>
                    <span className="btn btn-primary btn-xs" onClick={(e) => this.removeCard(e, result.stripecardid)}>Remove</span>
                </div>
            </li>
        ) : <p className="no-data">No Data</p> : <Loader />;
        return (
            <section className="main-Section">
                <Header
                    title={'Payment Method'}
                    backUrl={`dashboard`}
                    rightText='plus'
                    toUrl={`add-card`}
                />
                <div className="container-fluid midSection scrollSection">
                    <div className="usersList subscription">
                        <ul>
                            {markup}
                        </ul>
                    </div>
                </div>
            </section>
        )
    }
}

const mapStateToProps = (state) => ({
    home: state.home,
    UI: state.UI
})

const mapActionToProps = {
    cardList,
    deleteCard
}

export default connect(mapStateToProps, mapActionToProps)(SubscriptionInfo);