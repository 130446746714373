import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { allPatriotsGroupsList, requestJoinGroup, deleteGroup } from '../../Redux/actions/homeAction';
import { getToken } from '../../Helper/Session';
import { Loader } from '../Common/Loader';
import Header from '../Layout/Header';

class GY6PatriotGroup extends Component {
    constructor(props) {
        super(props)
        this.state = {
            groupid: '',
            groupname: '',
            search: ''
        }
    }

    componentDidMount() {
        let token = getToken() !== null ? JSON.parse(getToken()) : '';
        const params = {
            apikey: process.env.REACT_APP_API_KEY,
            userid: token.id
        }
        this.props.allPatriotsGroupsList(params);
    }


    handleGrpJoinOrDelete = (e, groupid) => {
        e.preventDefault();
        let token = getToken() !== null ? JSON.parse(getToken()) : '';
        if (token.admin === '1') {
            const params = {
                groupid: groupid,
                apikey: process.env.REACT_APP_API_KEY
            }
            this.props.deleteGroup(params, token.id, true);
        } else {
            const params = {
                groupid: groupid,
                apikey: process.env.REACT_APP_API_KEY,
                userid: token.id
            }
            this.props.requestJoinGroup(params);
        }
    }

    getGroupDetail = (e, groupid, groupname) => {
        e.preventDefault();
        this.setState({
            groupid: groupid,
            groupname: groupname,
        })
    }

    searchKeyword = (e) => {
        this.setState({
            search: e.target.value
        })
    }

    render() {
        const { home: { groups }, UI: { loading }, user: { user } } = this.props;
        let textBtn = user !== undefined && user.admin === "1" ? 'Delete' : 'Join';
        let textMsg = user !== undefined && user.admin === "1" ? 'Are You sure you want to delete this group?' : 'Confirm to join?';
        let textLbl = user !== undefined && user.admin === "1" ? 'Account' : this.state.groupname;
        const group = groups.filter(result => {
            return result.groupname.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1;
        });
        let markup = !loading ? group.length > 0 ? group.map((result, i) =>
            <li key={i}>
                <div className="userProfile">
                    <figure>
                        <img src={result.image !== '' ? process.env.REACT_APP_API_URL + result.image : '../images/logo.png'} alt="User" />
                    </figure>
                    <figcaption>
                        <h2>{result.groupname}</h2>
                    </figcaption>
                </div>
                <div className="addGroup" data-toggle="modal" data-target="#joinRequest">
                    <button type="button" className="btn btn-primary noshadow btn-xs" onClick={(e) => this.getGroupDetail(e, result.groupid, result.groupname)}>{textBtn}</button>
                </div>
            </li>
        ) : <p className="no-data">No Data</p> : <Loader />;
        return (
            <section className="main-Section">
                <Header
                    title={'GY6 Patriots Group'}
                    backUrl={`create-patriot-group`}
                />
                <div className="container-fluid midSection scrollSection userListCon">
                    <div className="search-form">
                        <div className="form">
                            <input type="text" placeholder="Keyword Search" value={this.state.search} name="search" onChange={this.searchKeyword} className="searchInput" />
                        </div>
                    </div>
                    <div className="content">
                        <div className="usersList">
                            <ul>
                                {markup}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="modal custom-modal initiateFreedom  fade" id="joinRequest" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-body p-0 pt-4">
                                <div className="logo"><img src="../images/logo.png" alt="Logo" /></div>
                                <div className="text-block">
                                    <h2>{textLbl}</h2>
                                    <p>{textMsg}</p>
                                </div>
                                <div className="reasonList">
                                    &nbsp;
                                </div>
                                <div className="yesNoBtns">
                                    <button
                                        className="btn yesBtn"
                                        onClick={(e) => this.handleGrpJoinOrDelete(e, this.state.groupid)}>{loading ? <Loader /> : 'Yes'}
                                    </button>
                                    <button className="btn" data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

const mapStateToProps = (state) => ({
    home: state.home,
    user: state.user,
    UI: state.UI
})

const mapActionToProps = {
    allPatriotsGroupsList,
    requestJoinGroup,
    deleteGroup
}

export default connect(mapStateToProps, mapActionToProps)(withRouter(GY6PatriotGroup));