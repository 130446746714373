import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PublicRoute from './PublicRoute';
import { UserRoute, IsUserSubscribedRoute, AdminRoute } from './PrivateRoute';
import { Provider } from 'react-redux';
import store from './Redux/store';
import Home from './Home';
import Error from './Error';
import Login from './Component/Auth/Login';
import ForgotPassword from './Component/Auth/ForgotPassword';
import PrivacyPolicy from './PrivacyPolicy';
import AboutUs from './AboutUs';
import OurMission from './Component/User/OurMission';
import BecomeMember from './Component/User/BecomeMember';
import Subscribe from './Component/User/Subscribe';
import UserDashboard from './Component/User/Dashboard';
import UserChangePassword from './Component/User/ChangePassword';
import UserProfile from './Component/User/Profile';
import Notifications from './Component/User/Notifications';
import Setting from './Component/User/Setting';
import AffiliatesDonation from './Component/User/AffiliatesDonation';
import AddAffiliatesDonation from './Component/User/AddAffiliatesDonation';
import EditAffiliatesDonation from './Component/User/EditAffiliatesDonation';
import ArticlesLegislation from './Component/User/ArticlesLegislation';
import ArticlesLegislationDetail from './Component/User/ArticlesLegislationDetail';
import AddArticle from './Component/User/AddArticle';
import CurrentEvents from './Component/User/CurrentEvents';
import AddEvent from './Component/User/AddEvent';
import AddLegelCounsel from './Component/User/AddLegelCounsel';
import EditLegelCounsel from './Component/User/EditLegelCounsel';
import LegelCounsel from './Component/User/LegelCounsel';
import Map from './Component/User/Map';
import Media from './Component/User/Media';
import MediaDetail from './Component/User/MediaDetail';
import AddMedia from './Component/User/AddMedia';
import Success from './Component/User/Success';
import CreatePatriotGroup from './Component/Group/CreatePatriotGroup';
import Members from './Component/Group/Members';
import MemberDetail from './Component/Group/MemberDetail';
import SubscriptionInfo from './Component/User/SubscriptionInfo';
import GY6PatriotGroup from './Component/Group/GY6PatriotGroup';
import PatriotGroupList from './Component/Group/PatriotGroupList';
import PatriotGroupAndRequest from './Component/Group/PatriotGroupAndRequest';
import Chat from './Component/User/Chat';
import ChatDetail from './Component/User/ChatDetail';
import UsersToChat from './Component/User/UsersToChat';
import UserList from './Component/User/UserList';
import Users from './Component/User/Users';
import EditChat from './Component/User/EditChat';
import AddGroup from './Component/Group/AddGroup';
import EditGroup from './Component/Group/EditGroup';
import { getToken } from './Helper/Session';
import { SET_AUTHENTICATED } from './Redux/types';
import { logoutUser, getUserData } from './Redux/actions/userAction';
import Signup from './Component/Auth/Signup';
import UserDetail from './Component/User/UserDetail';
import Flagged from './Component/User/Flagged';
import EditArticle from './Component/User/EditArticle';
import EditEvent from './Component/User/EditEvent';
import EditMedia from './Component/User/EditMedia';

import { messaging } from "./Component/Services/firebase";
import { getOS } from "./Helper/Util";
import CacheBuster from './CacheBuster';
import { toast } from 'react-toastify';

const token = getToken() !== null ? JSON.parse(getToken()) : '';

if (token) {
  if (!token) {
    store.dispatch(logoutUser());
    window.location.href = '/';
  } else {
    store.dispatch({ type: SET_AUTHENTICATED });
    const params = {
      userid: token.id,
      apikey: process.env.REACT_APP_API_KEY
    }
    store.dispatch(getUserData(params));
  }
}

class App extends Component {

  componentDidMount() {
    if (getOS() === 'iOS') {
      navigator.geolocation.getCurrentPosition(this.showMap);
    } else {
      navigator.geolocation.getCurrentPosition((position) => {
        localStorage.setItem('lat', position.coords.latitude)
        localStorage.setItem('lng', position.coords.longitude)
      });
      // Allow to show notification
      new Promise((resolve, reject) => {
        messaging.requestPermission()
          .then(() => {
            messaging.getToken().then((result) => {
              localStorage.setItem('fbToken', result)
            })
          }).then((firebaseToken) => {
            resolve(firebaseToken);
          }).catch((err) => {
            // toast.error('The notification permission was not granted and blocked instead', {
            //   position: toast.POSITION.TOP_RIGHT
            // });
          });
      });
    }
  }

  showMap = (position) => {
    localStorage.setItem('lat', position.coords.latitude)
    localStorage.setItem('lng', position.coords.longitude)
  }

  render() {
    return (
      <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (loading) return null;
          if (!loading && !isLatestVersion) {
            refreshCacheAndReload();
          }

          return (
            <>
              <ToastContainer />
              <Provider store={store}>
                <Router>
                  <Switch>
                    <PublicRoute exact path='/' component={Home} />
                    <PublicRoute exact path='/login' component={Login} />
                    <PublicRoute exact path='/signup' component={Signup} />
                    <PublicRoute exact path='/forgot-password' component={ForgotPassword} />
                    <IsUserSubscribedRoute exact path='/user/our-mission' component={OurMission} />
                    <IsUserSubscribedRoute exact path='/user/become-member' component={BecomeMember} />
                    <IsUserSubscribedRoute exact path='/user/subscribe' component={Subscribe} />
                    <UserRoute exact path='/user/create-patriot-group' component={CreatePatriotGroup} />
                    <UserRoute exact path='/user/edit-patriot-group-:grpId' component={CreatePatriotGroup} />
                    <UserRoute exact path='/user/patroit-group-users' component={PatriotGroupList} />
                    <UserRoute exact path='/user/patroit-group-users-:grpId' component={PatriotGroupList} />
                    <UserRoute exact path='/user/gy-patroit-group' component={GY6PatriotGroup} />
                    <UserRoute exact path='/user/add-group' component={AddGroup} />
                    <UserRoute exact path='/user/edit-group-:id' component={EditGroup} />
                    <UserRoute exact path='/user/dashboard' component={UserDashboard} />
                    <UserRoute exact path='/user/change-password' component={UserChangePassword} />
                    <UserRoute exact path='/user/update-profile' component={UserProfile} />
                    <UserRoute exact path='/user/notifications' component={Notifications} />
                    <UserRoute exact path='/user/setting' component={Setting} />
                    <UserRoute exact path='/user/subscription-info' component={SubscriptionInfo} />
                    <UserRoute exact path='/user/current-events' component={CurrentEvents} />
                    <UserRoute exact path='/user/add-event' component={AddEvent} />
                    <UserRoute exact path='/user/success' component={Success} />
                    <UserRoute exact path='/user/articles-legislation' component={ArticlesLegislation} />
                    <UserRoute exact path='/user/articles-legislation-detail-:id' component={ArticlesLegislationDetail} />
                    <UserRoute exact path='/user/map-:lat-:lng' component={Map} />
                    <UserRoute exact path='/user/legel-counsel' component={LegelCounsel} />
                    <UserRoute exact path='/user/affiliates-donation' component={AffiliatesDonation} />
                    <UserRoute exact path='/user/media' component={Media} />
                    <UserRoute exact path='/user/media-detail-:id' component={MediaDetail} />
                    <UserRoute exact path='/user/add-media' component={AddMedia} />
                    <UserRoute exact path='/user/about-us' component={AboutUs} />
                    <UserRoute exact path='/user/privacy-policy' component={PrivacyPolicy} />
                    <UserRoute exact path='/user/patriot-group-and-request' component={PatriotGroupAndRequest} />
                    <UserRoute exact path='/user/add-article' component={AddArticle} />
                    <UserRoute exact path='/user/chat' component={Chat} />
                    <UserRoute exact path='/user/chat-:id' component={ChatDetail} />
                    <UserRoute exact path='/user/user-lists' component={UserList} />
                    <UserRoute exact path='/user/users-to-chat' component={UsersToChat} />
                    <UserRoute exact path='/user/edit-chat-:id' component={EditChat} />
                    <UserRoute exact path='/user/members-:id' component={Members} />
                    <UserRoute exact path='/user/user-detail-:groupId-:userId' component={MemberDetail} />
                    <UserRoute exact path='/user/add-card' component={Subscribe} />
                    <UserRoute exact path='/user/user-profile-:id' component={UserDetail} />

                    <AdminRoute exact path='/user/add-affiliates-donation' component={AddAffiliatesDonation} />
                    <AdminRoute exact path='/user/edit-affiliates-donation-:id' component={EditAffiliatesDonation} />
                    <AdminRoute exact path='/user/add-legel-counsel' component={AddLegelCounsel} />
                    <AdminRoute exact path='/user/edit-legel-counsel-:id' component={EditLegelCounsel} />
                    <AdminRoute exact path='/user/users' component={Users} />
                    <AdminRoute exact path='/user/flagged-list' component={Flagged} />
                    <AdminRoute exact path='/user/edit-article-:id' component={EditArticle} />
                    <AdminRoute exact path='/user/edit-event-:id' component={EditEvent} />
                    <AdminRoute exact path='/user/edit-media-:id' component={EditMedia} />

                    <Route component={Error} />
                  </Switch>
                </Router>
              </Provider>
            </>
          );
        }}
      </CacheBuster>
    );
  }
}
export default App;
