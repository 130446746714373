import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from '../Layout/Header';

class Success extends Component {

    render() {
        return (
            <section className="main-Section">
                <Header
                    title={'Success'}
                    backUrl={'dashboard'}
                />
                <div className="container-fluid midSection scrollSection success-page">
                    <div className="success-card">
                        <figure className="success-card__logo">
                            <img src="../images/logo.png" alt="logo" />
                        </figure>
                        <figcaption className="success-card__info">
                            <h2 className="success-card__info__title">Success!</h2>
                            <p>Your Patriot Group has been notified!</p>
                        </figcaption>
                    </div>
                </div>
            </section>
        )
    }
}

const mapStateToProps = (state) => ({
    UI: state.UI
})


export default connect(mapStateToProps)(Success);