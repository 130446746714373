import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addAffiliates } from '../../Redux/actions/homeAction';
import { stateList } from '../../Redux/actions/userAction';
import { inputPlaceHolders, isEmpty, isWebsite } from "../../Helper/Util";
import Header from '../Layout/Header';

class EditAffiliatesDonation extends Component {
    constructor(props) {
        super(props)
        this.state = {
            aff_id: '',
            aff_name: '',
            state: '',
            aff_website: '',
            aff_description: '',
            image: null,
            url: '',
            errors: {}
        }
    }

    handleChange = e => {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value,
            errors: {
                ...this.state.errors,
                [e.target.name]: ''
            }
        })
    }

    handleImgChange = (e) => {
        var file = e.target.files[0];
        let reader = new FileReader();
        reader.onload = (e) => {
            let images = e.target.result;
            this.setState({
                image: images.split(';base64')[1],
                errors: {
                    image: ''
                }
            });
        };
        reader.readAsDataURL(file);
    };

    componentDidUpdate() {
        inputPlaceHolders();
    }

    componentDidMount() {
        const params = {
            apikey: process.env.REACT_APP_API_KEY,
        }
        this.props.stateList(params);
        let item = JSON.parse(localStorage.getItem('aff'));
        this.setState({
            aff_id: item ? item.aff_id : '',
            aff_name: item ? item.aff_name : '',
            state: item ? item.stateid : '',
            aff_website: item ? item.aff_website : '',
            aff_description: item ? item.aff_description : '',
            url: item.aff_url ? item.aff_url : '',
        })
    }

    validate = () => {
        const { aff_name, aff_description, aff_website, state } = this.state;
        let errors = {};
        if (isEmpty(aff_name)) errors.aff_name = true;
        if (isEmpty(aff_description)) errors.aff_description = true;
        if (isEmpty(state)) errors.state = true;
        if (isEmpty(aff_website)) errors.aff_website = true;
        else if (!isWebsite(aff_website)) errors.aff_website = true;
        return errors;
    }

    handleSubmit = (e) => {
        e.preventDefault()
        const errors = this.validate();
        if (Object.keys(errors).length !== 0) {
            this.setState({ errors });
            return false;
        }
        const { home: { afCurrentPage } } = this.props;
        const params = {
            aff_id: this.state.aff_id,
            aff_name: this.state.aff_name,
            state: this.state.state,
            aff_website: this.state.aff_website,
            aff_description: this.state.aff_description,
            image: this.state.image,
            apikey: process.env.REACT_APP_API_KEY
        }
        this.props.addAffiliates(params, this.props.history, afCurrentPage);
    }

    render() {
        const { errors } = this.state;
        const { home: { states }, UI: { loading } } = this.props;
        let stateMarkup = states !== undefined && states.length > 0 ?
            states.map((result, i) => <option value={result.id} key={i}>{result.name}</option>) :
            <p>No Data</p>;
        return (
            <section className="main-Section">
                <form className="form-block formBox" onSubmit={this.handleSubmit}>
                    <Header
                        title={'Edit Affiliates'}
                        backUrl={'affiliates-donation'}
                        saveBtn={true}
                        loading={loading}
                    />
                    <div className="container-fluid midSection scrollSection">
                        <div className="content">
                            <fieldset>
                                <span className="placeholder">Title</span>
                                <input type="text" name="aff_name" className={`form-control input__field ${errors.aff_name ? 'inputError' : ''}`} value={this.state.aff_name} onChange={this.handleChange} />
                            </fieldset>
                            <fieldset className="selectInput redAro">
                                <span className="placeholder">State</span>
                                <select className={`form-control input__field ${errors.state ? 'inputError' : ''}`} name="state" value={this.state.state} onChange={this.handleChange}>
                                    <option value=''>Select State</option>
                                    {stateMarkup}
                                </select>
                            </fieldset>
                            <fieldset>
                                <span className="placeholder">Website</span>
                                <input type="text" name="aff_website" className={`form-control input__field ${errors.aff_website ? 'inputError' : ''}`} value={this.state.aff_website} onChange={this.handleChange} />
                            </fieldset>
                            <fieldset className="input--filled">
                                <span className="placeholder">Description</span>
                                <textarea name="aff_description" placeholder="Start Typing here" className={`form-control input__field ${errors.aff_description ? 'inputError' : ''}`} value={this.state.aff_description} onChange={this.handleChange} ></textarea>
                            </fieldset>
                            <div className="profileBrowse">
                                <div className="profileImage">
                                    {this.state.image ? (
                                        <figure><img src={this.state.image ? (`data:image/png;base64` + this.state.image) : "./images/logo.png"} alt="Fig" /></figure>
                                    ) :
                                        <figure><img src={this.state.url !== '' ? process.env.REACT_APP_API_URL + this.state.url : '../images/logo.png'} alt="Fig" /></figure>
                                    }
                                    <label className="addProfile">
                                        <img src="../images/add.png" alt="User" />
                                        <input type="file" name="image" onChange={this.handleImgChange} />
                                    </label>
                                </div>
                                <div>
                                    <h4 className={`uplodError ${errors.image ? 'upload-title' : ''}`}>Please Upload Image</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </section>
        )
    }
}

const mapStateToProps = (state) => ({
    home: state.home,
    UI: state.UI
})

const mapActionToProps = {
    addAffiliates,
    stateList
}

export default connect(mapStateToProps, mapActionToProps)(EditAffiliatesDonation);