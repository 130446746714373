import React, { Component } from 'react';
import { connect } from 'react-redux';
import { notificationList, deleteNotification } from '../../Redux/actions/userAction';
import { getToken } from '../../Helper/Session';
import moment from 'moment';
import { titleCase } from '../../Helper/Util';
import { confirmAlert } from 'react-confirm-alert';
import Header from '../Layout/Header';
import { Loader } from '../Common/Loader';
import { db } from "../Services/firebase";
import { toast } from 'react-toastify';

class Notifications extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            myChats: []
        }
    }
    notificationAction = (e, notifId, type, lat, lang, username, chatId, fromId, isDelete) => {
        e.preventDefault();
        if (type === 'Call' && isDelete === 1) {
            const userData = {
                username: username,
                fromId: fromId
            }
            this.props.history.push(`map-${lat}-${lang}`, userData)
        }
        else if (type === 'Message' && isDelete === 1) {
            e.preventDefault();
            let token = getToken() !== null ? JSON.parse(getToken()) : '';
            db.ref("Chats").child(chatId).on("value", snapshot => {
                if (snapshot.val() !== null) {
                    let cd = snapshot.val();
                    let users = cd.users;
                    let userData = JSON.parse(localStorage.getItem('chatUserMedia'));
                    let usersKeys = Object.values(users);
                    if (usersKeys.indexOf(token.id) === -1) {
                        toast.error('You left this chat!', {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        return false;
                    } else {
                        for (let i = 0; i < usersKeys.length; i++) {
                            if (usersKeys[i] !== token.id) {
                                for (let j = 0; j < userData.length; j++) {
                                    if (usersKeys[i] === userData[j].id) {
                                        cd.title = cd.title === "" ? userData[j].name : cd.title
                                        cd.img = userData[j].img
                                        localStorage.setItem('chatDetail', JSON.stringify(cd));
                                    }
                                }
                            }
                        }
                        this.props.history.push(`chat-${cd.id}`);
                    }
                } else {
                    toast.error('Chat doesn`t exist or deleted by Chat Admin!', {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            });
        }
        else if (isDelete !== 1) {
            const params = {
                notificationid: notifId,
                apikey: process.env.REACT_APP_API_KEY
            }
            confirmAlert({
                title: 'Confirm to submit',
                message: 'Are you sure you want to delete?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => {
                            this.props.deleteNotification(params);
                        }
                    },
                    {
                        label: 'No',
                    }
                ]
            });
        }
    }

    componentDidMount() {
        let token = getToken() !== null ? JSON.parse(getToken()) : '';
        const params = {
            apikey: process.env.REACT_APP_API_KEY,
            userid: token.id
        }
        this.props.notificationList(params);
    }

    render() {
        const { home: { notifications }, UI: { loading } } = this.props;
        let markup = !loading ? notifications.length > 0 ? notifications.map((result, i) =>
            <li key={i}>
                <div className="userProfile" onClick={(e) => this.notificationAction(e, result.notid, result.type, result.lat, result.lang, result.username, result.chatid, result.fromid, 1)} >
                    <figure>
                        <img src={result.url !== '' ? process.env.REACT_APP_API_URL + result.url : '../images/logo.png'} alt="Article" />
                    </figure>
                    <figcaption>
                        <h2 className="font-bold title"><span>{result.name} @{result.username}</span></h2>
                        <p className="pl-0 msg">{result.reasonforcall ? titleCase(result.reasonforcall) + ' View User`s Location' : '' + ' ' + (result.name) + ' ' + titleCase(result.description)}</p>
                        < p className="pl-0 dateTime" >
                            <span>
                                {moment.utc(result.dateadded).local().format('MM/DD/yyyy hh:mm a')}
                            </span>
                        </p >
                    </figcaption >
                </div >
                <i className="fa fa-trash" aria-hidden="true" onClick={(e) => this.notificationAction(e, result.notid)}></i>
            </li >
        ) : <p className="no-data">No Data</p> : <Loader />;
        return (
            <section className="main-Section">
                <Header
                    title={'Notifications'}
                    backUrl={'dashboard'}
                />
                <div className="container-fluid midSection scrollSection">
                    <div className="usersList notification">
                        <ul>
                            {markup}
                        </ul>
                    </div>
                </div>
            </section>
        )
    }
}

const mapStateToProps = (state) => ({
    home: state.home,
    UI: state.UI
})

const mapActionToProps = {
    notificationList,
    deleteNotification
}

export default connect(mapStateToProps, mapActionToProps)(Notifications);