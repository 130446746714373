import React, { Component } from 'react';
import { connect } from 'react-redux';
import { allPatriotsUserList } from '../../Redux/actions/homeAction';
import InfiniteScroll from "react-infinite-scroll-component";
import { Loader } from '../Common/Loader';
import InitiateFreedom from '../Common/InitiateFreedom';
import { titleCase } from '../../Helper/Util';
import Header from '../Layout/Header';

class Users extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search: '',
            page: 1,
            errors: {}
        }
    }

    componentDidMount() {
        const params = {
            apikey: process.env.REACT_APP_API_KEY,
            page: this.state.page,
            search: this.state.search ? this.state.search : ''
        }
        this.props.allPatriotsUserList(params);
    }

    fetchMoreData = () => {
        let newPage = this.state.page;
        newPage++;
        if (this.props.home.pgCurrentPage !== this.state.page) {
            newPage = this.props.home.pgCurrentPage + 1
        }
        this.setState({ page: newPage });
        const params = {
            apikey: process.env.REACT_APP_API_KEY,
            page: newPage,
            search: this.state.search ? this.state.search : ''
        }
        this.props.allPatriotsUserList(params)
    };

    searchKeyword = (e) => {
        this.setState({
            search: e.target.value,
            page: 1
        });
        const params = {
            apikey: process.env.REACT_APP_API_KEY,
            page: e.target.value !== '' ? this.state.page : 1,
            search: e.target.value
        }
        this.props.allPatriotsUserList(params)
    }

    userProfile = (e, userId) => {
        e.preventDefault();
        this.props.history.push(`user-profile-${userId}`, 1)
    }

    render() {
        const { home: { patriotUsers, patriotUsersHasMore, pgCurrentPage } } = this.props;
        let markup = patriotUsers.length > 0 ? patriotUsers.map((result, index) =>
            <li className="cursor-pointer" onClick={(e) => this.userProfile(e, result.id)} key={index}>
                <div className="userProfile">
                    <figure>
                        <img src={result.url !== '' ? process.env.REACT_APP_API_URL + result.url : '../images/logo.png'} alt="User" />
                    </figure>
                    <figcaption>
                        <h2>{result.name} @<span>{titleCase(result.username)}</span></h2>
                        <p><i className="location"></i>{result.city}, {result.state}</p>
                    </figcaption>
                </div>
            </li>
        ) : (pgCurrentPage === 1 ? <p className="no-data">No Data</p> : '');
        return (
            <section className="main-Section">
                <Header
                    title={'Users'}
                    backUrl={'dashboard'}
                />
                <div className="container-fluid midSection" style={{ height: "auto" }}>
                    <div className="content">
                        <InitiateFreedom />
                        <div className="search-box">
                            <input type="text" placeholder="Search" value={this.state.search} name="search" onChange={this.searchKeyword} className="inp" />
                        </div>
                    </div>
                    <div className="content">
                        <div className="usersList">
                            <InfiniteScroll
                                dataLength={patriotUsers.length}
                                next={this.fetchMoreData}
                                hasMore={patriotUsersHasMore}
                                loader={patriotUsers.length >= 10 ? <Loader /> : ''}
                            >
                                <ul>
                                    {markup}
                                    {pgCurrentPage === 0 ? <Loader /> : ''}
                                </ul>
                            </InfiniteScroll>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

const mapStateToProps = (state) => ({
    home: state.home,
    UI: state.UI
})

const mapActionToProps = {
    allPatriotsUserList
}

export default connect(mapStateToProps, mapActionToProps)(Users);