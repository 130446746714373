import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getToken } from './Helper/Session';

// Auth Routes

export const AdminRoute = ({ component: Component, ...rest }) => {
  const decodedToken = getToken() !== null ? JSON.parse(getToken()) : "";
  return (
    <Route
      {...rest}
      render={(props) => decodedToken && decodedToken.admin === '1' ? <Component {...props} /> : <Redirect to={{ pathname: '/', state: { from: props.location } }} />}
    />
  )
}

// User Routes

export const UserRoute = ({ component: Component, ...rest }) => {
  const decodedToken = getToken() !== null ? JSON.parse(getToken()) : "";
  return (
    <Route
      {...rest}
      render={(props) => decodedToken && decodedToken.subscriptionid !== '' ? <Component {...props} /> : <Redirect to={{ pathname: '/', state: { from: props.location } }} />}
    />
  )
}

// Is User Subscribed

export const IsUserSubscribedRoute = ({ component: Component, ...rest }) => {
  const decodedToken = getToken() !== null ? JSON.parse(getToken()) : "";
  return (
    <Route
      {...rest}
      render={(props) => decodedToken && decodedToken.subscriptionid === '' && decodedToken.subscriptionid === "" ? <Component {...props} /> : <Redirect to={{ pathname: '/', state: { from: props.location } }} />}
    />
  )
}