import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { allPatriotsUserList } from '../../Redux/actions/homeAction';
import { toast } from 'react-toastify';
import InfiniteScroll from "react-infinite-scroll-component";
import { Loader } from '../Common/Loader';
import Header from '../Layout/Header';
import { getToken } from '../../Helper/Session';

class PatriotGroupList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            owner: '',
            search: '',
            errors: {},
            page: 1,
            groupid: '',
            users: []
        }
    }

    componentDidMount() {
        let users = localStorage.getItem('grp') ? JSON.parse(localStorage.getItem('grp')) : [];
        this.setState({
            groupid: this.props.match.params.grpId,
            users: users
        })
        const params = {
            apikey: process.env.REACT_APP_API_KEY,
            page: this.state.page,
            search: this.state.search ? this.state.search : ''
        }
        this.props.allPatriotsUserList(params);
    }

    fetchMoreData = () => {
        let newPage = this.state.page;
        newPage++;
        if (this.props.home.pgCurrentPage !== this.state.page) {
            newPage = this.props.home.pgCurrentPage + 1
        }
        this.setState({ page: newPage });
        const params = {
            apikey: process.env.REACT_APP_API_KEY,
            page: newPage,
            search: this.state.search ? this.state.search : ''
        }
        this.props.allPatriotsUserList(params)
    };

    handleOwner = (e) => {
        this.setState({
            owner: e.target.value,
            errors: {
                ...this.state.errors,
                [e.target.name]: ''
            }
        });
    }

    validate = () => {
        const { users } = this.state;
        let errors = {};
        if (users.length === 0) errors.users = toast.error('Select at least One User', {
            position: toast.POSITION.TOP_RIGHT
        });
        return errors;
    }

    handleOwnerSubmit = (e) => {
        e.preventDefault()
        // const errors = this.validate();
        // if (Object.keys(errors).length !== 0) {
        //     this.setState({ errors });
        //     return false;
        // }
        const { groupid } = this.state;
        let url = groupid ? `edit-group-${groupid}` : 'add-group';
        this.props.history.push(url, this.state.users)
    }

    toggleCheckboxChange = (e, s) => {
        // let userArray = [...this.state.users];
        // if (e.target.checked) {
        //     this.setState(prevState => ({
        //         users: [...prevState.users, s]
        //     }))
        // } else {
        //     const index = userArray.indexOf(s);
        //     console.log('index',index)
        //     if (index > -1) {
        //         userArray.splice(index, 1);
        //         this.setState({ users: userArray });
        //     }
        //     // this.setState(prevState => ({
        //     //     users: [...prevState.users, s]
        //     // }))
        // }
        var array = [...this.state.users];
        var index = array.indexOf(s.id)
        if (e.target.checked) {
            if (index !== -1) {
                array.splice(index, 1);
                this.setState({ users: array });
            }
        } else {
            this.setState(prevState => ({
                users: [...prevState.users, s.id]
            }))
        }
    }

    searchKeyword = (e) => {
        this.setState({
            search: e.target.value,
            page: 1
        });
        const params = {
            apikey: process.env.REACT_APP_API_KEY,
            page: e.target.value !== '' ? this.state.page : 1,
            search: e.target.value
        }
        this.props.allPatriotsUserList(params)
    }

    render() {
        let token = getToken() !== null ? JSON.parse(getToken()) : '';
        const { home: { patriotUsers, patriotUsersHasMore, pgCurrentPage } } = this.props;
        let markup = patriotUsers.length > 0 ? patriotUsers.map((result, i) => {
            if (result.id !== token.id) {
                return (
                    <li key={i}>
                        <div className="userProfile">
                            <figure>
                                <img src={result.url !== '' ? process.env.REACT_APP_API_URL + result.url : '../images/logo.png'} alt="User" />
                            </figure>
                            <figcaption>
                                <h2>{result.name} @<span>{result.username}</span></h2>
                                <p><i className="location"></i>{result.city}, {result.state}</p>
                            </figcaption>
                        </div>
                        <div className={this.state.users.length > 0 && this.state.users.includes(result.id) === true ? 'userAddIcon added' : 'userAddIcon'}
                        >
                            <input
                                type="checkbox"
                                className='form-control'
                                id={result.id}
                                checked={this.state.users.find((u) => u.id === result.id)}
                                value={result.id}
                                onChange={(e) => this.toggleCheckboxChange(e, result)}
                            />
                        </div>
                    </li >
                )
            }
        }) : (pgCurrentPage === 1 ? <p className="no-data">No Data</p> : '');
        return (
            <section className="main-Section">
                <Header
                    title={'Patriot Group'}
                    backUrl={'dashboard'}
                />
                <div className="container-fluid midSection" style={{ height: "auto" }}>
                    <div className="content">
                        <div className="search-form">
                            <div className="search-box">
                                <input type="text" placeholder="Search User" value={this.state.search} name="search" onChange={this.searchKeyword} className="inp" />
                            </div>
                        </div>
                    </div>
                    <div className="content">
                        <div className="usersList">
                            <InfiniteScroll
                                dataLength={patriotUsers.length}
                                next={this.fetchMoreData}
                                hasMore={patriotUsersHasMore}
                                loader={patriotUsers.length >= 10 ? <Loader /> : ''}
                            >
                                <ul>
                                    {markup}
                                    {pgCurrentPage === 0 ? <Loader /> : ''}
                                </ul>
                            </InfiniteScroll>
                        </div>
                        <div className="fixedBtn">
                            <button className="btn btn-primary" onClick={(e) => this.handleOwnerSubmit(e)}>Save</button>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

const mapStateToProps = (state) => ({
    home: state.home,
    UI: state.UI
})

const mapActionToProps = {
    allPatriotsUserList
}

export default connect(mapStateToProps, mapActionToProps)(withRouter(PatriotGroupList));