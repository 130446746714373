import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getToken } from '../../Helper/Session';
import { Loader } from '../Common/Loader';
import InitiateFreedom from '../Common/InitiateFreedom';
import { getDate } from '../../Helper/Util';
import Header from '../Layout/Header';
import { getMyChats } from '../../Redux/actions/chatAction';
import { db } from "../Services/firebase";

class Chat extends Component {
    constructor(props) {
        super(props)
        this.state = {
            chats: null,
            myChats: [],
            loading: false,
            name: '',
            search: ''
        }
    }

    searchKeyword = (e) => {
        this.setState({
            search: e.target.value
        })
    }

    async componentDidMount() {
        let token = getToken() !== null ? JSON.parse(getToken()) : '';
        this.setState({ loading: true });
        try {
            db.ref("MyChats").child(token.id).on("value", snapshot => {
                let myChats = [];
                let chatIds = [];
                let userIds = [];
                let userMedia = [];
                let unreadCount = [];
                snapshot.forEach((snap) => {
                    if (snap.val() != null) {
                        myChats.push(snap.val());
                        chatIds.push(snap.val().id);
                        let users = snap.val().users !== undefined ? Object.keys(snap.val().users) : [];
                        for (let i = 0; i < users.length; i++) {
                            if (userIds.indexOf(users[i]) === -1) {
                                userIds.push(users[i]);
                            }
                        }
                    }
                });
                for (let i = 0; i < userIds.length; i++) {
                    db.ref("Users").child(userIds[i]).once('value', function (mediaSnap) {
                        if (mediaSnap.val() !== null) {
                            userMedia.push({ 'id': mediaSnap.val().id, 'name': mediaSnap.val().name, 'img': mediaSnap.val().img })
                            localStorage.setItem("userMedia", JSON.stringify(userMedia));
                            localStorage.setItem("chatUserMedia", JSON.stringify(userMedia));
                        }
                    });
                }
                if (chatIds.length > 0) {
                    for (let x = 0; x < chatIds.length; x++) {
                        db.ref("Messages").child(chatIds[x]).orderByChild("status").equalTo(false).on("value", (snapshot) => {
                            if (snapshot.val() !== null && Object.values(snapshot.val())[0].from !== token.id) {
                                if (unreadCount !== null) {
                                    const index = unreadCount.find((res) => res.id === chatIds[x]);
                                    if (index !== undefined) {
                                        if (index.id === chatIds[x]) {
                                            unreadCount.push({ 'id': chatIds[x], 'count': snapshot.numChildren() })
                                            localStorage.setItem("unreadCount", JSON.stringify(unreadCount))
                                        }
                                    } else {
                                        unreadCount.push({ 'id': chatIds[x], 'count': snapshot.numChildren() })
                                        localStorage.setItem("unreadCount", JSON.stringify(unreadCount))
                                    }
                                }
                            } else {
                                localStorage.setItem("unreadCount", JSON.stringify(unreadCount))
                            }
                        });
                    }
                }
                let userData = JSON.parse(localStorage.getItem('chatUserMedia'));
                let unreadData = JSON.parse(localStorage.getItem('unreadCount'));
                if (userData !== null) {
                    for (let j = 0; j < myChats.length; j++) {
                        if (!myChats[j].title) {
                            let usersKeys = Object.values(myChats[j].users);
                            for (let l = 0; l < usersKeys.length; l++) {
                                if (usersKeys[l] !== token.id) {
                                    for (let k = 0; k < userData.length; k++) {
                                        if (usersKeys[l] === userData[k].id) {
                                            myChats[j].title = userData[k].name
                                            myChats[j].img = userData[k].img
                                        }
                                    }
                                    if (unreadData !== null) {
                                        for (let k = 0; k < unreadData.length; k++) {
                                            if (myChats[j].id === unreadData[k].id) {
                                                myChats[j].count = unreadData[k].count
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                myChats.sort(function (a, b) { return b.lastmessagedate - a.lastmessagedate })
                this.setState({ myChats, loading: false });
            });
        } catch (error) {
            this.setState({ loading: false });
        }
    }

    setChatDetail = (e, result) => {
        e.preventDefault();
        localStorage.setItem('chatDetail', JSON.stringify(result));
        let token = getToken() !== null ? JSON.parse(getToken()) : '';
        let users = JSON.parse(localStorage.getItem('chatDetail')).users;
        let userData = JSON.parse(localStorage.getItem('chatUserMedia'));
        let usersKeys = Object.values(users);
        let usersArry = [];
        for (let i = 0; i < usersKeys.length; i++) {
            if (usersKeys[i] !== token.id) {
                for (let j = 0; j < userData.length; j++) {
                    if (usersKeys[i] === userData[j].id) {
                        usersArry.push(userData[j]);
                        localStorage.setItem("userMedia", JSON.stringify(usersArry))
                    }
                }
            }
        }
        this.props.history.push(`chat-${result.id}`);
    }

    render() {
        const { myChats, loading } = this.state;
        const myChat = myChats.filter(result => {
            return result.title.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1;
        });
        let markup = !loading ? (myChat.length > 0 ? myChat.map((result, key) =>
            <li key={key} onClick={(e) => this.setChatDetail(e, result)} className={`cursor-pointer ${result.count ? `unread` : ''}`}>
                <div className="userProfile">
                    <figure><img src={result.url ? result.url : (result.img ? process.env.REACT_APP_API_URL + result.img : '../images/logo.png')} alt="User" /></figure>
                    <figcaption>
                        <h2 className="fx-jcsb-ac">
                            {result.title ? result.title : 'Unknown User'}
                        </h2>
                        <p className="pl-0">{result.lastmessage}</p>
                    </figcaption>
                </div>
                <div className="rightBlock">
                    {result.count > 0 &&
                        <h5 className="chatCounter">{result.count}</h5>
                    }
                    <h5 className="size13 date">{result.lastmessagedate ? getDate(result.lastmessagedate) : ''}</h5>
                    <span>
                        <img src="../images/aro-detail.svg" alt="arrow" />
                    </span>
                </div>
            </li>
        ) : <p className="no-data">No Chat records.
            <Link to="users-to-chat" className="cmn-btn documentBtn">
                Click to continue...
            </Link>
        </p>) : <Loader />;
        return (
            <section className="main-Section">
                <Header
                    title={'Chat'}
                    backUrl={'dashboard'}
                />
                <div className="container-fluid midSection scrollSection">
                    <div className="content">
                        <InitiateFreedom />
                        <div className="search-box">
                            <input type="text" placeholder="Keywords Search" value={this.state.search} name="search" onChange={this.searchKeyword} className="inp" />
                        </div>
                        <div className="post-container">
                            <div className="usersList">
                                <ul>
                                    {markup}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="action-btn">
                    <Link to="users-to-chat" className="cmn-btn documentBtn">
                        <img src="../images/chat-icon.svg" alt="icon" />
                    </Link>
                </div>
            </section>
        )
    }
}

const mapStateToProps = (state) => ({
    UI: state.UI,
    chat: state.chat
})

const mapActionToProps = {
    getMyChats
}

export default connect(mapStateToProps, mapActionToProps)(withRouter(Chat));