import React, { Component } from 'react';
import { toast } from "react-toastify";
import { Link } from 'react-router-dom';
import axios from "../../Helper/Axios";
import { inputPlaceHolders, isEmpty, isEmail } from '../../Helper/Util';

class ForgotPassword extends Component {
    constructor(props) {
        super(props)
        this.state = this.getInitialState();
    }

    getInitialState = () => ({
        email: '',
        errors: {},
        loading: false
    });

    componentDidMount() {
        inputPlaceHolders()
    }

    validate = () => {
        const { email } = this.state;
        let errors = {};
        if (isEmpty(email)) errors.email = true;
        if (!isEmail(email)) errors.email = true;
        return errors;
    }

    handleChange = (e) => {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value,
            errors: {
                ...this.state.errors,
                [e.target.name]: ''
            }
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const errors = this.validate();
        if (Object.keys(errors).length !== 0) {
            this.setState({ errors });
            return false;
        }
        this.setState({ loading: true });
        const params = {
            "email": this.state.email
        }
        axios.post('https://gotyour6app.online/forget/public/api/v1/forgetpass', params)
            .then(res => {
                if (res.data.status === true) {
                    this.setState({ loading: false });
                    this.setState(this.getInitialState());
                    toast.success(res.data.message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                } else {
                    this.setState({ loading: false });
                    toast.error(res.data.message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            }).catch(error => {
                this.setState({ loading: false });
                if (error.response.status !== true) {
                    toast.error(error.response.data.message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            });
    }
    render() {
        const { errors, loading } = this.state;
        return (
            <section className="main-Section">
                <header className="header">
                    <div className="container-fluid">
                        <div className="header-section">
                            <Link className="header-section__back" to="login">
                                <img src="../images/back.svg" alt="Back" />
                            </Link>
                            <h2 className="page-title">Forgot Password</h2>
                        </div>
                    </div>
                </header>
                <div className="container-fluid midSection scrollSection">
                    <div className="content login">
                        <div className="forgotPasswordImage">
                            <figure>
                                <img src="./images/logo.png" alt="Logo" />
                            </figure>
                            <p className="text">Enter your email address and we will sent you instructions to reset your password</p>
                        </div>
                        <form autoComplete="off" onSubmit={this.handleSubmit}>
                            <div className="form-block formBox">
                                <div className="form-block formBox">
                                    <fieldset>
                                        <span className="placeholder">Email Address</span>
                                        <input className={`form-control input__field ${errors.email ? 'inputError' : ''}`} name="email" type="text" value={this.state.email} onChange={this.handleChange} />
                                    </fieldset>
                                </div>
                            </div>
                            <input type="submit" className="btn btn-primary" disabled={loading ? 'disabled' : ''} value={this.state.loading ? 'Loading...' : 'Continue'} /><br />
                        </form>
                    </div>
                </div>
            </section>
        )
    }
}
export default ForgotPassword;