import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addLegal } from '../../Redux/actions/homeAction';
import { stateList } from '../../Redux/actions/userAction';
import { inputPlaceHolders, isEmpty, isEmail, isMobile, isWebsite } from "../../Helper/Util";
import Header from '../Layout/Header';

class AddLegelCounsel extends Component {
    constructor(props) {
        super(props)
        this.state = {
            legal_name: '',
            state: '',
            legal_email: '',
            legal_phone: '',
            legal_website: '',
            legal_description: '',
            image: null,
            height: 0,
            width: 0,
            errors: {}
        }
    }

    handleChange = e => {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value,
            errors: {
                ...this.state.errors,
                [e.target.name]: ''
            }
        })
    }

    handleImgChange = (e) => {
        var file = e.target.files[0];
        let reader = new FileReader();
        reader.onload = (e) => {
            let images = e.target.result;
            this.setState({
                image: images.split(';base64')[1],
                errors: {
                    image: ''
                }
            });
        };
        reader.readAsDataURL(file);
    };

    componentDidMount() {
        const params = {
            apikey: process.env.REACT_APP_API_KEY,
        }
        this.props.stateList(params);
        inputPlaceHolders();
    }

    validate = () => {
        const { legal_name, legal_phone, legal_email, legal_website, legal_description, state, image } = this.state;
        let errors = {};
        if (isEmpty(legal_name)) errors.legal_name = true;
        if (isEmpty(legal_phone)) errors.legal_phone = true;
        else if (!isMobile(legal_phone)) errors.legal_phone = true;
        if (isEmpty(legal_description)) errors.legal_description = true;
        if (isEmpty(legal_website)) errors.legal_website = true;
        else if (!isWebsite(legal_website)) errors.legal_website = true;
        if (isEmpty(legal_email)) errors.legal_email = true;
        else if (!isEmail(legal_email)) errors.legal_email = true;
        if (isEmpty(state)) errors.state = true;
        if (image === null) errors.image = true;
        return errors;
    }

    handleSubmit = (e) => {
        e.preventDefault()
        const errors = this.validate();
        if (Object.keys(errors).length !== 0) {
            this.setState({ errors });
            return false;
        }
        const { home: { leCurrentPage } } = this.props;
        const params = {
            legal_name: this.state.legal_name,
            legal_phone: this.state.legal_phone,
            legal_email: this.state.legal_email,
            legal_description: this.state.legal_description,
            legal_website: this.state.legal_website,
            state: this.state.state,
            image: this.state.image,
            apikey: process.env.REACT_APP_API_KEY
        }
        this.props.addLegal(params, this.props.history, leCurrentPage);
    }

    render() {
        const { errors } = this.state;
        const { home: { states }, UI: { loading } } = this.props;
        let stateMarkup = states !== undefined && states.length > 0 ?
            states.map((result, i) => <option value={result.id} key={i}>{result.name}</option>) :
            <p>No Data</p>;
        return (
            <section className="main-Section">
                <form className="form-block formBox" onSubmit={this.handleSubmit}>
                    <Header
                        title={'Add Legal Counsel'}
                        backUrl={'legel-counsel'}
                        saveBtn={true}
                        loading={loading}
                    />
                    <div className="container-fluid midSection scrollSection">
                        <div className="content">
                            <fieldset>
                                <span className="placeholder">Name</span>
                                <input type="text" name="legal_name" className={`form-control input__field ${errors.legal_name ? 'inputError' : ''}`} value={this.state.legal_name} onChange={this.handleChange} />
                            </fieldset>
                            <fieldset className="selectInput redAro">
                                <span className="placeholder">State</span>
                                <select className={`form-control input__field ${errors.state ? 'inputError' : ''}`} name="state" value={this.state.state} onChange={this.handleChange}>
                                    <option value=''>Select State</option>
                                    {stateMarkup}
                                </select>
                            </fieldset>
                            <fieldset>
                                <span className="placeholder">Email</span>
                                <input type="text" name="legal_email" className={`form-control input__field ${errors.legal_email ? 'inputError' : ''}`} value={this.state.legal_email} onChange={this.handleChange} />
                            </fieldset>
                            <fieldset>
                                <span className="placeholder">Phone</span>
                                <input type="text" name="legal_phone" className={`form-control input__field ${errors.legal_phone ? 'inputError' : ''}`} value={this.state.legal_phone} onChange={this.handleChange} />
                            </fieldset>
                            <fieldset>
                                <span className="placeholder">Website</span>
                                <input type="text" name="legal_website" className={`form-control input__field ${errors.legal_website ? 'inputError' : ''}`} value={this.state.legal_website} onChange={this.handleChange} />
                            </fieldset>
                            <fieldset className="input--filled">
                                <span className="placeholder">Description</span>
                                <textarea name="legal_description" placeholder="Start Typing here" className={`form-control input__field ${errors.legal_description ? 'inputError' : ''}`} value={this.state.legal_description} onChange={this.handleChange} ></textarea>
                            </fieldset>
                            <div className="profileBrowse">
                                <div className="profileImage">
                                    <figure>
                                        <img src={this.state.image ? (`data:image/png;base64` + this.state.image) : "../images/profile-pic.png"} alt="Fig" />
                                    </figure>
                                    <label className="addProfile">
                                        <img src="../images/add.png" alt="User" />
                                        <input type="file" name="image" onChange={this.handleImgChange} />
                                    </label>
                                </div>
                                <div>
                                    <h4 className={`uplodError ${errors.image ? 'upload-title' : ''}`}>Please Upload Image</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </section>
        )
    }
}

const mapStateToProps = (state) => ({
    home: state.home,
    UI: state.UI
})

const mapActionToProps = {
    addLegal,
    stateList
}

export default connect(mapStateToProps, mapActionToProps)(AddLegelCounsel);