import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Home extends Component {

    render() {
        return (
            <>
                <section className="main-container">
                    <div className="container-fluid center-block splace-screen">
                        <div className="logo">
                            <Link to="login">
                                <img src="./images/logo.png" alt="Fig" />
                            </Link>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

export default Home;