import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getUserDetail, flagUser } from '../../Redux/actions/homeAction';
import { deleteUser } from '../../Redux/actions/userAction';
import { addNewChat, getMyChats } from '../../Redux/actions/chatAction';
import { getToken } from '../../Helper/Session';
import { db } from "../Services/firebase";
import { titleCase } from '../../Helper/Util';
import { confirmAlert } from 'react-confirm-alert';
import Header from '../Layout/Header';
import { Loader } from '../Common/Loader';

class MemberDetail extends Component {

    componentDidMount() {
        let token = getToken() !== null ? JSON.parse(getToken()) : '';
        const params = {
            apikey: process.env.REACT_APP_API_KEY,
            userid: this.props.match.params.id
        }
        this.props.getUserDetail(params, this.props.history);
        this.props.getMyChats(token.id);
    }

    handleFlag = (e, userId) => {
        e.preventDefault();
        let token = getToken() !== null ? JSON.parse(getToken()) : '';
        const params = {
            user_id: userId,
            fromid: token.id,
            apikey: process.env.REACT_APP_API_KEY
        }
        this.props.flagUser(params);
    }

    handleDelete = (e, userId) => {
        e.preventDefault();
        const params = {
            userid: userId,
            apikey: process.env.REACT_APP_API_KEY
        }
        confirmAlert({
            title: 'Confirm to submit',
            message: 'Are you sure you want to delete?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        this.props.deleteUser(params, this.props.history);
                    }
                },
                {
                    label: 'No',
                }
            ]
        });
    }

    handleChat = (e, toUser) => {
        e.preventDefault()
        let token = getToken() !== null ? JSON.parse(getToken()) : '';
        let users = [toUser, token];
        let userObj = {};
        let unreadObj = {};
        for (let i = 0; i < users.length; i++) {
            userObj[users[i].id] = users[i].id;
            unreadObj['unread' + users[i].id] = 0;
        }
        const { chat: { myChats } } = this.props;
        let myRef = db.ref().push();
        const params = {
            authUser: token.id,
            id: myRef.key,
            title: '',
            url: '',
            unreadObj: 0,
            users: userObj
        }
        if (myChats.length > 0) {
            for (let i = 0; i < myChats.length; i++) {
                if (JSON.stringify(myChats[i].users) === JSON.stringify(userObj) && myChats[i].url === '') {
                    this.props.history.push(`chat-${myChats[i].id}`);
                    localStorage.setItem('chatDetail', JSON.stringify(myChats[i]));
                    return false
                }
            }
            this.props.addNewChat(params, users);
            this.props.history.push(`chat-${myRef.key}`);
        } else {
            this.props.addNewChat(params, users);
            this.props.history.push(`chat-${myRef.key}`);
        }
    }

    render() {
        const { home: { userDetail }, UI: { loading } } = this.props;
        let backUrl = 'users';
        let token = getToken() !== null ? JSON.parse(getToken()) : '';
        if (this.props.location.state === 2) {
            backUrl = 'media'
        }
        else if (this.props.location.state === 3) {
            backUrl = 'current-events'
        }
        else if (this.props.location.state === 4) {
            backUrl = 'articles-legislation'
        }
        else if (this.props.location.state === 5) {
            backUrl = 'affiliates-donation'
        }
        return (
            <section className="main-Section">
                <Header
                    title={!loading ? userDetail !== undefined ? titleCase(userDetail.username) : '' : <Loader />}
                    backUrl={backUrl}
                />
                <div className="container-fluid midSection scrollSection userListCon">
                    <div className="content bg-gray">
                        <div className="memberDetailPage">
                            {!loading ?
                                <>
                                    <div className="memberDetailPage__inside">
                                        <div className="memberDetailPage__inside__info">
                                            {token.id === userDetail.id ?
                                                <a href="update-profile" className={token.id === userDetail.id ? "flatUser edit" : "flatUser"}></a>
                                                :
                                                <button className="flatUser" onClick={(e) => this.handleFlag(e, userDetail !== undefined ? userDetail.id : 0)}></button>
                                            }
                                            <figure className="memberDetailPage__inside__info__logo">
                                                <img src={userDetail !== undefined && userDetail.url !== '' ? process.env.REACT_APP_API_URL + userDetail.url : '../images/logo.png'} alt={userDetail.name} />
                                            </figure>
                                            <h5>{userDetail !== undefined ? userDetail.name : ''}</h5>
                                            <p>@{userDetail !== undefined ? titleCase(userDetail.username) : ''}</p>
                                            {this.props.location.state === 1 &&
                                                <p><i className="fas fa-map-marker-alt"></i> {userDetail.address + userDetail.city}, {userDetail.state}</p>
                                            }
                                            {token.id !== userDetail.id ?
                                                <div className="memberDetailPage__inside__info__chat">
                                                    <i className="line mr-2"></i>
                                                    <span>
                                                        <a href={`tel:${userDetail !== undefined ? userDetail.phone : ''}`} className="iconx"><img src="../images/call.svg" alt="Call" /></a>
                                                    </span>
                                                    <i className="dot-ui ml-2"></i>
                                                    <button className="chatBtn" onClick={(e) => this.handleChat(e, userDetail)}></button>
                                                    <i className="dot-ui mr-2"></i>
                                                    <span>
                                                        <a href={`mailto::${userDetail !== undefined ? userDetail.email : ''}`} className="iconx"><img src="../images/email.svg" alt="Email" /></a>
                                                    </span>
                                                    <i className="line ml-2"></i>
                                                </div> : ''
                                            }
                                        </div>
                                    </div>
                                    {this.props.location.state === 1 &&
                                        <div className="deleteUserBtn">
                                            <button className="btn btn-primary" onClick={(e) => this.handleDelete(e, userDetail.id)}>Delete User</button>
                                        </div>
                                    }
                                </>
                                : <Loader />
                            }
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

const mapStateToProps = (state) => ({
    home: state.home,
    chat: state.chat,
    UI: state.UI
})

const mapActionToProps = {
    getUserDetail,
    deleteUser,
    flagUser,
    addNewChat,
    getMyChats
}
export default connect(mapStateToProps, mapActionToProps)(withRouter(MemberDetail));