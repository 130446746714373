import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import user from './reducers/user';
import home from './reducers/home';
import ui from './reducers/ui';
import chat from './reducers/chat';

const initialState = {};
const middleware = [thunk]

const reducers = combineReducers({
    user: user,
    home: home,
    chat: chat,
    UI: ui
});

const store = createStore(
    reducers,
    initialState,
    compose(
        applyMiddleware(...middleware),
        window.__REDUX_DEVTOOLS_EXTENSION__
            ? window.__REDUX_DEVTOOLS_EXTENSION__()
            : f => f)
);

export default store;