import firebase from 'firebase';
import 'firebase/messaging';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    databaseURL: process.env.REACT_APP_FIREBASE_DB_URL,
    authDomain: "got-your-6.firebaseapp.com",
    projectId: 'got-your-6',
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: 142617001940,
    appId: '1:142617001940:android:40f483bb8607b8f8a0e041'
}
// Initialize Firebase
if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}
export const db = firebase.database();
export const storage = firebase.storage();
export let messaging = new Promise((rej, res) => { });
if (firebase.messaging.isSupported()) {
    messaging = firebase.messaging();
} else {
    // dummy methods on messaging object
    messaging.getToken = () => { };
    messaging.onMessage = () => { };
}
