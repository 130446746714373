// user reducer types
export const SET_AUTHENTICATED = 'SET_AUTHENTICATED'
export const SET_UNAUTHENTICATED = 'SET_UNAUTHENTICATED'
export const SET_USER = 'SET_USER'
export const LOADING_USER = 'LOADING_USER'

// UI reducer types
export const SET_ERRORS = 'SET_ERRORS'
export const LOADING_UI = 'Loading...'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'
export const LOADING_DATA = 'LOADING_DATA'

// Users
export const SET_STATES = 'SET_STATES'
export const SET_USERS = 'SET_USERS'
export const SET_USER_DETAIL = 'SET_USER_DETAIL'
export const SET_USER_PAGER = 'SET_USER_PAGER'
export const USER_DETAIL = 'USER_DETAIL'
export const SET_CARDS = 'SET_CARDS'
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS'
export const UNREAD_NOTIFICATIONS = 'UNREAD_NOTIFICATIONS'

// Home
export const SET_LEGAL = 'SET_LEGAL'
export const SET_AFFILIATE = 'SET_AFFILIATE'
export const SET_EVENT = 'SET_EVENT'
export const DELETE_EVENT = 'DELETE_EVENT'
export const SET_ARTICLE = 'SET_ARTICLE'
export const DELETE_ARTICLE = 'DELETE_ARTICLE'
export const SET_MEDIA = 'SET_MEDIA'
export const DELETE_MEDIA = 'DELETE_MEDIA'
export const ARTICLE_DETAIL = 'ARTICLE_DETAIL'
export const MEDIA_DETAIL = 'MEDIA_DETAIL'

export const ADD_GROUP = 'ADD_GROUP'
export const SET_GROUP = 'SET_GROUP'
export const GRP_REQUEST = 'GRP_REQUEST'
export const SET_PATRIOT_USER = 'SET_PATRIOT_USER'
export const SET_MY_GROUP = 'SET_MY_GROUP'
export const SET_REQUESTED_LIST = 'SET_REQUESTED_LIST'

export const SET_FLAGGED = 'SET_FLAGGED'
export const SET_PENDING_ARTICLE = 'SET_PENDING_ARTICLE'
export const SET_PENDING_MEDIA = 'SET_PENDING_MEDIA'
export const SET_PENDING_EVENT = 'SET_PENDING_EVENT'
export const CHANGE_POST_STATUS = 'CHANGE_POST_STATUS'
export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION'
export const GROUP_ACTION = 'GROUP_ACTION'
export const DELETE_USER = 'DELETE_USER'
export const DELETE_AFF = 'DELETE_AFF'
export const DELETE_LEGAL = 'DELETE_LEGAL'


export const MY_CHATS = 'MY_CHATS'
export const MY_CHAT_MSGS = 'MY_CHAT_MSGS'
export const CHAT_DETAIL = 'CHAT_DETAIL'
export const DELETE_DETAIL = 'DELETE_DETAIL'