import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { inputPlaceHolders, isEmpty } from "../../Helper/Util";
import { createGroup } from '../../Redux/actions/homeAction';
import { getToken } from '../../Helper/Session';
import Header from '../Layout/Header';

class AddGroup extends Component {
    constructor(props) {
        super(props)
        this.state = {
            errors: {},
            groupname: '',
            image: null,
            loading: false,
        }
    }

    componentDidMount() {
        inputPlaceHolders();
    }

    handleChange = e => {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value,
            errors: {
                ...this.state.errors,
                [e.target.name]: ''
            }
        })
    }

    handleImgChange = (e) => {
        var file = e.target.files[0];
        let reader = new FileReader();
        reader.onload = (e) => {
            let images = e.target.result;
            let img = new Image();
            img.src = images
            img.onload = () => {
                let canvas = document.createElement('canvas')
                const MAX_WIDTH = 400
                const MAX_HEIGHT = 350
                let width = img.width
                let height = img.height
                if (width > height) {
                    if (width > MAX_WIDTH) {
                        height *= MAX_WIDTH / width
                        width = MAX_WIDTH
                    }
                } else {
                    if (height > MAX_HEIGHT) {
                        width *= MAX_HEIGHT / height
                        height = MAX_HEIGHT
                    }
                }
                canvas.width = width
                canvas.height = height
                let ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0, width, height);
                this.setState({
                    image: canvas.toDataURL().split(';base64')[1],
                    errors: {
                        image: ''
                    }
                });
            }
        };
        reader.readAsDataURL(file);
    };

    validate = () => {
        const { groupname, image } = this.state;
        let errors = {};
        if (isEmpty(groupname)) errors.groupname = true;
        if (image === null) errors.image = true;
        return errors;
    }

    handleSubmit = (e) => {
        e.preventDefault()
        let token = getToken() !== null ? JSON.parse(getToken()) : '';
        const errors = this.validate();
        if (Object.keys(errors).length !== 0) {
            this.setState({ errors });
            return false;
        }
        let users = this.props.location.state !== undefined ? this.props.location.state : [];
        const params = {
            groupname: this.state.groupname,
            image: this.state.image,
            owner: parseInt(token.id),
            apikey: process.env.REACT_APP_API_KEY,
            users: [...users, parseInt(token.id)]
        }
        this.props.createGroup(params, this.props.history);
    }

    render() {
        const { errors } = this.state;
        const { UI: { loading } } = this.props;
        return (
            <section className="main-Section">
                <Header
                    title={'Patriot Group'}
                    backUrl={`create-patriot-group`}
                />
                <div className="container-fluid midSection scrollSection edit-patriotCon">
                    <div className="content">
                        <div className="post-container">
                            <div className="edit-group">
                                <form className="form-block formBox" onSubmit={this.handleSubmit}>
                                    <h4 className="size20 font-bold mb-25">What's Your group name?</h4>
                                    <div className="profileBrowse justify-content-center">
                                        <div className="profileImage">
                                            <figure>
                                                <img src={this.state.image ? (`data:image/png;base64` + this.state.image) : "../images/profile-pic.png"} alt="Fig" />
                                            </figure>
                                            <label className="addProfile">
                                                <img src="../images/add.png" alt="User" />
                                                <input type="file" name="image" onChange={this.handleImgChange} />
                                            </label>
                                        </div>
                                        <div>
                                            <h4 className={`uplodError ${errors.image ? 'upload-title' : ''}`}>Please Upload Image</h4>
                                        </div>
                                    </div>
                                    <div className="form-block formBox">
                                        <fieldset>
                                            <span className="placeholder">Group Name</span>
                                            <input type="text" name="groupname" className={`form-control input__field ${errors.groupname ? 'inputError' : ''}`} value={this.state.groupname} onChange={this.handleChange} />
                                        </fieldset>
                                    </div>
                                    <input type="submit" disabled={loading ? 'disabled' : ''} className="btn btn-primary" value={loading ? 'Loading...' : 'Save'} />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

const mapStateToProps = (state) => ({
    UI: state.UI
})

const mapActionToProps = {
    createGroup
}
export default connect(mapStateToProps, mapActionToProps)(withRouter(AddGroup));