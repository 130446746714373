import { SET_UNAUTHENTICATED, MY_CHAT_MSGS, MY_CHATS, CHAT_DETAIL, DELETE_DETAIL } from '../types'

const initialState = {
    loading: false,
    myChats: [],
    myChatMsgs: [],
    chatDetail: {}
}

export default function chat(state = initialState, action) {
    switch (action.type) {
        case SET_UNAUTHENTICATED:
            return initialState;
        case MY_CHATS:
            let userData = action.userData;
            let payload = action.payload;
            for (let i = 0; i < userData.length; i++) {
                let userKey = userData[i].id;
                let title = userData[i].name;
                let img = userData[i].img;
                for (let j = 0; j < payload.length; j++) {
                    let payloadUsersKey = Object.keys(payload[j].users);
                    if (payloadUsersKey.length === 2) {
                        if (payloadUsersKey.indexOf(userKey) > -1) {
                            payload[j].title = payload[j].title ? payload[j].title : title;
                            payload[j].img = img;
                        }
                    }
                }
            }
            payload.sort(function (a, b) { return b.lastmessagedate - a.lastmessagedate })
            return {
                ...state,
                myChats: action.payload,
                loading: false
            }
        case MY_CHAT_MSGS:
            let uArray = action.userData;
            let mPayload = action.payload;
            for (let i = 0; i < uArray.length; i++) {
                let userKey = uArray[i].id;
                let title = uArray[i].name;
                let img = uArray[i].img;
                for (let j = 0; j < mPayload.length; j++) {
                    if (mPayload[j].from === userKey) {
                        mPayload[j].name = title;
                        mPayload[j].img = img;
                    }
                }
            }
            mPayload.sort(function (a, b) { return b.time - a.time })
            return {
                ...state,
                myChatMsgs: mPayload,
                loading: false
            }
        case CHAT_DETAIL:
            let userDetail = action.userData;
            let cPayload = action.payload;
            let title = cPayload.title ? cPayload.title : (userDetail !== undefined ? userDetail.name : '');
            cPayload.title = title;
            return {
                ...state,
                chatDetail: action.payload,
                loading: false
            }
        case DELETE_DETAIL:
            return {
                ...state,
                myChats: state.myChats.filter(res => res.id !== action.payload),
                loading: false
            }
        default:
            return state;
    }
}