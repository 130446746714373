import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { eventList, pendingEventList, filterEvents, flagEvent, deleteEvent, changePostStatus } from '../../Redux/actions/homeAction';
import { getToken } from '../../Helper/Session';
import InitiateFreedom from '../Common/InitiateFreedom';
import { types } from "../../Helper/Constant";
import InfiniteScroll from "react-infinite-scroll-component";
import { currentTime } from "../../Helper/Util";
import Header from '../Layout/Header';
import { Loader } from '../Common/Loader';
import { confirmAlert } from 'react-confirm-alert';

class CurrentEvents extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: '',
            description: '',
            eventId: '',
            moreOpened: false,
            moreOpenedPending: false,
            search: '',
            type: '',
            page: 1,
            page_: 1
        }
    }

    componentDidMount() {
        const params = {
            apikey: process.env.REACT_APP_API_KEY,
            page: this.state.page,
            currenttimestamp: currentTime()
        }
        if (!this.props.home.events.length) {
            this.props.eventList(params);
        }
    }

    getDetail = (e, name, description) => {
        e.preventDefault();
        this.setState({
            name: name,
            description: description,
        })
    }

    handleFlag = (e, eventId) => {
        e.preventDefault();
        let token = getToken() !== null ? JSON.parse(getToken()) : '';
        const params = {
            event_id: eventId,
            fromid: token.id,
            apikey: process.env.REACT_APP_API_KEY
        }
        this.props.flagEvent(params);
        this.setState({
            moreOpened: !this.state.moreOpened,
        });
    }

    moreOpenedToggle = (e, eventId, result) => {
        e.preventDefault();
        if (result !== undefined) {
            localStorage.setItem('event', JSON.stringify(result))
        } else {
            localStorage.removeItem('event')
        }
        this.setState({
            moreOpened: !this.state.moreOpened,
            eventId: eventId
        });
    }

    moreOpenedTogglePending = (e, eventId, result) => {
        e.preventDefault();
        if (result !== undefined) {
            localStorage.setItem('event', JSON.stringify(result))
        } else {
            localStorage.removeItem('event')
        }
        this.setState({
            moreOpenedPending: !this.state.moreOpenedPending,
            eventId: eventId
        });
    }

    searchKeyword = (e) => {
        this.setState({
            search: e.target.value
        })
    }

    searchType = (e) => {
        this.setState({
            type: e.target.value,
        })
        // const params = {
        //     type: e.target.value,
        //     searchstring: this.state.search,
        //     currenttimestamp: currentTime(),
        //     apikey: process.env.REACT_APP_API_KEY,
        //     page: this.state.page,
        // }
        // this.props.filterEvents(params)
    }

    fetchMoreData = () => {
        let newPage = this.state.page;
        newPage++;
        if (this.props.home.eCurrentPage !== this.state.page) {
            newPage = this.props.home.eCurrentPage + 1
        }
        this.setState({ page: newPage });
        const params = {
            apikey: process.env.REACT_APP_API_KEY,
            page: newPage,
            currenttimestamp: currentTime()
        }
        this.props.eventList(params)
    };

    fetchMorePendingData = () => {
        let newPage_ = this.state.page_;
        newPage_++;
        this.setState({ page: newPage_ });
        const params = {
            apikey: process.env.REACT_APP_API_KEY,
            page: newPage_,
            currenttimestamp: currentTime()
        }
        this.props.eventList(params)
    };

    handleDelete = (e, eventId) => {
        e.preventDefault();
        const params = {
            event_id: eventId,
            apikey: process.env.REACT_APP_API_KEY
        }
        confirmAlert({
            title: 'Confirm to submit',
            message: 'Are you sure you want to delete?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        this.props.deleteEvent(params);
                    }
                },
                {
                    label: 'No',
                }
            ]
        });
        this.setState({
            moreOpened: !this.state.moreOpened,
        });
    }

    getEventPosts = (e, type) => {
        this.setState({
            moreOpened: false,
            moreOpenedPending: false,
            type: ''
        });
        if (type === 1) {
            const params = {
                apikey: process.env.REACT_APP_API_KEY,
                page: this.state.page,
                status: 'Approved',
                currenttimestamp: currentTime()
            }
            this.props.eventList(params);
        } else {
            const params_ = {
                apikey: process.env.REACT_APP_API_KEY,
                page: this.state.page_,
                status: 'Pending',
                currenttimestamp: currentTime()
            }
            this.props.pendingEventList(params_);
        }
    }

    handleApproveDeny = (e, eventId, status) => {
        e.preventDefault();
        const params = {
            event_id: eventId,
            status: status,
            apikey: process.env.REACT_APP_API_KEY
        }
        this.props.changePostStatus(params, 2);
        this.setState({
            moreOpenedPending: !this.state.moreOpenedPending,
        });
    }

    userProfile = (e, userId) => {
        e.preventDefault();
        this.props.history.push(`user-profile-${userId}`, 3)
    }

    render() {
        const { home: { events, eventHasMore, pendingEvents, pendingEventHasMore }, UI: { loading }, user: { user } } = this.props;
        let event;
        event = events.filter(result => {
            return result.event_title.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1;
        });

        if (this.state.type) {
            if (this.state.search) {
                event = events.filter(result => {
                    return (
                        result.event_title.toLowerCase().indexOf(this.state.search) !== -1 &&
                        result.type.toLowerCase().indexOf(this.state.type) !== -1
                    );
                });
            } else {
                event = events.filter(result => {
                    return result.type.toLowerCase().indexOf(this.state.type.toLowerCase()) !== -1;
                });
            }
        }

        if (this.state.search) {
            event = events.filter(result => {
                return result.event_title.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1;
            });
        }

        let pendingEvent;
        pendingEvent = pendingEvents.filter(result => {
            return result.event_title.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1;
        });

        if (this.state.type) {
            pendingEvent = pendingEvents.filter(result => {
                return result.type.toLowerCase().indexOf(this.state.type.toLowerCase()) !== -1;
            });
        }
        if (this.state.search) {
            pendingEvent = pendingEvents.filter(result => {
                return result.event_title.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1;
            });
        }
        let posted = events.length > 0 ? event.length > 0 ? event.map((result, index) => {
            return (
                <li key={index}>
                    <div className="userProfile">
                        <figure>
                            <img src={result.images !== null ? result.images[0] !== null ? process.env.REACT_APP_API_URL + result.images[0].url : '../images/logo.png' : '../images/logo.png'} alt="Event" />
                        </figure>
                        <figcaption>
                            <h2>{result.event_title}</h2>
                            <p><i className="location"></i> {result.address} {result.city} {result.statename},{result.zip} USA</p>
                            <p> {result.startdate}</p>
                            <p>{result.starttime} to {result.endtime} | <Link data-toggle="modal" data-target="#infoModal" onClick={(e) => this.getDetail(e, result.event_title, result.event_description)}>Learn More</Link></p>
                        </figcaption>
                    </div>
                    <figure className="rightUserImg mt-3" onClick={(e) => this.userProfile(e, result.user.id)}>
                        <img src={result.user !== null ? result.user.url !== "" ? process.env.REACT_APP_API_URL + result.user.url : '../images/logo.png' : '../images/logo.png'} alt="User" />
                    </figure>
                    <div className="addGroup moreOpt absoluteUI">
                        <Link to="" onClick={(e) => this.moreOpenedToggle(e, result.eventid, result)} >
                            <span className="more_opt_v" >
                                <img src="../images/more-dot-v.svg" alt="Dot" />
                            </span>
                        </Link>
                    </div>
                </li>
            )
        }) : <p className="no-data">No Data</p> : <Loader />;

        let pending = pendingEvent.length > 0 ? pendingEvent.map((result, index) => {
            return (
                <li key={index}>
                    <div className="userProfile">
                        <figure>
                            <img src={result.images !== null ? result.images[0] !== null ? process.env.REACT_APP_API_URL + result.images[0].url : '../images/logo.png' : '../images/logo.png'} alt="Event" />
                        </figure>
                        <figcaption>
                            <h2>{result.event_title}</h2>
                            <p><i className="location"></i> {result.address} {result.city} {result.statename},{result.zip} USA</p>
                            <p> {result.startdate}</p>
                            <p>{result.starttime} to {result.endtime} | <Link data-toggle="modal" data-target="#infoModal" onClick={(e) => this.getDetail(e, result.event_title, result.event_description)}>Learn More</Link></p>
                        </figcaption>
                    </div>
                    <figure className="rightUserImg mt-3" onClick={(e) => this.userProfile(e, result.user.id)}>
                        <img src={result.user !== null ? result.user.url !== "" ? process.env.REACT_APP_API_URL + result.user.url : '../images/logo.png' : '../images/logo.png'} alt="User" />
                    </figure>
                    <div className="addGroup moreOpt absoluteUI">
                        <Link to="" onClick={(e) => this.moreOpenedTogglePending(e, result.eventid, result)} >
                            <span className="more_opt_v" >
                                <img src="../images/more-dot-v.svg" alt="Dot" />
                            </span>
                        </Link>
                    </div>
                </li>
            )
        }) : <p className="no-data">No Data</p>;
        return (
            <section className="main-Section">
                <Header
                    title={'Events'}
                    backUrl={'dashboard'}
                />
                <div className="container-fluid midSection" style={{ height: "auto" }}>
                    <div className="content">
                        <InitiateFreedom />
                        <div className="form-block formBox">
                            {user !== undefined && user.admin === "1" &&
                                <div className="patriot-group-tab">
                                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link active" id="home-tab" data-toggle="tab" data-target="#eposted-tab" type="button" onClick={(e) => this.getEventPosts(e, 1)}>Posted</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="profile-tab" data-toggle="tab" data-target="#epending-tab" type="button" onClick={(e) => this.getEventPosts(e, 2)}>Pending</button>
                                        </li>
                                    </ul>
                                </div>
                            }
                            <div className="search-box">
                                <input type="text" placeholder="Keyword Search" value={this.state.search} name="search" onChange={this.searchKeyword} className="inp" />
                            </div>
                            <fieldset className="selectInput redAro">
                                <select className="form-control input__field" onChange={this.searchType}>
                                    <option value=''>Select Event</option>
                                    {types.map((choice, i) =>
                                        <option value={choice.value} key={i}>{choice.text}</option>
                                    )}
                                </select>
                            </fieldset>
                            <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade show active" id="eposted-tab" role="tabpanel">
                                    <div className="post-container">
                                        <div className="usersList">
                                            <InfiniteScroll
                                                dataLength={event.length}
                                                next={this.fetchMoreData}
                                                hasMore={eventHasMore}
                                                loader={event.length >= 10 ? <Loader /> : ''}
                                            >
                                                <ul>
                                                    {posted}
                                                </ul>
                                            </InfiniteScroll>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="epending-tab" role="tabpanel">
                                    <div className="post-container">
                                        <div className="usersList">
                                            <InfiniteScroll
                                                dataLength={pendingEvents.length}
                                                next={this.fetchMorePendingData}
                                                hasMore={pendingEventHasMore}
                                            >
                                                <ul>
                                                    {pending}
                                                </ul>
                                            </InfiniteScroll>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal custom-modal initiateFreedom  fade" id="infoModal" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="text-block scrollSection">
                                    <h2>{this.state.name}</h2>
                                    <p className="text-capitalize">{this.state.description}</p>
                                </div>
                                <button className="btn btn-primary" data-dismiss="modal">Dismiss</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="action-btn">
                    <Link to="add-event" className="cmn-btn documentBtn">
                        <img src="../images/plus-big.svg" alt="icon" />
                    </Link>
                </div>
                <div className={this.state.moreOpened ? "bottomCard opened" : "bottomCard"}>
                    {user !== undefined && user.admin === "1" ?
                        <>
                            <Link to={`edit-event-${this.state.eventId}`}>
                                <button className="btn btn-default mb-2">Edit</button>
                            </Link>
                            <button className="btn btn-default mb-2" onClick={(e) => this.handleDelete(e, this.state.eventId)}>Delete</button>
                        </>
                        : <button className="btn btn-default mb-2" onClick={(e) => this.handleFlag(e, this.state.eventId)} disabled={loading ? 'disabled' : ''}>{loading ? 'Loading' : 'Flag'}</button>
                    }
                    <button className="btn btn-plain" onClick={(e) => this.moreOpenedToggle(e)}>Cancel</button>
                </div>
                <div className={this.state.moreOpenedPending ? "bottomCard opened" : "bottomCard"}>
                    {user !== undefined && user.admin === "1" &&
                        <>
                            <button className="btn btn-default mb-2" onClick={(e) => this.handleApproveDeny(e, this.state.eventId, 'Approved')}>Approve</button>
                            <Link to={`edit-event-${this.state.eventId}`}>
                                <button className="btn btn-default mb-2">Edit</button>
                            </Link>
                            <button className="btn btn-default mb-2" onClick={(e) => this.handleApproveDeny(e, this.state.eventId, 'Rejected')}>Deny</button>
                            <button className="btn btn-plain" onClick={(e) => this.moreOpenedTogglePending(e)}>Cancel</button>
                        </>
                    }
                </div>
            </section>
        )
    }
}

const mapStateToProps = (state) => ({
    home: state.home,
    user: state.user,
    UI: state.UI
})

const mapActionToProps = {
    eventList,
    flagEvent,
    deleteEvent,
    filterEvents,
    changePostStatus,
    pendingEventList
}

export default connect(mapStateToProps, mapActionToProps)(CurrentEvents);