import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { addEvent } from '../../Redux/actions/homeAction';
import { getToken } from '../../Helper/Session';
import { inputPlaceHolders, isEmpty, getTime, time12To24Format, getTimeStampByDate } from "../../Helper/Util";
import { types } from "../../Helper/Constant";
import { stateList } from '../../Redux/actions/userAction';
import { Loader } from '../Common/Loader';
import DatePicker from "react-datepicker";
import moment from 'moment';
import Header from '../Layout/Header';

class EditEvent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            startdatetimestamp: getTimeStampByDate(new Date()),
            enddatetimestamp: getTimeStampByDate(new Date()),
            type: 'Gun Show',
            starttime: new Date(),
            endtime: new Date(),
            startdate: new Date(),
            enddate: new Date(),
            address: '',
            height: 0,
            width: 0,
            state: '',
            city: '',
            zipcode: '',
            event_description: '',
            images: null,
            event_title: '',
            event_id: '',
            url: '',
            errors: {}
        }
    }

    componentDidUpdate() {
        inputPlaceHolders();
    }

    componentDidMount() {
        this.setState({
            event_id: this.props.match.params.id
        })
        const params = {
            apikey: process.env.REACT_APP_API_KEY,
        }
        this.props.stateList(params);
        let item = JSON.parse(localStorage.getItem('event'));
        let d = new Date();
        let y = d.getFullYear();
        let m = d.getMonth();
        let day = d.getDate();
        let startT = item ? time12To24Format(item.starttime).split(':') : new Date();
        let endT = item ? time12To24Format(item.endtime).split(':') : new Date();
        this.setState({
            event_title: item ? item.event_title : '',
            event_description: item ? item.event_description : '',
            type: item ? item.type : '',
            starttime: new Date(y, m, day, startT[0] !== undefined ? startT[0] : '00', startT[1] !== undefined ? startT[1] : '00', '00'),
            endtime: new Date(y, m, day, endT[0] !== undefined ? endT[0] : '00', endT[1] !== undefined ? endT[1] : '00', '00'),
            startdate: item ? new Date(item.startdate) : new Date(),
            enddate: item ? new Date(item.enddate) : new Date(),
            startdatetimestamp: item ? item.startdatetimestamp : getTimeStampByDate(new Date()),
            enddatetimestamp: item ? item.enddatetimestamp : getTimeStampByDate(new Date()),
            address: item ? item.address : '',
            state: item ? item.state : '',
            city: item ? item.city : '',
            zipcode: item ? item.zip : '',
            url: item ? item.images !== null ? item.images[0].url : '' : '',
        })
    }

    handleChange = e => {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value,
            errors: {
                ...this.state.errors,
                [e.target.name]: ''
            }
        })
    }

    handleType = (e) => {
        this.setState({
            type: e.target.value,
            errors: {
                ...this.state.errors,
                [e.target.name]: ''
            }
        });
    }

    handleStartDate = (date) => {
        this.setState({
            startdate: date,
            startdatetimestamp: getTimeStampByDate(date)
        })
    }

    handleEndDate = (date) => {
        this.setState({
            enddate: date,
            enddatetimestamp: getTimeStampByDate(date)
        })
    }

    handleStartTime = (date) => {
        this.setState({
            starttime: date
        })
    }

    handleEndTime = (date) => {
        this.setState({
            endtime: date
        })
    }

    handleImgChange = (e) => {
        var file = e.target.files[0];
        let reader = new FileReader();
        reader.onload = (e) => {
            let images = e.target.result;
            this.setState({
                images: images.split(';base64')[1],
                errors: {
                    images: ''
                }
            });
        };
        reader.readAsDataURL(file);
    };

    validate = () => {
        const { event_title, event_description, state, city, type, zipcode, address } = this.state;
        let errors = {};
        if (isEmpty(event_title)) errors.event_title = true;
        if (isEmpty(event_description)) errors.event_description = true;
        if (isEmpty(state)) errors.state = true;
        if (isEmpty(address)) errors.address = true;
        if (isEmpty(zipcode)) errors.zipcode = true;
        if (isEmpty(type)) errors.type = true;
        if (isEmpty(city)) errors.city = true;
        return errors;
    }

    handleSubmit = (e) => {
        e.preventDefault()
        let token = getToken() !== null ? JSON.parse(getToken()) : '';
        const errors = this.validate();
        if (Object.keys(errors).length !== 0) {
            this.setState({ errors });
            return false;;
        }
        const { home: { eCurrentPage } } = this.props;
        const params = {
            event_id: this.state.event_id,
            event_title: this.state.event_title,
            event_description: this.state.event_description,
            event_type: this.state.type,
            startdatetimestamp: this.state.startdatetimestamp,
            enddatetimestamp: this.state.enddatetimestamp,
            starttime: getTime(this.state.starttime),
            endtime: getTime(this.state.endtime),
            startdate: moment(this.state.startdate).format('MM/DD/YYYY'),
            enddate: moment(this.state.enddate).format('MM/DD/YYYY'),
            address: this.state.address,
            state: this.state.state,
            city: this.state.city,
            zipcode: this.state.zipcode,
            images: [this.state.images],
            height: this.state.height,
            width: this.state.width,
            userid: token.id,
            apikey: process.env.REACT_APP_API_KEY
        }
        this.props.addEvent(params, this.props.history, token.admin, eCurrentPage);
    }

    render() {
        const { errors } = this.state;
        const { home: { states }, UI: { loading } } = this.props;
        let stateMarkup = !loading ? states !== undefined && states.length > 0 ?
            states.map((result, i) => <option value={result.id} key={i}>{result.name}</option>) :
            <p>No Data</p> : <Loader />;
        return (
            <section className="main-Section">
                <form className="form-block formBox" onSubmit={this.handleSubmit}>
                    <Header
                        title={'Edit Event'}
                        backUrl={'current-events'}
                        saveBtn={true}
                        loading={loading}
                    />
                    <div className="container-fluid midSection scrollSection">
                        <div className="content">
                            <fieldset>
                                <span className="placeholder">Event Name</span>
                                <input type="text" name="event_title" className={`form-control input__field ${errors.event_title ? 'inputError' : ''}`} value={this.state.event_title} onChange={this.handleChange} />
                            </fieldset>
                            <div className="row mb-20">
                                {types.map((choice, i) =>
                                    <div className="col-md-3 col-6 mb-10">
                                        <label className={`radio-label roundType`}>
                                            <input
                                                type="radio"
                                                key={i}
                                                name="type"
                                                value={choice.value}
                                                checked={this.state.type === choice.value}
                                                onChange={e => this.handleType(e)}
                                            />
                                            <span className="checkbox-custom"></span>
                                            <span className="labelName">{choice.text}</span>
                                        </label>
                                    </div>
                                )}
                            </div>
                            <div className="row mb-20">
                                <div className="col-6">
                                    <div className="date-card">
                                        <i className="date-card__icon"><img src="../images/calendar.svg" alt="calendar" /></i>
                                        <div className="date-card__filleds">
                                            <span className="placeholder">Event Start Date</span>
                                            <DatePicker
                                                className="inp"
                                                selected={this.state.startdate}
                                                onChange={this.handleStartDate}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="date-card">
                                        <i className="date-card__icon"><img src="../images/calendar.svg" alt="calendar" /></i>
                                        <div className="date-card__filleds">
                                            <span className="placeholder">End Date</span>
                                            <DatePicker
                                                className="inp"
                                                selected={this.state.enddate}
                                                onChange={this.handleEndDate}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-20">
                                <div className="col-6">
                                    <div className="date-card">
                                        <i className="date-card__icon"><img src="../images/time.svg" alt="Time" /></i>
                                        <div className="date-card__filleds">
                                            <span className="placeholder">Start Time</span>
                                            <DatePicker
                                                selected={this.state.starttime}
                                                onChange={this.handleStartTime}
                                                showTimeSelect
                                                showTimeSelectOnly
                                                timeIntervals={30}
                                                dateFormat="h:mm a"
                                                timeCaption="time"
                                                className="inp"
                                                placeholderText="Time:"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="date-card">
                                        <i className="date-card__icon"><img src="../images/time.svg" alt="Time" /></i>
                                        <div className="date-card__filleds">
                                            <span className="placeholder">End Time</span>
                                            <DatePicker
                                                selected={this.state.endtime}
                                                onChange={this.handleEndTime}
                                                showTimeSelect
                                                showTimeSelectOnly
                                                timeIntervals={30}
                                                dateFormat="h:mm a"
                                                timeCaption="time"
                                                className="inp"
                                                placeholderText="Time:"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <fieldset className="textareaInput">
                                <span className="placeholder">Address</span>
                                <textarea name="address" className={`form-control input__field ${errors.address ? 'inputError' : ''}`} value={this.state.address} onChange={this.handleChange} ></textarea>
                            </fieldset>
                            <div className="row">
                                <div className="col-6">
                                    <fieldset className="input--filled">
                                        <span className="placeholder">Zip Code</span>
                                        <input type="text" name="zipcode" className={`form-control input__field ${errors.zipcode ? 'inputError' : ''}`} value={this.state.zipcode} onChange={this.handleChange} />
                                    </fieldset>
                                </div>
                                <div className="col-6">
                                    <fieldset className="input--filled">
                                        <span className="placeholder">City</span>
                                        <input type="text" name="city" className={`form-control input__field ${errors.city ? 'inputError' : ''}`} value={this.state.city} onChange={this.handleChange} />
                                    </fieldset>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-sm-6">
                                    <fieldset className="selectInput redAro">
                                        <span className="placeholder">State</span>
                                        <select className={`form-control input__field ${errors.state ? 'inputError' : ''}`} name="state" value={this.state.state} onChange={this.handleChange}>
                                            <option value=''>Select State</option>
                                            {stateMarkup}
                                        </select>
                                    </fieldset>
                                </div>
                            </div>
                            <fieldset className="input--filled">
                                <span className="placeholder">Description</span>
                                <textarea name="event_description" placeholder="Start Typing here" className={`form-control input__field ${errors.event_description ? 'inputError' : ''}`} value={this.state.event_description} onChange={this.handleChange} ></textarea>
                            </fieldset>
                            <div className="profileBrowse">
                                <div className="profileImage">
                                    {this.state.images && this.state.url ? (
                                        <figure><img src={this.state.images ? (`data:image/png;base64` + this.state.images) : "./images/logo.png"} alt="Fig" /></figure>
                                    ) :
                                        <figure><img src={this.state.url !== '' ? process.env.REACT_APP_API_URL + this.state.url : '../images/logo.png'} alt="Fig" /></figure>
                                    }
                                    <label className="addProfile">
                                        <img src="../images/add.png" alt="User" />
                                        <input type="file" name="image" onChange={this.handleImgChange} />
                                    </label>
                                </div>
                                <div>
                                    <h4 className={`uplodError ${errors.images ? 'upload-title' : ''}`}>Please Upload Image</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </section>
        )
    }
}

const mapStateToProps = (state) => ({
    home: state.home,
    UI: state.UI
})

const mapActionToProps = {
    addEvent,
    stateList
}

export default connect(mapStateToProps, mapActionToProps)(withRouter(EditEvent));