import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from '../Layout/Sidebar';
import InitiateModal from '../Common/InitiateModal';
import { connect } from 'react-redux';
import { myGroupsList } from '../../Redux/actions/homeAction';
import { unreadNotification, readNotification, checkUserMultipleLogin, updateProfile } from '../../Redux/actions/userAction';
import { getToken } from '../../Helper/Session';
import { getOS } from "../../Helper/Util";
import { db } from "../Services/firebase";

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menuOpened: false,
            toggle: false,
            loading: false,
            title: '',
            body: '',
            myChats: []
        }
    }

    async componentDidMount() {
        let token = getToken() !== null ? JSON.parse(getToken()) : '';
        const params = {
            apikey: process.env.REACT_APP_API_KEY,
            userid: token.id
        }
        this.props.myGroupsList(params);
        this.props.unreadNotification(params);
        const params_ = {
            userid: token.id,
            token: localStorage.getItem('fbToken') ? localStorage.getItem('fbToken') : '',
            lat: localStorage.getItem('lat') ? localStorage.getItem('lat') : 0,
            lang: localStorage.getItem('lng') ? localStorage.getItem('lng') : 0,
            os: getOS()
        }
        this.props.updateProfile(params_, false);

        const params__ = {
            userid: token.id,
            rand_no: localStorage.getItem('log_token')
        }
        this.props.checkUserMultipleLogin(params__);
        try {
            db.ref("MyChats").child(token.id).on("value", snapshot => {
                let myChats = [];
                let userIds = [];
                let chatIds = [];
                let userMedia = [];
                let unreadCount = [];
                snapshot.forEach((snap) => {
                    if (snap.val() !== null) {
                        myChats.push(snap.val());
                        let users = snap.val().users !== undefined ? Object.keys(snap.val().users) : [];
                        for (let i = 0; i < users.length; i++) {
                            if (userIds.indexOf(users[i]) === -1) {
                                userIds.push(users[i]);
                                db.ref("Users").child(users[i]).once('value', function (mediaSnap) {
                                    if (mediaSnap.val() !== null) {
                                        userMedia.push({ 'id': mediaSnap.val().id, 'name': mediaSnap.val().name, 'img': mediaSnap.val().img })
                                        localStorage.setItem("userMedia", JSON.stringify(userMedia));
                                        localStorage.setItem("chatUserMedia", JSON.stringify(userMedia));
                                    }
                                });
                            }
                        }
                    }
                });
                if (chatIds.length > 0) {
                    for (let x = 0; x < chatIds.length; x++) {
                        db.ref("Messages").child(chatIds[x]).orderByChild("status").equalTo(false).on("value", (snapshot) => {
                            if (snapshot.val() !== null && Object.values(snapshot.val())[0].from !== token.id) {
                                if (unreadCount !== null) {
                                    const index = unreadCount.find((res) => res.id === chatIds[x]);
                                    if (index !== undefined) {
                                        if (index.id === chatIds[x]) {
                                            unreadCount.push({ 'id': chatIds[x], 'count': snapshot.numChildren() })
                                            localStorage.setItem("unreadCount", JSON.stringify(unreadCount))
                                        }
                                    } else {
                                        unreadCount.push({ 'id': chatIds[x], 'count': snapshot.numChildren() })
                                        localStorage.setItem("unreadCount", JSON.stringify(unreadCount))
                                    }
                                }
                            } else {
                                localStorage.setItem("unreadCount", JSON.stringify(unreadCount))
                            }
                        });
                    }
                }
            });
        } catch (error) {
            console.log(error)
        }
    }

    isGrpAvl = (e, count) => {
        e.preventDefault();
        if (count > 0) {
            this.setState({
                toggle: true
            });
        } else {
            this.setState({
                toggle: false
            });
        }
    }

    menuOpenedToggle = () => {
        this.setState({
            menuOpened: !this.state.menuOpened,
        });
    }

    setAsRead = () => {
        let token = getToken() !== null ? JSON.parse(getToken()) : '';
        const params = {
            apikey: process.env.REACT_APP_API_KEY,
            user_id: token.id
        }
        const { home: { unreadNotifications } } = this.props;
        if (unreadNotifications > 0) {
            this.props.readNotification(params);
        }
    }

    render() {
        const { home: { myGroups, unreadNotifications } } = this.props;
        return (
            <>
                <Sidebar
                    menuOpened={this.state.menuOpened}
                    menuOpenedToggle={this.menuOpenedToggle}
                />
                <section className="main-Section">
                    <header className="header">
                        <div className="container-fluid">
                            <div className="header-section">
                                <button className="header-section__menu" type="button" onClick={this.menuOpenedToggle}><img src="../images/menu.svg" alt="Menu" /></button>
                                <h2 className="page-title">Home</h2>
                                <Link to="notifications" className="notificationIcon" onClick={this.setAsRead}>
                                    <img src="../images/bell.svg" alt="Img" />
                                    {unreadNotifications > 0 &&
                                        <span className="counter">
                                            {unreadNotifications}
                                        </span>}
                                </Link>
                            </div>
                        </div>
                    </header>
                    <div className="container-fluid midSection scrollSection">
                        <div className="content login">
                            <div className="home-list">
                                <ul>
                                    <li>
                                        <Link data-toggle="modal" data-target={this.state.toggle ? '#initiateFreedom' : '#initiateFreedom4'} onClick={(e) => this.isGrpAvl(e, myGroups.length)}>
                                            <figure><img src="../images/home-img1.png" alt="Img" /></figure>
                                            <figcaption>
                                                <h2>Initiate <span>Freedom</span></h2>
                                            </figcaption>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="patriot-group-and-request">
                                            <figure><img src="../images/home-img2.png" alt="Img" /></figure>
                                            <figcaption>
                                                <h2>Patriot <span>Group</span></h2>
                                            </figcaption>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="articles-legislation">
                                            <figure><img src="../images/home-img2.png" alt="Img" /></figure>
                                            <figcaption>
                                                <h2>Articles &amp; <span>Legislation</span></h2>
                                            </figcaption>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="current-events">
                                            <figure><img src="../images/home-img2.png" alt="Img" /></figure>
                                            <figcaption>
                                                <h2>Current <span>Events</span></h2>
                                            </figcaption>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="media">
                                            <figure><img src="../images/home-img2.png" alt="Img" /></figure>
                                            <figcaption>
                                                <h2>Media</h2>
                                            </figcaption>
                                        </Link>
                                    </li>
                                    <li>
                                        <a href="https://www.bravoconcealment.com/pages/concealed-carry-map" target="_blank" rel="noreferrer">
                                            <figure><img src="../images/home-img2.png" alt="Img" /></figure>
                                            <figcaption>
                                                <h2>Reciprocity <span>Map</span></h2>
                                            </figcaption>
                                        </a>
                                    </li>
                                    <li>
                                        <Link to="legel-counsel">
                                            <figure><img src="../images/home-img2.png" alt="Img" /></figure>
                                            <figcaption>
                                                <h2>Legal <span>Counsel</span> </h2>
                                            </figcaption>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="affiliates-donation">
                                            <figure><img src="../images/home-img2.png" alt="Img" /></figure>
                                            <figcaption>
                                                <h2>Affiliates/ <span>Donations</span></h2>
                                            </figcaption>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <InitiateModal
                        myGroups={myGroups}
                    />
                </section>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    home: state.home,
    user: state.user
})

const mapActionToProps = {
    myGroupsList,
    unreadNotification,
    readNotification,
    updateProfile,
    checkUserMultipleLogin
}

export default connect(mapStateToProps, mapActionToProps)(Dashboard);