import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { flaggedItems, ignoreFlagItem, deleteFlagItem } from '../../Redux/actions/homeAction';
import moment from 'moment';
import InitiateFreedom from '../Common/InitiateFreedom';
import { Loader } from '../Common/Loader';
import { titleCase } from '../../Helper/Util';
import Header from '../Layout/Header';

class Flagged extends Component {
    constructor(props) {
        super(props);
        this.state = {
            moreOpened: false,
            name: '',
            description: '',
            flagId: '',
            type: ''
        }
    }

    componentDidMount() {
        const params = {
            apikey: process.env.REACT_APP_API_KEY,
        }
        this.props.flaggedItems(params);
    }

    searchKeyword = (e) => {
        this.setState({
            search: e.target.value
        })
    }

    getDetail = (e, name, description) => {
        e.preventDefault();
        this.setState({
            name: name,
            description: description,
        })
    }

    moreOpenedToggle = (e, flagId, type) => {
        e.preventDefault();
        this.setState({
            moreOpened: !this.state.moreOpened,
            flagId: flagId,
            type: type
        });
    }

    handleIgnoreReomve = (e, flagId, flagType) => {
        e.preventDefault();
        const { type } = this.state;
        let params = {};
        if (type === 'User') {
            params = {
                user_id: flagId,
                apikey: process.env.REACT_APP_API_KEY
            }
        }
        else if (type === 'Article') {
            params = {
                article_id: flagId,
                apikey: process.env.REACT_APP_API_KEY
            }
        } else if (type === 'Event') {
            params = {
                event_id: flagId,
                apikey: process.env.REACT_APP_API_KEY
            }
        }
        else if (type === 'Post') {
            params = {
                post_id: flagId,
                apikey: process.env.REACT_APP_API_KEY
            }
        }
        if (flagType === 'ignore') {
            this.props.ignoreFlagItem(params);
        }
        if (flagType === 'remove') {
            this.props.deleteFlagItem(params);
        }
        this.setState({
            moreOpened: !this.state.moreOpened,
        });
    }

    render() {
        const { home: { flaggedItems }, UI: { loading } } = this.props;
        let markup = !loading ? flaggedItems.length > 0 ? flaggedItems.map((result, i) => {
            let type = result.type;
            if (type === 'User') {
                return (
                    <>
                        <li key={i}>
                            <div className="userProfile">
                                <figure>
                                    <img src={result.data.url !== '' ? process.env.REACT_APP_API_URL + result.data.url : '../images/logo.png'} alt="User" />
                                </figure>
                                <figcaption>
                                    <h2>{result.data.name} @<span>{titleCase(result.data.username)}</span></h2>
                                    <p><i className="location"></i>{result.data.city}, {result.data.state}</p>
                                </figcaption>
                            </div>
                            <div className="addGroup moreOpt mt-1">
                                <Link to="" onClick={(e) => this.moreOpenedToggle(e, result.user_id, result.type)} >
                                    <span className="more_opt_v" >
                                        <img src="../images/more-dot-v.svg" alt="Icon" />
                                    </span>
                                </Link>
                            </div>
                        </li>
                    </>
                )
            }
            else if (type === 'Article') {
                return (
                    <>
                        <div className="custom-card" key={i}>
                            <figure className="custom-card__photo">
                                <img src={result.data.url !== '' ? process.env.REACT_APP_API_URL + result.data.url : '../images/logo.png'} alt="Article" />
                            </figure>
                            <div className="custom-card__date">
                                <span>
                                    {moment(result.data.dateadded).format('MM/DD/YYYY')}
                                </span>
                            </div>
                            <div className="custom-card__user-card">
                                <figure className="user-img">
                                    <img src={result.data.user !== undefined ? process.env.REACT_APP_API_URL + result.data.user.url : '../images/logo.png'} alt="User" />
                                </figure>
                                <figcaption className="custom-card__user-card__info">
                                    <h4>{result.data.user !== undefined ? result.data.user.name : ''}@{result.data.user !== undefined ? result.data.user.username : ''}</h4>
                                </figcaption>
                                <div className="moreOpt">
                                    <Link to="" onClick={(e) => this.moreOpenedToggle(e, result.article_id, result.type)} >
                                        <span className="more_opt_v" >
                                            <img src="../images/more-dot-v.svg" alt="Icon" />
                                        </span>
                                    </Link>
                                </div>
                            </div>
                            <div className="custom-card__description rightGap">
                                <Link to={`articles-legislation-detail-${result.data.article_id}`}>
                                    <h3>{result.data.article_title}</h3>
                                </Link>
                                <p className="scrollSection">{result.data.article_description}</p>
                            </div>
                        </div>
                    </>
                )
            }
            else if (type === 'Event') {
                return (
                    <>
                        <li key={i}>
                            <div className="userProfile">
                                <figure>
                                    <img src={result.data.images !== null ? result.data.images[0] !== null ? process.env.REACT_APP_API_URL + result.data.images[0].url : '../images/logo.png' : '../images/logo.png'} alt="Event" />
                                </figure>
                                <figcaption>
                                    <h2>{result.data.event_title}</h2>
                                    <p><i className="location"></i> {result.data.address} {result.data.city} {result.data.statename},{result.data.zip} USA</p>
                                    <p> {moment(result.data.startdate).format('MM/DD/YYYY')}</p>
                                    <p>{result.data.starttime} to {result.data.endtime} | <Link data-toggle="modal" data-target="#infoModal" onClick={(e) => this.getDetail(e, result.data.event_title, result.data.event_description)}>Learn More</Link></p>
                                </figcaption>
                            </div>
                            <div className="addGroup moreOpt mt-1">
                                <Link to="" onClick={(e) => this.moreOpenedToggle(e, result.event_id, result.type)} >
                                    <span className="more_opt_v" >
                                        <img src="../images/more-dot-v.svg" alt="Icon" />
                                    </span>
                                </Link>
                            </div>
                        </li>
                    </>
                )
            }
            else if (type === 'Post') {
                // let mediaToShow = result.data.video_url ? (<iframe height='400px' width="100%"
                //     src={`https://www.youtube.com/embed/${(result.data.video_url).split('https://youtu.be/')[1]}`}
                // >
                // </iframe>) : result.data.images.map(m =>
                //     <img src={process.env.REACT_APP_API_URL + m.url} alt="Media" />
                // );
                // return (
                //     <div className="custom-card" key={i}>
                //         <figure className="custom-card__photo">
                //             {mediaToShow}
                //         </figure>
                //         <div className="custom-card__date">
                //             <span>
                //                 {moment(result.data.dateadded).format('MM/DD/YYYY')}
                //             </span>
                //         </div>
                //         <div className="custom-card__user-card">
                //             <figure className="user-img cursor-pointer" onClick={(e) => this.userProfile(e, result.data.user.id)}>
                //                 <img src={result.data.user !== null ? result.data.user.url !== null ? process.env.REACT_APP_API_URL + result.user.url : '../images/logo.png' : '../images/logo.png'} alt="User" />
                //             </figure>
                //             <figcaption className="custom-card__user-card__info">
                //                 <span className="more_opt_v" onClick={(e) => this.moreOpenedToggle(e, result.post_id)} >
                //                     <img src="../images/more-dot-v.svg" alt="fig" />
                //                 </span>
                //                 <h4 className="inblockTitle" onClick={(e) => this.userProfile(e, result.user.id)}>{result.user !== '' ? result.user.name : ''}@{result.user !== '' ? result.user.username : ''}</h4>
                //             </figcaption>
                //         </div>
                //         <div className="custom-card__description">
                //             <Link to={`media-detail-${result.post_id}`}>
                //                 <h3>{result.post_title} - {result.statename}</h3>
                //             </Link>
                //             <p>{result.post_description}</p>
                //         </div>
                //     </div>
                // )
            }
        }) : <p className="no-data">No Data</p> : <Loader />;
        return (
            <section className="main-Section">
                <Header
                    title={'Flagged'}
                    backUrl={'dashboard'}
                />
                <div className="container-fluid midSection" style={{ height: "auto" }}>
                    <div className="content">
                        <InitiateFreedom />
                        <div className="post-container">
                            <div className="usersList">
                                <ul>
                                    {markup}

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal custom-modal initiateFreedom  fade" id="infoModal" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="text-block scrollSection">
                                    <h2>{this.state.name}</h2>
                                    <p className="text-capitalize">{this.state.description}</p>
                                </div>
                                <button className="btn btn-primary" data-dismiss="modal">Dismiss</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={this.state.moreOpened ? "bottomCard opened" : "bottomCard"}>
                    <button className="btn btn-default mb-2" onClick={(e) => this.handleIgnoreReomve(e, this.state.flagId, 'ignore')}>Ignore</button>
                    <button className="btn btn-default mb-2" onClick={(e) => this.handleIgnoreReomve(e, this.state.flagId, 'remove')}>Remove</button>
                    <button className="btn btn-plain" onClick={(e) => this.moreOpenedToggle(e)}>Cancel</button>
                </div>
            </section>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.user,
    home: state.home,
    UI: state.UI
})

const mapActionToProps = {
    flaggedItems,
    ignoreFlagItem,
    deleteFlagItem
}

export default connect(mapStateToProps, mapActionToProps)(Flagged);