export function Loader() {
    return (
        <>
            <div className="text-center loader-main">
                <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        </>
    )
}


export function LoaderForUser() {
    return (
        <>
            <div className="text-center loader-main mb-10">
                <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        </>
    )
}

export function pendLoader() {
    return (
        <>
            {/* <div className="loader-container">
                <div className="loader">Loading...</div>
            </div> */}
            <div className="text-center"
            // style={{ 'position': 'fixed', 'height': '100vh', 'display': 'flex', 'alignItems': 'center', 'justifyContent': 'center', 'width': '100%', 'background': 'white', 'padding': '15px 0px', 'left': 0 }}
            >
                <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        </>
    )
}

export function addLoader() {
    return (
        <>
            <div className="text-center">
                <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        </>
    )
}


export function LoaderCenter() {
    return (
        <>
            <div className="text-center loader-main">
                <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        </>
    )
}